<script lang="ts" setup>
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import ImageCarouselModal from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModal.vue'
import { OutlinePhoto } from '@ankor-io/icons/outline'

import ResponsiveImage from '@/components/image/ResponsiveImage.vue'
import { HeroSectionData } from '@/sections/hero/HeroSection'
import OverlappedHeroTitle from '@/sections/hero/single/HeroTitle.vue'
import EmbeddedHeroTitle from '@/sections/hero/triple-stacked/HeroTitle.vue'
import { LayoutTemplate } from '@/sections/types'

type Props = {
  uri: string
  proposalUri: string
  data: HeroSectionData
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const showCarouselModal: Ref<boolean> = ref(false)
</script>
<template>
  <!-- TRIPLE STACKED LAYOUT -->
  <div v-if="props.layout.type === 'triple_stacked'" class="itinerary-hero-triple flex flex-col gap-1 relative">
    <EmbeddedHeroTitle
      v-if="props.data.heading || props.data.subheading"
      :heading="props.data.heading"
      :subheading="props.data.subheading"
    />

    <!-- Main hero -->
    <div class="h-[22.5rem] sm:h-full">
      <AssetViewer
        v-if="props.data.heroImages[0]"
        id="heroIndex:0"
        class="w-full col-span-2 object-cover"
        :url="`/media/${props.data.heroImages[0]}`"
      />
    </div>

    <div class="flex gap-1">
      <!-- Bottom left hero image -->
      <ResponsiveImage
        v-if="props.data.heroImages[1]"
        id="heroIndex:1"
        :index="1"
        :url="`/media/${props.data.heroImages[1]}`"
      />

      <!-- Bottom right hero image -->
      <ResponsiveImage
        v-if="props.data.heroImages[2]"
        id="heroIndex:2"
        :index="2"
        :url="`/media/${props.data.heroImages[2]}`"
      />
    </div>

    <!-- Show all button -->
    <button
      v-if="layout.options?.enableGallery && props.data.images.length"
      type="button"
      class="absolute bottom-5 right-5 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
      @click="showCarouselModal = true"
    >
      <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
      Show all photos
    </button>
  </div>

  <!-- SINGLE LAYOUT -->
  <div v-else class="itinerary-hero-single mt-24 relative">
    <!-- Hero Title -->
    <div v-if="props.data.heading || props.data.subheading" class="mx-4 sm:mx-8 relative">
      <OverlappedHeroTitle :heading="data.heading" :subheading="data.subheading" />
    </div>

    <!-- Main hero -->
    <div class="h-[22.5rem] sm:h-full">
      <AssetViewer
        v-if="props.data.heroImages[0]"
        class="w-full overflow-hidden rounded-2xl col-span-2 object-cover"
        :url="`/media/${props.data.heroImages[0]}`"
      />
    </div>

    <!-- Show all button -->
    <button
      v-if="layout.options?.enableGallery && props.data.images?.length"
      type="button"
      class="absolute bottom-8 right-8 sm:right-12 z-10 text-gray-900 bg-white hover:bg-gray-100 border border-gray-900 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center"
      @click="showCarouselModal = true"
    >
      <OutlinePhoto class="inline-block w-4 h-4 stroke-2 mr-2" />
      Show all photos
    </button>
  </div>

  <Teleport v-if="showCarouselModal" to="body">
    <ImageCarouselModal :slides="props.data.images" @close="showCarouselModal = false" />
  </Teleport>
</template>
