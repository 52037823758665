<script setup lang="ts">
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { OutlineCalendar } from '@ankor-io/icons/outline'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { SummarySectionEditorData } from '@/sections/summary/SummarySectionEditor'
import { LayoutTemplate } from '@/sections/types'

type Props = {
  id: string
  data: SummarySectionEditorData
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<SummarySectionEditorData>): void
}>()

const update = (event: string): void => {
  emit('update:value', { sectionId: props.id, data: { summaryTitle: event } })
}
</script>
<template>
  <div class="itinerary-summary w-full flex justify-center">
    <div class="max-w-screen-xl w-full border-b border-b-gray-200 flex justify-center items-center gap-x-2.5">
      <OutlineCalendar class="size-6" />
      <h5 class="text-xl">
        <SingleLineTextEditor
          placeholder="Enter a summary title"
          :value="props.data.summaryTitle"
          @update:value="update"
        />
      </h5>
    </div>
  </div>
</template>
