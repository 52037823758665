import { SectionLayout, SectionProperties } from '@ankor-io/common/proposal/Section'
import { EditableSection, EditableSectionState, SectionTemplate } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { bindings } from '@/services/proposal/Binding'

/**
 * Build an editable section using section template.
 *
 * @param sectionTemplate the template to generate the section from
 * @param slideUri the slide uri
 * @returns the editable section
 */
const createEditableSection = (sectionTemplate: SectionTemplate, slideUri: string): EditableSection<SectionType> => {
  return produceEditableSection(slideUri, sectionTemplate.type, sectionTemplate.layout, sectionTemplate.data, sectionTemplate.properties)
}

/**
 * Create an editible section using the supplied data
 *
 * @param slideUri the slide uri
 * @param sectionType the type of section
 * @param layout the layout data
 * @param data the data data
 * @returns an EditableSection
 */
const produceEditableSection = (slideUri: string, sectionType: SectionType, layout: SectionLayout, data: any, properties?: SectionProperties) => {
  const section: EditableSection<any> = bindings.proposalsEditableSections.get(sectionType)!({
    template: {
      type: sectionType,
      layout: layout,
      data: data,
      properties: properties
    },
    slideUri: slideUri,
    source: null,
    state: EditableSectionState.NEEDS_INIT,
  })

  // return the section
  return section
}

/**
 * Create an editible section using the supplied data
 *
 * @param slideUri the slide uri
 * @param sectionType the type of section
 * @param layout the layout data
 * @param data the data data
 * @returns an EditableSection
 */
const produceInitialisedEditableSection = (
  slideUri: string,
  sectionType: SectionType,
  layout: SectionLayout,
  data: any,
) => {
  const section: EditableSection<any> = bindings.proposalsEditableSections.get(sectionType)!({
    template: {
      type: sectionType,
      layout: layout,
      data: data,
    },
    slideUri: slideUri,
    source: null,
    state: EditableSectionState.INITIALIZED,
  })

  // return the section
  return section
}

export { createEditableSection, produceEditableSection, produceInitialisedEditableSection }
