<script setup lang="ts">
import { Ref, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'

type Props = {
  /**
   * The place name
   */
  name: string
  /**
   * The place description
   */
  description: string
  /**
   * The location image
   */
  image: string
  /**
   * A boolean representing whether this card is selected or not
   */
  selected: boolean
  /**
   * Represents the segment label
   */
  label: string
}

const props = defineProps<Props>()
const isClamped: Ref<boolean> = ref(true)

const toggleClamp = () => {
  isClamped.value = !isClamped.value
}
</script>
<template>
  <div
    class="rounded-lg overflow-y-auto bg-white w-full max-w-sm min-h-77"
    :class="selected ? 'shadow-[0_0_5px_5px] mt-1.5 shadow-theme-primary' : 'shadow-md'"
  >
    <div class="h-40">
      <AssetViewer v-if="props.image" class="w-full object-cover" :url="`/media/${props.image}`" />
    </div>

    <div class="px-4 py-3">
      <h1 class="!text-xl font-bold flex flex-wrap gap-x-1">
        <span>{{ props.label }}:</span>
        <SingleLineTextEditor class="self-center" :value="props.name" :is-editable="false" />
      </h1>
      <div v-if="props.description" class="text-base">
        <p :class="isClamped ? 'line-clamp-3' : 'line-clamp-none'">
          <MultiLineTextEditor :value="props.description" :is-editable="false" />
        </p>
        <span v-if="isClamped" class="cursor-pointer text-theme-primary" @click="toggleClamp"> Show more </span>
        <span v-else class="cursor-pointer text-theme-primary" @click="toggleClamp"> Show less </span>
      </div>
    </div>
  </div>
</template>
