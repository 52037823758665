import gql from 'graphql-tag'
import { Component } from 'vue'

import { Itinerary, Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { URIEntity } from '@ankor-io/common/uri/Uri'
import { GenericUriParser } from '@ankor-io/common/uri/uri.parser'

import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import ConfigurationComponent from './ImageConfiguration.vue'
import ImageSectionEditorVue from './ImageSectionEditor.vue'

const QUERY_ITINERARY_IMAGES = gql`
  query Itinerary($URI: String!) {
    itinerary(uri: $URI) {
      uri
      images
      segments {
        stops {
          place {
            uri
            images
          }
        }
      }
    }
  }
`

const QUERY_VESSEL_IMAGES = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      uri
      blueprint {
        images
      }
    }
  }
`

/**
 * Get all the images from the itinerary
 * @param itinerary
 */
const getAllItineraryImages = (itinerary: Itinerary): string[] => {
  const itineraryImages = (itinerary.images && replacePathToMediaUris(itinerary.uri, ...itinerary.images)) || []
  return itineraryImages.concat(
    itinerary.segments.flatMap(
      (segment: Segment) =>
        segment.stops.flatMap(
          (stop: Stop) => stop.place?.images && replacePathToMediaUris(stop.place.uri, ...stop.place.images),
        ) || [],
    ),
  )
}

const getAllVesselImages = (vessel: any): string[] => {
  return (vessel.blueprint.images && replacePathToMediaUris(vessel.uri, ...vessel.blueprint.images)) || []
}

export interface ImageSectionEditorData {
  images: string[]
  image: string
}

export class ImageSectionEditor extends ProposalAbstractEditableSection<ImageSectionEditorData, EditableProposal> {
  async fetchData(): Promise<ImageSectionEditorData> {
    const client = await this.client()
    const slideUri = new GenericUriParser().parse(this.getSlideUri()!)
    const entity: URIEntity = slideUri.getEntity()

    // Fetch the images based on the entity Itinerary
    if (entity === URIEntity.ITINERARY) {
      const itineraryUri = this.getSlideUri()
      return client
        .query({
          query: QUERY_ITINERARY_IMAGES,
          variables: { URI: itineraryUri },
        })
        .then(({ data }) => {
          const images = getAllItineraryImages(data?.itinerary).filter((image) => image)
          return Promise.resolve({
            images: images,
            image: images[0] || '',
          })
        })
    }

    // Fetch the images based on the entity Vessel
    if (entity === URIEntity.VESSEL) {
      const vesselUri = this.getSlideUri()
      return client
        .query({
          query: QUERY_VESSEL_IMAGES,
          variables: { URI: vesselUri },
        })
        .then(({ data }) => {
          const images = getAllVesselImages(data?.vessel)
          return Promise.resolve({
            images: images,
            image: images[0] || '',
          })
        })
    }

    // If the entity is not found, return an empty array
    return Promise.resolve({
      images: [],
      image: '',
    })
  }

  getType(): SectionType {
    return SectionType.IMAGE
  }

  getComponent(): Component {
    return ImageSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
