import { SectionLayout } from '@ankor-io/common/proposal/Section'
import { LineItem, Pricing } from '@ankor-io/common/vessel/types'

/**
 * The value for different types of InputAmountsTaxed
 */
export enum InputAmountsTaxedValues {
  INCLUSIVE = 'INCLUSIVE',
  EXCLUSIVE = 'EXCLUSIVE',
  NONE = 'NONE',
}

export interface VesselPricingSectionData {
  pricing: VesselPricing
}

/**
 * Extended pricing for line items to display quantity as percent
 */
export type LineItemPricing = LineItem & {
  displayQuantityAsPercent: boolean
}

/**
 * Extended pricing for vessels with extended line items
 */
export type VesselPricing = Pricing & {
  lineItems: LineItemPricing[]
}

// Vessel Pricing Layout Template
export type VesselPricingLayoutTemplate = SectionLayout & {
  /**
   * The layout type
   */
  type: string

  /**
   * The options for a layout
   */
  options: VesselPricingOptions
}

export type VesselPricingOptions = {
  enabledPricingColumns: string[]
  pricingLabel: string
  pricingHeaderBackground: string
  pricingBodyBackground: string
}
