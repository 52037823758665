import { EditableSection, SectionProperties, SectionTemplate } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { createEditableSection, produceEditableSection } from '@/services/proposal/factory/SectionFactory'

/**
 * Create an editable section using section template.
 *
 * @param sectionTemplate the template to generate the section from
 * @param slideUri the slide uri
 * @returns {EditableSection<SectionType>} the editable section
 */
const createSection = (sectionTemplate: SectionTemplate, slideUri: string): EditableSection<SectionType> => {
  // build the section
  const editableSection: EditableSection<SectionType> = createEditableSection(sectionTemplate, slideUri)
  return editableSection
}

/**
 * Create a section of type without any data or layout
 *
 * @param sectionType the type of section to create
 * @param slideUri the slide uri
 * @returns {EditableSection<SectionType>} the editable section
 */
const createBlankSection = (sectionType: SectionType, slideUri: string, properties?: SectionProperties): EditableSection<SectionType> => {
  const editableSection: EditableSection<SectionType> = produceEditableSection(slideUri, sectionType, {}, {}, properties)
  return editableSection
}

export { createSection, createBlankSection }
