import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M10.8 2.39999C10.5772 2.40011 10.3588 2.46225 10.1693 2.57946C9.97987 2.69666 9.82676 2.8643 9.72718 3.06359L8.85838 4.79999H4.79998C4.48172 4.79999 4.17649 4.92642 3.95145 5.15147C3.7264 5.37651 3.59998 5.68173 3.59998 5.99999C3.59998 6.31825 3.7264 6.62348 3.95145 6.84852C4.17649 7.07357 4.48172 7.19999 4.79998 7.19999V19.2C4.79998 19.8365 5.05283 20.447 5.50292 20.8971C5.95301 21.3471 6.56346 21.6 7.19998 21.6H16.8C17.4365 21.6 18.0469 21.3471 18.497 20.8971C18.9471 20.447 19.2 19.8365 19.2 19.2V7.19999C19.5182 7.19999 19.8235 7.07357 20.0485 6.84852C20.2735 6.62348 20.4 6.31825 20.4 5.99999C20.4 5.68173 20.2735 5.37651 20.0485 5.15147C19.8235 4.92642 19.5182 4.79999 19.2 4.79999H15.1416L14.2728 3.06359C14.1732 2.8643 14.0201 2.69666 13.8306 2.57946C13.6411 2.46225 13.4228 2.40011 13.2 2.39999H10.8ZM8.39998 9.59999C8.39998 9.28173 8.5264 8.97651 8.75145 8.75147C8.97649 8.52642 9.28172 8.39999 9.59998 8.39999C9.91824 8.39999 10.2235 8.52642 10.4485 8.75147C10.6735 8.97651 10.8 9.28173 10.8 9.59999V16.8C10.8 17.1183 10.6735 17.4235 10.4485 17.6485C10.2235 17.8736 9.91824 18 9.59998 18C9.28172 18 8.97649 17.8736 8.75145 17.6485C8.5264 17.4235 8.39998 17.1183 8.39998 16.8V9.59999ZM14.4 8.39999C14.0817 8.39999 13.7765 8.52642 13.5514 8.75147C13.3264 8.97651 13.2 9.28173 13.2 9.59999V16.8C13.2 17.1183 13.3264 17.4235 13.5514 17.6485C13.7765 17.8736 14.0817 18 14.4 18C14.7182 18 15.0235 17.8736 15.2485 17.6485C15.4735 17.4235 15.6 17.1183 15.6 16.8V9.59999C15.6 9.28173 15.4735 8.97651 15.2485 8.75147C15.0235 8.52642 14.7182 8.39999 14.4 8.39999Z",
      "clip-rule": "evenodd"
    })
  ]))
}