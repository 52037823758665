<script setup lang="ts">
import { initDropdowns } from 'flowbite'
import { onMounted } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'

import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import {
  AdditionalNotesEditorData,
  AdditionalNotesLayout,
  AdditionalNotesStyles,
} from '@/sections/additionalNotes/types'
import { LayoutTemplate } from '@/sections/types'

import TextCenterAlignedAdditionalNotes from './assets/TextCenterAlignedAdditionalNotes.png'
import TextLeftAlignedAdditionalNotes from './assets/TextLeftAlignedAdditionalNotes.png'
import TextRightAlignedAdditionalNotes from './assets/TextRightAlignedAdditionalNotes.png'
import TwoColAdditionalNotes from './assets/TwoColAdditionalNotes.png'

type AdditionalNotesConfigurationProps = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: AdditionalNotesEditorData
  /**
   * The section's layout
   */
  layout: AdditionalNotesLayout
}

const props = defineProps<AdditionalNotesConfigurationProps>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<AdditionalNotesEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

//const tabs: Ref<string[]> = ref(['Style'])
//const activeTab: Ref<string> = ref('Style')

onMounted(() => {
  initDropdowns()
})

const updateLayout = (layoutType: string) => {
  emit('update:layout', { sectionId: props.id, layout: { type: layoutType, options: { ...props.layout.options } } })
}

/** cmnt out 3image layout related code for now
const updateImage = (event: { field: string; value: string; layout?: LayoutTemplate }): void => {
  const data: AdditionalNotesEditorData = ObjectUtil.deepCopy(props.data)

  if (event.field === 'images') {
    data.images = [event.value]
  }

  emit('update:value', { sectionId: props.id, data: data })
}
const deleteImage = (event: { value: string; index: number }): void => {
  const data: AdditionalNotesEditorData = ObjectUtil.deepCopy(props.data)
  data.images?.splice(event.index, 1)
  emit('update:value', { sectionId: props.id, data: data })
}
 */

const stylesOptions: AdditionalNotesStyles[] = [
  {
    type: 'single-column-right-aligned',
    title: 'Single column on the right',
    description: 'A right column for additional notes style.',
    image: TextRightAlignedAdditionalNotes,
  },
  {
    type: 'single-column-left-aligned',
    title: 'Single column on the left',
    description: 'A left column for additional notes style.',
    image: TextLeftAlignedAdditionalNotes,
  },
  {
    type: 'single-column-center-aligned',
    title: 'Text block',
    description: 'Simple text block with a single column.',
    image: TextCenterAlignedAdditionalNotes,
  },
  {
    type: 'two-column-text-only',
    title: 'Two columns',
    description: 'Two column additional notes.',
    image: TwoColAdditionalNotes,
  },
  /** cmnt out 3image layout related code for now
  {
    type: 'two-column-broker-image',
    title: 'Image and additional notes',
    description: 'Make a personal note to your guests with this style.',
    image: BrokerImageAdditionalNotes,
  },
  {
    type: 'two-column-text-right-aligned',
    title: 'Left aligned image with single column',
    description: 'Left aligned image with single columned additional note.',
    image: ImageLeftAlignedAdditionalNotes,
  },
  {
    type: 'two-column-text-left-aligned',
    title: 'Right aligned image with single column',
    description: 'Right aligned image with a single columned additional note.',
    image: ImageRightAlignedAdditionalNotes,
  }, */
]
</script>
<template>
  <div class="h-[calc(100vh-10rem)] overflow-auto">
    <HelpTip class="mb-5" tip="Both simple blocks or text, and text combined with images." />

    <div v-for="option in stylesOptions" class="mt-4" :key="option.type">
      <p class="text-sm font-semibold text-gray-900 dark:text-white">{{ option.title }}</p>
      <p class="text-xs mb-4 text-gray-500 dark:text-gray-400">{{ option.description }}</p>
      <div class="hero-two-column-layout group relative">
        <img class="w-full border border-gray-200" :src="option.image" />
        <!-- On hover apply -->
        <div v-if="props.layout.type !== option.type">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout(option.type)"
          >
            Apply
          </button>
          <div
            class="z-10 absolute top-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
        <Badge class="top-4 right-4" text="Added" :show="layout.type === option.type" />
      </div>
    </div>
  </div>
  <!-- cmnt out 3image layout related code for now
    <GalleryTab
      v-show="activeTab === 'Gallery'"
      :uri="props.proposalUri"
      :images="props.data.images || []"
      :heroImages="[]"
      :layout="layout"
      :allowsAddImage="false"
      @update:value="updateImage"
      @delete:value="deleteImage"
    />
    -->
</template>
