<script setup lang="ts">
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import { LayoutTemplate } from '@/sections/types'

import ProfileCompanyLayout1 from './assets/ProfileCompanyLayout1.png'
import ProfileCompanyLayout2 from './assets/ProfileCompanyLayout2.png'

type Props = {
  id: string
  layout: LayoutTemplate
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

/**
 * @param layout receives a newly selected layout
 * Update to diff sync the newly selected layout
 */
const updateLayout = (layoutType: string): void => {
  const layout: LayoutTemplate = ObjectUtil.deepCopy(props.layout)
  layout.type = layoutType
  emit('update:layout', { sectionId: props.id, layout })
}
</script>
<template>
  <div id="company-broker-details" class="flex flex-col gap-y-5">
    <HelpTip
      tip="Choose a footer style to apply. You can use this company and broker details section anywhere in your presentation."
    />

    <div class="h-[calc(100vh-16rem)] overflow-y-auto flex flex-col gap-y-5">
      <div>
        <p class="text-sm font-semibold text-gray-900 dark:text-white">Compact with company and broker details</p>
        <p class="text-xs text-gray-500 dark:text-gray-400">
          A simple, professional way to finish off your presentation.
        </p>
      </div>
      <div class="compact-container-layout group relative">
        <div class="bg-gray-200 h-45 flex justify-center items-center">
          <img class="w-full" :src="ProfileCompanyLayout1" />
        </div>
        <!-- On hover apply -->
        <div v-if="layout.type !== undefined && layout.type !== 'compact'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout('compact')"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
        <Badge text="Added" :show="layout.type === 'compact' || !layout.type" />
      </div>

      <div>
        <p class="text-sm font-semibold text-gray-900 dark:text-white">Taller, bolder footer with company and broker</p>
        <p class="text-xs text-gray-500 dark:text-gray-400">Bold, fun and adventurous to end with a bang!</p>
      </div>
      <div class="taller-container-layout group relative">
        <div class="bg-gray-200 h-45 flex justify-center items-center">
          <img class="w-full" :src="ProfileCompanyLayout2" />
        </div>
        <!-- On hover apply -->
        <div v-if="layout.type !== 'taller'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout('taller')"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
        <Badge text="Added" :show="layout.type === 'taller'" />
      </div>
    </div>
  </div>
</template>
