<script setup lang="ts">
import { Ref, onMounted, ref } from 'vue'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'

import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import { LayoutTemplate } from '@/sections/types'

interface LayoutTabProps {
  image?: string
  layout: LayoutTemplate
}

const props = defineProps<LayoutTabProps>()

const emit = defineEmits<{
  (e: 'update:layout', value: { layout: LayoutTemplate }): void
}>()

const enableGalleryRef: Ref<boolean> = ref(false)
const addCycle: Ref<number> = ref(0)

const updateLayout = (layoutName: string) => {
  emit('update:layout', {
    layout: { type: layoutName, options: { ...props.layout.options, enableGallery: enableGalleryRef.value } },
  })
}

onMounted(() => {
  enableGalleryRef.value = props.layout?.options?.enableGallery || false
  addCycle.value = props.layout?.options?.addCycle || 0
})
</script>

<template>
  <div id="image-style-tab" class="flex flex-col gap-y-5">
    <HelpTip tip="Select an image style" />

    <div class="h-[calc(100vh-16rem)] overflow-y-auto flex flex-col gap-y-5">
      <!-- Simple Image -->
      <div>
        <p class="text-sm text-gray-900 dark:text-gray-50 font-semibold">Simple image</p>
        <p class="text-xs text-gray-500 dark:text-gray-400">Add a simple image to your presentation.</p>
      </div>
      <div class="simple-layout group relative py-4 px-6">
        <AssetViewerPlaceholder class="w-full h-full" :url="`/media/${image}`" />

        <!-- On hover apply -->
        <template v-if="layout.type !== 'simple'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout('simple')"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute inset-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </template>
        <Badge text="Added" :show="layout.type === 'simple'" />
      </div>

      <!-- Full width -->
      <div>
        <p class="text-sm text-gray-900 dark:text-gray-50 font-semibold">Full width image</p>
        <p class="text-xs text-gray-500 dark:text-gray-400">Full width image for impact.</p>
      </div>
      <div class="full-layout group relative">
        <AssetViewerPlaceholder class="w-full h-full" :url="`/media/${image}`" />

        <!-- On hover apply -->
        <template v-if="layout.type !== 'full'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout('full')"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute inset-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </template>
        <Badge text="Added" :show="layout.type === 'full'" />
      </div>
    </div>
  </div>
</template>
