<script setup lang="ts">
import { Ref, computed, ref, watch } from 'vue'

import { ProposalIndexItem } from '@ankor-io/common/index/ProposalIndexItem'
import { OutlineAdd, OutlineCalendar, OutlineDelete, OutlineDuplicate, OutlineEdit } from '@ankor-io/icons/outline'
import { SolidMapPin } from '@ankor-io/icons/solid'

import Badge from '@/components/Badge.vue'
import DeleteConfirmation, { DeleteConfirmationDataProps } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/modal/useModal'
import { deleteDocument } from '@/services/stowage/StowageService'

type ProposalCardProps = {
  /**
   * Defines if the proposal has been added to the cart
   */
  isAdded: boolean
  /**
   * indexed item of type itinerary from the hits
   */
  indexItem: ProposalIndexItem
  // allowed actions in card
  allowedActions: string[]
}

const props = defineProps<ProposalCardProps>()
const emit = defineEmits<{
  (e: 'proposal:item:add'): void
  (e: 'open:duplicate:modal'): void
  (e: 'item:delete'): void
}>()

const { isOpen, updateModalState } = useModal()

const modalValue: Ref<DeleteConfirmationDataProps | null> = ref(null)

const openDeleteConfirmation = () => {
  modalValue.value = {
    message: 'You are about to delete this presentation. Are you sure you want to continue?',
    labelCancel: 'No, keep the presentation',
    labelConfirm: 'Yes, delete the presentation',
  }

  updateModalState(true)
}

const confirmDelete = () => {
  updateModalState(false)
  emit('item:delete')
  try {
    if (props.indexItem.uri !== props.indexItem.objectID) {
      deleteDocument(props.indexItem.objectID)
    }

    deleteDocument(props.indexItem.uri)
  } catch (err) {
    console.error('Error deleting proposal', err)
  }
}

const closeModal = () => {
  modalValue.value = null
  updateModalState(false)
}

/**
 * interpolates 'from' and 'to' places
 * when either of line_3 or line_4 are undefined
 * do not display the arrow symbol for direction
 */
// TODO: remove the check for Array when types get updated to be always string[] for line_3 and line_4
const fromToLocation = computed(() => {
  const { line_3 = [], line_4 = [] } = props.indexItem
  const from = Array.isArray(line_3) ? line_3.join(', ') : line_3
  const to = Array.isArray(line_4) ? line_4.join(', ') : line_4
  return `${from} ${line_3.length && line_4.length ? '&rarr;' : ''} ${to}`
})

watch(isOpen, (value) => {
  if (!value) {
    modalValue.value = null
  }
})
</script>

<template>
  <!-- Proposal card -->
  <figure class="hide-on-transit relative w-full h-48">
    <!-- added badge -->
    <Badge text="Added" :show="isAdded" />

    <div class="h-full grid grid-rows-[minmax(0,1fr)_3.2rem]">
      <!-- card content -->
      <figcaption class="flex space-between p-5">
        <div class="w-full text-gray-900 dark:text-white">
          <div class="mb-2.5 font-medium text-left">
            <h3
              v-if="props.indexItem.line_1"
              class="text-lg mb-1 leading-tight line-clamp-1"
              v-html="props.indexItem.line_1"
            ></h3>
          </div>
          <div
            v-if="props.indexItem.line_3?.length || props.indexItem.line_4?.length"
            class="flex items-center text-sm text-gray-500 dark:text-gray-300"
          >
            <SolidMapPin class="shrink-0 w-3.5 h-3.5 mr-2.5 inline-block align-bottom fill-gray-500" />
            <p class="w-full leading-tight line-clamp-1 [&>p]:inline-block" v-html="fromToLocation"></p>
          </div>
        </div>

        <!-- actions -->
        <div class="flex flex-col ml-4">
          <button
            v-if="props.allowedActions.includes('add')"
            name="add"
            type="button"
            class="relative group rounded-lg p-1 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700 active:bg-gray-100 focus:bg-gray-100 dark:focus:border-gray-600 dark:active:border-gray-600"
            :class="{ 'opacity-0': isAdded }"
            :disabled="isAdded"
            @click.stop="$emit('proposal:item:add')"
          >
            <OutlineAdd class="w-3.5 h-3.5 text-gray-900 dark:text-white" />
          </button>

          <button
            v-if="props.allowedActions.includes('duplicate')"
            name="duplicate"
            type="button"
            class="relative group rounded-lg p-1 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700 active:bg-gray-100 focus:bg-gray-100 dark:focus:border-gray-600 dark:active:border-gray-600"
            @click.stop="$emit('open:duplicate:modal')"
          >
            <OutlineDuplicate class="w-3.5 h-3.5 text-gray-900 dark:text-white" />
          </button>

          <RouterLink
            v-if="props.allowedActions.includes('edit') && props.indexItem.tags?.includes('custom')"
            class="relative group rounded-lg p-1 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700 active:bg-gray-100 focus:bg-gray-100 dark:focus:border-gray-600 dark:active:border-gray-600"
            :to="{
              name: 'Proposal Builder',
              params: {
                uri: props.indexItem.uri,
              },
            }"
            @click.stop
          >
            <OutlineEdit class="w-3.5 h-3.5 text-gray-900 dark:text-white" />
          </RouterLink>

          <button
            v-if="props.allowedActions.includes('delete') && props.indexItem.tags?.includes('custom')"
            name="delete"
            type="button"
            class="relative group rounded-lg p-1 border-2 border-transparent hover:bg-gray-100 dark:hover:bg-gray-700 active:bg-gray-100 focus:bg-gray-100 dark:focus:border-gray-600 dark:active:border-gray-600"
            @click.stop="openDeleteConfirmation"
          >
            <OutlineDelete class="w-3.5 h-3.5 text-gray-900 dark:text-white" />
          </button>
        </div>
      </figcaption>

      <!-- proposal update info -->
      <div class="relative flex">
        <!-- lastEdited and lastUsedBy -->
        <div
          class="relative flex flex-col flex-grow gap-1 justify-center text-white py-2.5 px-5 bg-gradient-to-r from-gray-400 to-gray-300"
        >
          <div v-if="props.indexItem.line_5" class="flex items-center">
            <OutlineCalendar class="mr-2.5 inline-block align-baseline w-3.5 h-3.5" />
            <span class="text-sm leading-center font-normal line-clamp-1">Edited: {{ props.indexItem.line_5 }}</span>
          </div>
          <div v-if="props.indexItem.tags?.includes('Design Studio')">
            <span class="bg-purple-100 text-purple-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded">
              Design Studio
            </span>
          </div>
        </div>
      </div>
    </div>

    <ModalContentWrapper v-if="modalValue">
      <DeleteConfirmation
        :message="modalValue.message"
        :label-cancel="modalValue.labelCancel"
        :label-confirm="modalValue.labelConfirm"
        @close:modal="closeModal()"
        @confirm:modal="confirmDelete()"
      />
    </ModalContentWrapper>
  </figure>
</template>
