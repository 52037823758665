import { ref, Ref } from 'vue'
import { UserTheme } from '@/types/user-theme'

const userTheme: Ref<UserTheme> = ref('light')

/**
 * @returns the current theme preference the user has set, or default
 */
export const getThemePreference = () => {
  return userTheme.value
}

/**
 * Sets the userTheme value with current support for LIGHT and DARK modes
 * Param capability allows extendable function to support other themes
 * @param theme The theme preference the user wishes to toggle on
 */
export const setThemePreference = (theme: UserTheme) => {
  try {
    userTheme.value = theme
    switch (theme) {
      case 'dark':
        document.documentElement.classList.add('dark')
        sessionStorage.setItem('prefers-color-scheme', 'dark')
        break
      case 'light':
        document.documentElement.classList.remove('dark')
        sessionStorage.setItem('prefers-color-scheme', 'light')
    }
  } catch (e) {
    console.debug('Unable to set user theme')
  }
}

/**
 * Retrieves the stored theme preference so the user does not need to set
 * their desired theme each time they load the app
 */
export const getStoredThemePreference = () => {
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue) {
    // use previously set pref.
    setThemePreference(storageValue as UserTheme)
  } else {
    // read from the browser.
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      setThemePreference('dark')
    } else {
      setThemePreference('light')
    }
  }
}
