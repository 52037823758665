<script lang="ts" setup>
import { initPopovers } from 'flowbite'
import ls from 'localstorage-slim'
import { Mixpanel } from 'mixpanel-browser'
import { Ref, Teleport, computed, defineComponent, inject, onMounted, ref } from 'vue'
import draggable from 'vuedraggable'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import MegaModal from '@ankor-io/blocks/components/modals/MegaModal.vue'
import SimpleAlertModal from '@ankor-io/blocks/components/modals/SimpleAlertModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import ListGrid from '@ankor-io/blocks/layouts/ListGrid.vue'
import { VesselIndexItem } from '@ankor-io/common/index/VesselIndexItem'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal, JsonProposal, ProposalItem, ProposalItemType } from '@ankor-io/common/proposal/Proposal'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import { OutlinePerson, OutlineSleeping } from '@ankor-io/icons/outline'
import { SolidCircleX, SolidDrag, SolidPlus, SolidSearch, SolidX } from '@ankor-io/icons/solid'

import SearchNoResultsMoon from '@/assets/search-no-results-moon.svg'
import VisibleObserver from '@/components/VisibleObserver.vue'
import { VESSEL_ADDED } from '@/utils/mixPanel/constants'

import Tags from '../library/Tags.vue'
import VesselPreview from '../library/VesselPreview.vue'

defineComponent(draggable)

const props = defineProps<{
  uri: string
  presentation: EditableProposal
  isEditSaved: boolean
}>()

const emit = defineEmits<{
  (e: 'update:presentation', value: JsonProposal): void
}>()

const UNNAMED_YACHT = 'Unnamed Yacht'

// Modal helpers
const previewModalHelper = modalHelper()
const deleteModalHelper = modalHelper()

// the data in the table -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))

// We don't want to mutate the presentation prop
const presentationComputed = computed(() => props.presentation)
const isDragging: Ref<boolean> = ref(false)
const draggableYacht: Ref<{ uri: string; index: number } | null> = ref(null)

const mixPanel: Mixpanel | undefined = inject('mixPanel')

onMounted(() => {
  initPopovers()
})

// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922

  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    // allow only vessels into the dirty river
    if (hit.type !== ProposalItemType.VESSEL) {
      return
    }

    let offset = results.page * results.hitsPerPage + i

    if (ls.get<string>(`tombstone::${hit.uri}`) !== null && ls.get<string>(`tombstone::${hit.uri}`) !== undefined) {
      dirtyRiver.value[offset] = null
      return
    }

    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      ...hit,
      uri: hit.objectID,
      name: hit.line_1,
      length: hit.line_2,
      numCabins: hit.line_3,
      numSleeps: hit.line_4,
      buildYear: hit.line_5,
      builder: hit.line_6,
      tags: hit.tags,
    }
  })

  // return the clean river (used by algolia lib)
  return river
}

/**
 * Function to select a vessel from the search results
 * @param item The VesselIndexItem
 */
const selectYacht = (item: VesselIndexItem) => {
  const proposalItem: ProposalItem = {
    type: ProposalItemType.VESSEL,
    uri: item.uri,
    item,
  } as ProposalItem

  presentationComputed.value.addItem(proposalItem)
  emit('update:presentation', presentationComputed.value.toJson())

  const CAManaged = item.tags?.includes('claimed_ca')
  const ManagingCACompany = item.line_7 && item.line_7[0]

  mixPanel?.track(VESSEL_ADDED, { name: item.line_1, CAManaged, ManagingCACompany, uri: item.uri })
}

/**
 * Remove a yacht from the presentation
 * @param index The index of the yacht to remove
 */
const removeYacht = (index: number) => {
  // hide the modal
  deleteModalHelper.hide()

  const yachts = presentationComputed.value.yachts.splice(index, 1)
  const yacht = yachts[0]
  if (yacht) {
    presentationComputed.value.removeItem(yacht)
  }
  emit('update:presentation', presentationComputed.value.toJson())
}

/**
 * Reorders the selected yacht slides
 */
const reorder = () => {
  const jsonPresentation = presentationComputed.value.toJson()
  // Early cancel of reordering when the order did not actually change - prevent diffsync from triggering onChanges
  if (
    draggableYacht.value !== null &&
    draggableYacht.value.uri === jsonPresentation.yachts[draggableYacht.value.index!]?.uri
  ) {
    isDragging.value = false
    draggableYacht.value = null
    return
  }

  const vesselSlides = jsonPresentation.document.slides?.filter((slide) => slide.type === SlideType.VESSEL) || []
  const reorderedVesselSlides =
    vesselSlides?.sort((a, b) => {
      const aIndex = jsonPresentation.yachts.findIndex((yacht) => yacht.uri === a.uri)
      const bIndex = jsonPresentation.yachts.findIndex((yacht) => yacht.uri === b.uri)
      return aIndex - bIndex
    }) || []

  isDragging.value = false
  draggableYacht.value = null
  const itinerarySlides = jsonPresentation.document.slides?.filter((slide) => slide.type === SlideType.ITINERARY) || []
  emit('update:presentation', {
    ...jsonPresentation,
    document: {
      ...jsonPresentation.document,
      slides: [...reorderedVesselSlides, ...itinerarySlides],
    },
  })
}
</script>
<template>
  <div class="flex flex-col md:flex-row h-[calc(100vh-11rem)] gap-4">
    <div class="w-full md:w-3/4 h-3/4 md:h-full">
      <ais-configure filters="type:vessel AND NOT tags:tombstone" hitsPerPage="60" />

      <!-- Delete modal -->
      <Teleport defer to="body">
        <!-- delete modal -->
        <SimpleAlertModal
          v-if="deleteModalHelper.isVisible()"
          :model="deleteModalHelper.model"
          @confirm="(model: any) => [deleteModalHelper.hide(), removeYacht(model.index)]"
          @dismiss="deleteModalHelper.hide()"
        >
          <template #title="{ modelValue }">
            <h3>{{ modelValue.title }}</h3>
          </template>
          <template #default="{ modelValue }">
            <div class="space-y-2.5 text-center text-sm text-gray-500 dark:text-gray-400">
              <p>You are about to remove</p>
              <p class="font-bold">{{ modelValue.name }}</p>
              <p>Are you sure you want to proceed?</p>
            </div>
          </template>
        </SimpleAlertModal>
      </Teleport>

      <!-- Preview modal -->
      <Teleport defer to="body">
        <MegaModal
          v-if="previewModalHelper.isVisible()"
          :model="previewModalHelper.model"
          @dismiss="previewModalHelper.hide()"
        >
          <template #header="{ modelValue }">
            <div class="flex flex-wrap flex-col md:flex-row gap-4 justify-between relative">
              <h4 class="mr-4">Yacht Details</h4>

              <div class="flex items-center gap-x-2">
                <button
                  :disabled="!isEditSaved || presentationComputed.hasItem(modelValue.item.uri, ProposalItemType.VESSEL)"
                  :class="
                    !isEditSaved || presentationComputed.hasItem(modelValue.item.uri, ProposalItemType.VESSEL)
                      ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                      : 'cursor-pointer bg-primary-600 dark:bg-primary-500 hover:bg-primary-500 dark:hover:bg-primary-800'
                  "
                  class="justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white"
                  @click=";[selectYacht(modelValue.item), previewModalHelper.hide()]"
                >
                  <SolidPlus
                    v-if="!presentationComputed.hasItem(modelValue.item.uri, ProposalItemType.VESSEL)"
                    class="size-5 shrink-0 mr-2 self-center text-white"
                  />
                  {{
                    presentationComputed.hasItem(modelValue.item.uri, ProposalItemType.VESSEL)
                      ? 'Selected'
                      : 'Select Yacht'
                  }}
                </button>

                <button
                  type="button"
                  class="absolute md:relative top-1 md:top-auto right-0 transition-colors rounded-md text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-500"
                  @click="previewModalHelper.hide()"
                >
                  <span class="sr-only">Close</span>
                  <SolidX class="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </template>

          <template #default="{ modelValue }">
            <div class="min-h-24 overflow-y-auto border-t border-gray-200 dark:border-gray-600">
              <VesselPreview :uri="modelValue.uri" />
            </div>
          </template>
        </MegaModal>
      </Teleport>

      <div class="h-full overflow-auto px-2">
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <h3 class="sm:h-10" data-testId="page-title">Select Yachts</h3>
            <p data-testId="page-subtitle">Select one or more yachts that you wish to present.</p>
          </div>

          <div class="w-full flex flex-col md:flex-row justify-between items-baseline gap-2 pb-4 min-h-11">
            <div class="w-full flex flex-col sm:flex-row items-start sm:items-center gap-3">
              <div class="w-full sm:w-auto flex gap-x-2 items-center">
                <!-- nunununununununun -->
                <!--   Category Box    -->
                <!-- nunununununununun -->
                <ais-menu-select
                  attribute="tags"
                  :class-names="{
                    'ais-MenuSelect': 'tags-selector',
                  }"
                >
                  <template v-slot="{ refine }">
                    <select
                      class="w-48 h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
                      @change="refine(($event.currentTarget! as HTMLInputElement).value)"
                    >
                      <option class="" value="">All Yachts</option>
                      <option class="" value="claimed_ca">CA Verified</option>
                    </select>
                  </template>
                </ais-menu-select>

                <!-- nunununununununun -->
                <!--    Search Box     -->
                <!-- nunununununununun -->
                <ais-search-box
                  placeholder="Search for a yacht..."
                  submit-title="Submit"
                  reset-title="clear"
                  :autofocus="false"
                  :show-loading-indicator="false"
                  :class-names="{
                    'ais-SearchBox': 'h-11 w-full',
                    'ais-SearchBox-form':
                      'p-3 h-full flex items-center gap-x-2 relative rounded-lg border bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500',
                    'ais-SearchBox-input':
                      'bg-transparent order-2 h-full w-full z-20 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 placeholder-gray-500 text-black dark:text-white p-0',
                    'ais-SearchBox-reset': 'hidden',
                    'ais-SearchBox-loadingIcon': 'hidden',
                  }"
                >
                  <template v-slot:submit-icon>
                    <SolidSearch
                      class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-general-text-body"
                    />
                  </template>
                </ais-search-box>
              </div>
            </div>
          </div>
        </div>

        <!-- nunununununununun -->
        <!--  Search Results   -->
        <!-- nunununununununun -->
        <ais-infinite-hits :transform-items="accumulateSearchResults">
          <template v-slot="{ refineNext, isLastPage, results }">
            <div id="searchResults-top"></div>

            <!-- Getting Started and No Results-->
            <!-- add '?showGetStarted=1' to show that screen manually -->
            <template v-if="!results.nbHits || $route.query.showGetStarted">
              <slot name="no-results">
                <!-- No results. -->
                <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
                  <div class="h-[33vh]"><img :src="SearchNoResultsMoon" alt="No results found" class="h-full" /></div>
                  <h4>No results.</h4>
                  <div>Sorry! We could not find anything to match your query.</div>
                </div>
              </slot>
            </template>

            <!-- Show results -->
            <template v-else>
              <ListGrid :keyField="'uri'" :items="river">
                <template #item="{ item }">
                  <!-- Yacht Card -->
                  <div>
                    <button
                      type="button"
                      class="w-full aspect-[1.44] relative hover:scale-[1.02] md:hover:scale-105 focus:ring-4 focus:outline-none focus:ring-primary-300 cursor-pointer rounded-lg transition-transform duration-500 ease-in-out"
                      aria-label="Preview Yacht"
                      @click="
                        previewModalHelper.show({
                          uri: item.uri,
                          name: item.name,
                          length: item.length,
                          buildYear: item.buildYear,
                          numCabins: item.numCabins,
                          builder: item.builder,
                          tags: item.tags,
                          item,
                        })
                      "
                    >
                      <div v-if="item.tags" class="absolute top-2 left-2">
                        <Tags :tags="item.tags" :only="['claimed', 'claimed_ca']" />
                      </div>
                      <AssetViewerPlaceholder
                        class="w-full object-cover shadow-md rounded-lg"
                        :url="replacePathToMediaUris(item.uri, item.hero)[0]"
                        :blur="item.blur"
                        :widthDescriptors="['320w']"
                      />
                    </button>
                    <!-- Yacht Card Text Section -->
                    <div class="mt-2 flex gap-x-1">
                      <h6 class="w-full self-center line-clamp">
                        {{ item.name || UNNAMED_YACHT }}
                      </h6>
                    </div>
                    <div class="flex flex-row items-center mb-2 gap-x-2.5 text-sm text-gray-400 dark:text-white">
                      <span v-if="item.length" class="text-sm text-gray-400">
                        {{ item.length }}m ({{ (item.length * 3.2808).toFixed(1) }}ft)
                      </span>
                      <div v-if="item.numCabins" class="flex items-center self-start gap-x-1.5">
                        <OutlineSleeping class="size-4 stroke-gray-500 dark:stroke-gray-400" />
                        <span class="text-gray-500 dark:text-gray-400">{{ item.numCabins }}</span>
                      </div>
                      <div v-if="item.numSleeps" class="flex items-center self-start gap-x-1.5">
                        <OutlinePerson class="size-4 stroke-gray-500 dark:stroke-gray-400" />
                        <span class="text-gray-500 dark:text-gray-400">{{ item.numSleeps }}</span>
                      </div>
                    </div>
                    <button
                      class="inline-flex w-fit sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border text-sm px-5 py-2.5 text-center items-center"
                      :disabled="presentationComputed.hasItem(item.uri, ProposalItemType.VESSEL)"
                      :class="
                        presentationComputed.hasItem(item.uri, ProposalItemType.VESSEL)
                          ? 'pointer-events-none border-spacing-0 text-gray-200 dark:text-gray-600 border-gray-200 dark:border-gray-600 dark:bg-gray-800'
                          : 'border-primary-600 dark:border-primary-500 hover:border-primary-800 dark:hover:border-primary-800 text-primary-600 dark:text-primary-500 hover:text-white dark:hover:text-white focus:text-white dark:focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300'
                      "
                      @click="selectYacht(item)"
                    >
                      <SolidPlus
                        v-if="!presentationComputed.hasItem(item.uri, ProposalItemType.VESSEL)"
                        class="size-5 shrink-0 mr-2 self-center"
                      />
                      {{
                        presentationComputed.hasItem(item.uri, ProposalItemType.VESSEL) ? 'Selected' : 'Select Yacht'
                      }}
                    </button>
                  </div>
                </template>
                <template #after>
                  <template v-if="!isLastPage">
                    <VisibleObserver @visible="refineNext" />
                  </template>
                  <template v-else="isLastPage">
                    <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                  </template>
                </template>
              </ListGrid>
            </template>
          </template>
        </ais-infinite-hits>
      </div>
    </div>
    <div class="w-full md:w-1/4 h-1/4 md:h-full">
      <div class="h-full rounded-lg bg-gray-100 dark:bg-gray-800">
        <p class="pt-4 px-4">Selected Yachts</p>
        <draggable
          v-if="presentationComputed.yachts?.length"
          tag="ul"
          item-key="index"
          draggable=".selected-yacht"
          class="h-[calc(100%_-_4rem)] overflow-auto"
          :disabled="!isEditSaved"
          :list="presentationComputed.yachts"
          @start="isDragging = true"
          @dragend="reorder"
        >
          <template #item="{ element, index }">
            <li
              v-if="element.item?.line_1"
              id="selected-yacht"
              class="group relative selected-yacht transition-[margin] p-4 rounded-md bg-gray-50 dark:bg-gray-900"
              :class="[
                isEditSaved ? (isDragging ? 'cursor-grabbing' : 'cursor-grab') : 'cursor-not-allowed',
                isEditSaved && draggableYacht?.index === index && !isDragging ? 'my-4 ml-8 mr-4' : 'm-4',
              ]"
              @mouseenter="draggableYacht = { uri: element.item.uri, index }"
              @mouseleave="draggableYacht = null"
            >
              <SolidDrag
                v-if="!isDragging"
                class="transition-opacity absolute -left-6 top-1/2 -translate-y-1/2 size-6 shrink-0 text-gray-500 dark:text-gray-400"
                :class="isEditSaved && draggableYacht?.index === index ? 'opacity-100' : 'opacity-0'"
              />
              <div v-if="element.item?.line_1" class="font-bold">{{ element.item.line_1 }}</div>
              <div class="invisible absolute -top-2 -right-2">
                <button
                  class="w-5 h-5 hover:cursor-pointer"
                  :class="{
                    'group-hover:visible': isEditSaved,
                  }"
                  data-popover-placement="bottom"
                  :data-popover-target="`remove-yacht-${index}`"
                  @click="
                    deleteModalHelper.show({
                      title: 'Remove this yacht?',
                      index: index,
                      name: element.item.line_1,
                    })
                  "
                >
                  <SolidCircleX
                    class="w-full h-full fill-gray-400 dark:fill-gray-500 hover:fill-gray-500 dark:hover:fill-gray-400"
                  />
                </button>
                <div
                  data-popover
                  role="tooltip"
                  :id="`remove-yacht-${index}`"
                  class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
                >
                  <div class="p-2">Remove</div>
                  <div data-popper-arrow></div>
                </div>
              </div>
            </li>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
