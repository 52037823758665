import { FontItem } from '@ankor-io/common/proposal/Proposal'

export const listFonts = async (): Promise<FontItem[]> => {
  const apiKey: string = 'AIzaSyD8rparMwTNd0GcZAWayvVDO8e_rbq_fTQ'
  const res: Response = await fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}&sort=popularity`, {
    method: 'GET',
  })

  if (res.status !== 200) {
    return []
  }

  const json: any = await res.json()

  return json.items as FontItem[]
}

export interface FontService {
  listFonts: () => Promise<FontItem[]>
}

export const useFontService = (): FontService => {
  return {
    listFonts: listFonts,
  } as FontService
}
