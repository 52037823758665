<script setup lang="ts">
import debounce from 'lodash.debounce'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { isValidURL } from '@ankor-io/common/url/isValidURL'

import HelpTip from '@/components/HelpTip.vue'
import Toggle from '@/components/Toggle.vue'
import type {
  Alignment,
  KeyValueFeatured,
  Size,
  VesselShowcaseLayoutTemplate,
  VesselShowcaseSectionData,
} from '@/sections/vessel-showcase/types/types'
import { removeHtmlTagsFromString } from '@/utils/stringManipulations'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The vessel showcase data
   */
  data: VesselShowcaseSectionData
  /**
   * The vessel layout
   */
  layout: VesselShowcaseLayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: { sectionId: string; data: VesselShowcaseSectionData }): void
  (e: 'update:layout', value: { sectionId: string; layout: VesselShowcaseLayoutTemplate }): void
}>()

const optionsForAlignment: { id: string; alignment: Alignment }[] = [
  { id: 'left-alignment-radio', alignment: 'left' },
  { id: 'center-alignment-radio', alignment: 'center' },
  { id: 'right-alignment-radio', alignment: 'right' },
]

const optionsForSizeSelector: { id: string; size: Size }[] = [
  { id: 'small-size-radio', size: 'small' },
  { id: 'medium-size-radio', size: 'medium' },
  { id: 'large-size-radio', size: 'large' },
]

const optionsForImageHeight: { id: string; imageHeight: Size }[] = [
  { id: 'small-imageHeight-radio', imageHeight: 'small' },
  { id: 'medium-imageHeight-radio', imageHeight: 'medium' },
  { id: 'large-imageHeight-radio', imageHeight: 'large' },
]

const updateValue = (dataKey: string, value: string | KeyValueFeatured[]) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      ...props.data,
      [dataKey]: value,
    },
  })
}

const updateFeature = (featureIndex: number, dataKey: 'featured', value: boolean) => {
  const updatedFeatures: KeyValueFeatured[] = ObjectUtil.deepCopy(props.data.features)
  updatedFeatures[featureIndex][dataKey] = value
  updateValue('features', updatedFeatures)
}

const updateButtonLink = (value: string) => {
  if (!isValidURL(value) && !value.startsWith('http')) {
    updateValue('buttonLink', 'https://' + value)
  } else {
    updateValue('buttonLink', value)
  }
}

const toggleHighlights = (index: number, feature: KeyValueFeatured) => {
  updateFeature(index, 'featured', !feature.featured)
}

const toggleOption = (dataKey: string, value: boolean) => {
  emit('update:layout', {
    sectionId: props.id,
    layout: {
      type: props.layout.type,
      options: { ...props.layout.options, [dataKey]: value },
    },
  })
}

const setColour = (dataKey: string, event: Event): void => {
  const target = event.target as HTMLInputElement
  emit('update:layout', {
    sectionId: props.id,
    layout: {
      type: props.layout.type,
      options: { ...props.layout.options, [dataKey]: target.value },
    },
  })
}
</script>
<template>
  <div class="flex flex-col gap-y-5">
    <HelpTip tip="Toggle features that need to be featured" />

    <div class="h-[calc(100vh-17rem)] overflow-y-auto flex flex-col pr-2 gap-x-1 gap-y-2">
      <!-- Size  -->
      <p class="text-xs font-semibold text-gray-900 dark:text-white">
        Image Width <span class="font-normal">(Based on screen width 50%, 75%, 100%)</span>
      </p>
      <ul class="flex items-center text-xs font-medium rounded-lg">
        <li v-for="option in optionsForSizeSelector" :key="option.id" class="flex w-full items-center pl-2">
          <input
            :id="option.id"
            type="radio"
            :checked="option.size === props.layout.options?.size"
            :value="option.size"
            name="sizeRadioOptions"
            @click="
              $emit('update:layout', {
                sectionId: props.id,
                layout: {
                  type: props.layout.type,
                  options: { ...props.layout.options, size: option.size },
                },
              })
            "
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
          />
          <label :for="option.id" class="py-2 ml-2 capitalize text-gray-600 dark:text-gray-300">
            {{ option.size }}
          </label>
        </li>
      </ul>

      <!-- Height options -->
      <p class="text-xs font-semibold text-gray-900 dark:text-white">
        Image Height <span class="font-normal">(Based on screen height 25%, 50%, 75%)</span>
      </p>
      <ul class="flex items-center text-xs font-medium rounded-lg">
        <li v-for="option in optionsForImageHeight" :key="option.id" class="flex w-full items-center pl-2">
          <input
            :id="option.id"
            type="radio"
            :checked="option.imageHeight === props.layout.options?.imageHeight"
            :value="option.imageHeight"
            name="imageHeightRadioOptions"
            @click="
              $emit('update:layout', {
                sectionId: props.id,
                layout: {
                  type: props.layout.type,
                  options: { ...props.layout.options, imageHeight: option.imageHeight },
                },
              })
            "
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
          />
          <label :for="option.id" class="py-2 ml-2 capitalize text-gray-600 dark:text-gray-300">{{
            option.imageHeight
          }}</label>
        </li>
      </ul>

      <!-- Alignment options -->
      <p class="text-xs font-semibold text-gray-900 dark:text-white">Alignment</p>
      <ul class="flex items-center text-xs font-medium rounded-lg">
        <li v-for="option in optionsForAlignment" :key="option.id" class="flex w-full items-center pl-2">
          <input
            :id="option.id"
            type="radio"
            :checked="option.alignment === props.layout.options?.alignment"
            :value="option.alignment"
            name="alignmentRadioOptions"
            @click="
              $emit('update:layout', {
                sectionId: props.id,
                layout: {
                  type: props.layout.type,
                  options: { ...props.layout.options, alignment: option.alignment },
                },
              })
            "
            class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
          />
          <label :for="option.id" class="py-2 ml-2 capitalize text-gray-600 dark:text-gray-300">{{
            option.alignment
          }}</label>
        </li>
      </ul>

      <!-- Button -->
      <p class="text-sm font-semibold mb-2 border-b text-gray-900 dark:text-white border-gray-200">Button</p>
      <div class="relative">
        <input
          type="text"
          id="input_buttonText"
          class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder=" "
          :value="props.data.buttonText"
          @blur="updateValue('buttonText', ($event.target as HTMLInputElement).value)"
        />
        <label
          for="input_buttonText"
          class="rounded-t-lg cursor-text absolute text-sm text-gray-500 transition-transform duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 dark:text-gray-400 dark:bg-gray-700 peer-focus:dark:text-blue-500"
        >
          Button Title
        </label>
      </div>
      <div class="relative">
        <input
          type="text"
          id="input_buttonLink"
          class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder=" "
          :value="props.data.buttonLink"
          @blur="updateButtonLink(($event.target as HTMLInputElement).value)"
        />
        <label
          for="input_buttonLink"
          class="rounded-t-lg cursor-text absolute text-sm text-gray-500 transition-transform duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 dark:text-gray-400 dark:bg-gray-700 peer-focus:dark:text-blue-500"
        >
          Button Link
        </label>
      </div>

      <!-- Visibility -->
      <p class="text-sm font-semibold border-b text-gray-900 dark:text-white border-gray-200">Visibility</p>
      <div class="flex flex-col gap-x-1 gap-y-2">
        <Toggle
          name="showDescription"
          text="Show Description"
          :reversed="true"
          :is-bold="false"
          :value="props.layout.options?.showDescription"
          @click.prevent="toggleOption('showDescription', !props.layout.options?.showDescription)"
        />
        <Toggle
          name="showButton"
          text="Show Button"
          :reversed="true"
          :is-bold="false"
          :value="props.layout.options?.showButton"
          @click.prevent="toggleOption('showButton', !props.layout.options?.showButton)"
        />
        <Toggle
          name="showFeatures"
          text="Show Features"
          :reversed="true"
          :is-bold="false"
          :value="props.layout.options?.showFeatures"
          @click.prevent="toggleOption('showFeatures', !props.layout.options?.showFeatures)"
        />
        <Toggle
          name="showPricing"
          text="Show Pricing"
          :reversed="true"
          :is-bold="false"
          :value="props.layout.options?.showPricing"
          @click.prevent="toggleOption('showPricing', !props.layout.options?.showPricing)"
        />
      </div>

      <!-- Key Featured -->
      <p class="text-sm font-semibold border-b text-gray-900 dark:text-white border-gray-200">Highlights</p>
      <div class="flex justify-between items-center">
        <label for="backgroundColor" class="text-gray-600 dark:text-gray-300 text-xs text-center mr-2">
          Background
        </label>
        <button
          id="backgroundColor"
          type="button"
          class="rounded-lg text-center flex justify-center items-center h-6 w-12 drop-shadow-md"
          :style="{ background: props.layout.options?.highlightsBackground }"
        >
          <input
            class="font-medium rounded-lg text-sm text-center items-center cursor-pointer h-12 w-12 opacity-0"
            type="color"
            id="html5colorpickerHighlights"
            @change="setColour('highlightsBackground', $event)"
            @input="setColour('highlightsBackground', $event)"
            :value="props.layout.options?.highlightsBackground"
          />
        </button>
      </div>

      <Toggle
        name="showBorder"
        text="Show Border"
        :reversed="true"
        :is-bold="false"
        :value="props.layout.options?.showHighlightsBorder"
        @click.prevent="toggleOption('showHighlightsBorder', !props.layout.options?.showHighlightsBorder)"
      />

      <!-- Highlighted Features toggle visibility -->
      <p class="text-xs font-semibold text-gray-900 dark:text-white">Highlighted Features</p>
      <div class="flex flex-col gap-x-1 gap-y-2">
        <Toggle
          v-for="(feature, index) in props.data.features"
          :key="`${removeHtmlTagsFromString(feature.key)}-${index}`"
          :name="removeHtmlTagsFromString(feature.key)"
          :text="removeHtmlTagsFromString(feature.key)"
          :reversed="true"
          :is-bold="false"
          :value="feature.featured"
          @click.prevent="toggleHighlights(index, feature)"
        />
      </div>

      <!-- Features -->
      <p class="text-sm font-semibold border-b text-gray-900 dark:text-white border-gray-200">Features</p>
      <div class="flex justify-between items-center">
        <label for="featuresBackgroundColor" class="text-gray-600 dark:text-gray-300 text-xs text-center mr-2">
          Background
        </label>
        <button
          id="featuresBackgroundColor"
          type="button"
          class="rounded-lg text-center flex justify-center items-center h-6 w-12 drop-shadow-md"
          :style="{ background: props.layout.options?.featuresBackground }"
        >
          <input
            class="font-medium rounded-lg text-sm text-center items-center cursor-pointer h-12 w-12 opacity-0"
            type="color"
            id="html5colorpickerFeatures"
            :value="props.layout.options?.featuresBackground"
            @change="setColour('featuresBackground', $event)"
            @input="setColour('featuresBackground', $event)"
          />
        </button>
      </div>

      <!-- Pricing -->
      <p class="text-sm font-semibold border-b text-gray-900 dark:text-white border-gray-200">Pricing</p>
      <div class="flex justify-between items-center">
        <label for="pricingHeaderBackgroundColor" class="text-gray-600 dark:text-gray-300 text-xs text-center mr-2">
          Header Background
        </label>
        <button
          id="pricingHeaderBackgroundColor"
          type="button"
          class="rounded-lg text-center flex justify-center items-center h-6 w-12 drop-shadow-md"
          :style="{ background: props.layout.options?.pricingHeaderBackground }"
        >
          <input
            type="color"
            id="html5colorpickerPricingHeader"
            class="font-medium rounded-lg text-sm text-center items-center cursor-pointer h-12 w-12 opacity-0"
            :value="props.layout.options?.pricingHeaderBackground"
            @change="setColour('pricingHeaderBackground', $event)"
            @input="
              debounce(() => {
                setColour('pricingHeaderBackground', $event)
              }, 100)
            "
          />
        </button>
      </div>

      <div class="flex justify-between items-center">
        <label for="pricingBodyBackgroundColor" class="text-gray-600 dark:text-gray-300 text-xs text-center mr-2">
          Body Background
        </label>
        <button
          id="pricingBodyBackgroundColor"
          type="button"
          class="rounded-lg text-center flex justify-center items-center h-6 w-12 drop-shadow-md"
          :style="{ background: props.layout.options?.pricingBodyBackground }"
        >
          <input
            type="color"
            id="html5colorpickerPricingBody"
            class="font-medium rounded-lg text-sm text-center items-center cursor-pointer h-12 w-12 opacity-0"
            :value="props.layout.options?.pricingBodyBackground"
            @change="setColour('pricingBodyBackground', $event)"
            @input="
              debounce(() => {
                setColour('pricingBodyBackground', $event)
              }, 100)
            "
          />
        </button>
      </div>
    </div>
  </div>
</template>
