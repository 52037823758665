import { Runnable } from '@ankor-io/common/lang/functional.types'
import { Document, DocumentTemplate, EditableDocument, JsonDocument } from '@ankor-io/common/proposal/Document'
import { EditableSection, JsonSection, Section } from '@ankor-io/common/proposal/Section'
import {
  EditableSlide,
  EditableSlideState,
  ProposalEditableSlide,
  ProposalEditableSlideInterface,
  Slide,
} from '@ankor-io/common/proposal/Slide'

/**
 * Document interface implementation
 */
export class ProposalDocument implements Document {
  readonly header: Section<any> | null
  readonly footer: Section<any> | null
  readonly slides: Slide[]

  constructor(header: Section<any> | null, footer: Section<any> | null, slides: Slide[]) {
    this.header = header
    this.footer = footer
    this.slides = slides
  }

  asTemplate(): DocumentTemplate {
    // TODO: find out what are the rules for turning this document into a template
    throw new Error('Method not implemented yet.')
  }

  toJson(): JsonDocument {
    return {
      header: this.header ? this.header.toJson() : ({} as JsonSection<any>),
      footer: this.footer ? this.footer.toJson() : ({} as JsonSection<any>),
      slides: this.slides.map((slide: Slide) => slide.toJson()),
    }
  }
}

/**
 * Editable Document interface implementation
 */
export class EditableProposalDocument implements EditableDocument {
  private header: EditableSection<any> | null
  readonly footer: EditableSection<any> | null
  readonly slides: ProposalEditableSlide[]

  constructor(
    header: EditableSection<any> | null,
    footer: EditableSection<any> | null,
    slides: ProposalEditableSlide[],
  ) {
    this.header = header
    this.footer = footer
    this.slides = slides
  }

  setSlides(slides: ProposalEditableSlideInterface[]) {
    // remove them all
    this.slides.splice(0, this.slides.length)
    // then set them all
    this.addSlides(slides)
  }

  addSlides(slides: ProposalEditableSlide[], callback?: { then: Runnable<any>; catch: Runnable<any> }): void {
    slides.forEach((slide) => this.addSlide(slide, callback))
  }

  addSlide(
    slide: ProposalEditableSlide,
    callback?: { then: Runnable<any>; catch: Runnable<any> },
    indexPosition?: number,
  ): void {
    if (indexPosition !== undefined) {
      this.slides.splice(indexPosition, 0, slide)
    } else {
      this.slides.push(slide)
    }

    if (slide.getState() === EditableSlideState.NEEDS_HYDRATION) {
      slide.hydrate().then(callback?.then).catch(callback?.catch)
    }
  }

  removeSlide(index: number, callback?: { then: Runnable<any>; catch: Runnable<any> }): void {
    const slide = this.slides[index]
    this.slides.splice(index, 1)
    slide.deHydrate().then(callback?.then).catch(callback?.catch)
  }

  setHeader(section: EditableSection<any> | null): void {
    this.header = section
  }
  getHeader(): EditableSection<any> | null {
    return this.header
  }

  toJson(): JsonDocument {
    return {
      header: this.header ? this.header.toJson() : ({} as JsonSection<any>),
      footer: this.footer ? this.footer.toJson() : ({} as JsonSection<any>),
      slides: this.slides.map((slide: EditableSlide) => slide.toJson()),
    }
  }
  asTemplate(): DocumentTemplate {
    // TODO: find out what are the rules for turning this document into a template
    throw new Error('Method not implemented.')
  }
}
