import { ItineraryIndexItem } from '@ankor-io/common/index/ItineraryIndexItem'
import { ProposalIndexItem } from '@ankor-io/common/index/ProposalIndexItem'
import { Serializable } from '@ankor-io/common/lang/Serializable'
import { SectionTemplate } from '@ankor-io/common/proposal/Section'

import { VesselIndexItem } from '../index/VesselIndexItem'
import { YachtIndexItem } from '../index/YachtIndexItem'
import { Document as DocumentInterface, EditableDocument as EditableDocumentInterface, JsonDocument } from './Document'
import { SlideTemplate } from './Slide'

/**
 * All dates are in format: YYYY-MM-DD 
 */
export const COMPATIBILITY_DATES = {
  /**
   * The latest compatibility date. Date when proposal type changes were introduced.
   * - hasItems: boolean
   * - compatibilityDate: string
   * - yachts: ProposalItem[]
   * - itineraries: ProposalItem[]
   */
  LATEST: '2024-12-05',
  /**
   * An ordered list of all the compatibility dates
   */
  ALL: ['2024-12-05'],

  getCurrentDateIndex(currentDate?: string): number {
    if (!currentDate) {
      return 0
    }
    // if the current date is not found, start from the beginning
    return this.ALL.indexOf(currentDate) > -1 ? this.ALL.indexOf(currentDate) : 0
  },

  getNextDate(currentDate?: string): string {
    if (!currentDate) {
      return this.ALL[0]
    }
    const currentIndex = this.ALL.indexOf(currentDate)
    // if the current date is not found, we have started from the beginning
    if (currentIndex === -1) {
      return this.ALL[0]
    }
    return this.ALL[currentIndex + 1]
  }
}

/**
 * Static Template Object structure
 */
export type Template = {
  /**
   * theme properties
   * && yarn workspace @ankor-io/hatch ci:build && BRANCH_NAME=$BRANCH_NAME yarn workspace @ankor-io/hatch ci:provision
   */
  theme: ThemeTemplate
  /**
   * header static template
   */
  header: HeaderTemplate
  /**
   * footer static template
   */
  footer: FooterTemplate
  /**
   * yacht slide static template
   */
  yacht: SlideTemplate
  /**
   * vessel slide static template
   */
  vessel: SlideTemplate
  /**
   * itinerary slide static template
   */
  itinerary: SlideTemplate
  /**
   * The identity of the user profile and company
   */
  identity: Identity
}

/**
 * Contains the details of a users profile and the broker company
 */
export type Identity = {
  // profile
  givenName: string
  surname: string
  image: string
  role: string
  email: string
  phone: string
  website: string
  // company
  companyImage: string
  companyName: string
  companyAddress: string
  companyPhone: string
}

/**
 * Definition of a font item
 */
export type FontItem = {
  family: string
  variants: string[]
  subsets: string[]
  version: string
  lastModified: string
  files: {
    regular: string
  }
  category: string
  kind: string
  css?: string
}

/**
 * Theme properties
 */
export type ThemeTemplate = {
  color: {
    primary: string
    background: string
    shading: string
    accent: string
  }
  font: {
    heading: {
      type: FontItem
      color: string
    }
    subheading: {
      type: FontItem
      color: string
    }
    primary: {
      type: FontItem
      color: string
    }
  }
  density: string
  borders: string
}

/**
 * Header static template structure
 */
export type HeaderTemplate = {
  section: SectionTemplate
}

/**
 * Footer static template structure
 */
export type FooterTemplate = {
  section: SectionTemplate
}

/**
 * Structure of a proposal object
 */
export type JsonProposal = {
  /**
   * The compatibility date of this document. This is a new property and can be undefined in old proposals.
   * The date is in format: YYYY-MM-DD 
   */
  compatibilityDate?: string
  uri: string
  template: Template
  document: JsonDocument
  /**
   * @deprecated
   */
  proposalItems: ProposalItem[]
  yachts: ProposalItem[]
  itineraries: ProposalItem[]
  flow: 'NEEDS_INIT' | 'INITIALIZED'
  indexable: boolean
  tags: string[]
  internalName: string
  /**
   * @deprecated The proposal client facing name - this is deprecated and removed from the UI, but still exists in the back-end
   */
  externalName?: string
  /**
   * Tracks if the proposal is empty or not. This is a new property and can therefore be undefined in old proposals
   */
  hasItems?: boolean
}

/**
 * Structure of a proposal object
 */
export interface Proposal extends Serializable<JsonProposal> {
  /**
   * The date is in the format of 'DD-MM-YYYY'
   */
  readonly compatibilityDate: string
  /**
   * The proposal uri
   */
  readonly uri: string
  /**
   * the proposal static template
   */
  readonly template: Template
  /**
   * the proposal document
   */
  readonly document: DocumentInterface
  /**
   * the list of proposal items. This becomes a computed property in {@link COMPATIBILITY_DATES.LATEST}
   * @deprecated superseded by {@link yachts} and {@link itineraries}
   */
  readonly proposalItems: ProposalItem[]
  /**
   * the list of yachts in the proposal
   */
  readonly yachts: ProposalItem[]
  /**
   * the list of itineraries in the proposal
   */
  readonly itineraries: ProposalItem[]
  /**
   * the internal name of proposal
   */
  readonly internalName: string
  /**
   * @deprecated The external name of the proposal - this is deprecated and removed from the UI, but still exists in the back-end
   */
  readonly externalName?: string
  /**
   * the flow of proposal
   */
  readonly flow: 'NEEDS_INIT' | 'INITIALIZED'
  /**
   * the flag to determine if proposal is indexable
   */
  readonly indexable: boolean
  /**
   * the list of tags
   */
  readonly tags: string[]
  /**
   * Returns whether the proposal is empty or not
   */
  readonly hasItems: boolean
}

export interface EditableProposal extends Serializable<JsonProposal> {
  readonly compatibilityDate: string
  /**
   * The proposal uri
   */
  readonly uri: string
  /**
   * the proposal static template
   */
  readonly template: Template
  /**
   * the proposal document
   */
  readonly document: EditableDocumentInterface
  /**
   * the list of proposal items. This becomes a computed property in {@link COMPATIBILITY_DATES.LATEST}
   * @deprecated superseded by {@link yachts} and {@link itineraries}
   */
  readonly proposalItems: ProposalItem[]
  /**
   * the list of yachts in the proposal
   */
  readonly yachts: ProposalItem[]
  /**
   * the list of itineraries in the proposal
   */
  readonly itineraries: ProposalItem[]
  /**
   * the internal name of proposal
   */
  readonly internalName: string
  /**
   * @deprecated The external name of the proposal - this is deprecated and removed from the UI, but still exists in the back-end
   */
  readonly externalName?: string
  /**
   * the flow of proposal
   */
  readonly flow: 'NEEDS_INIT' | 'INITIALIZED'
  /**
   * the flag to determine if proposal is indexable
   */
  readonly indexable: boolean
  /**
   * the list of tags
   */
  readonly tags: string[]
  /**
   * Returns whether the proposal is empty or not
   */
  readonly hasItems: boolean
  /**
   * Returns whether the proposal is in initialization state or not.
   * This is dictated by the sections, when all the sections have completed
   * hydration, the proposal is considered initialized
   */
  isInitializing(): boolean
  /**
   * Performs all the necessary cleanups before this proposal is
   * reassigned to a new value
   */
  destroy(): void
  /**
   * Add an item to the proposal in the appropriate list: 
   * {@link yachts} for {@link ProposalItemType.VESSEL} or {@link itineraries} for {@link ProposalItemType.ITINERARY}
   * 
   * Adds a slide to the document for the relative item.
   * Sets the {@link hasItems} flag to true.
   * 
   * @param item the item to add to the proposal
   */
  addItem(item: ProposalItem): void
  /**
   * Remove an item from the proposal appropriate list:
   * {@link yachts} for {@link ProposalItemType.VESSEL} or {@link itineraries} for {@link ProposalItemType.ITINERARY}
   * 
   * Removes the slide from the document for the relative to the item.
   * Sets the {@link hasItems} flag to false if there are no more items in the proposal.
   */
  removeItem(item: ProposalItem): void
  /**
   * Check if the proposal has an item with the given uri
   * @param uri the uri of the item to check
   * @param type the type of the item to check
   */
  hasItem(uri: string, type: ProposalItemType): boolean
}

/**
 * Represent a type of Item that can be added to a proposal
 */
export type ProposalItem = {
  /**
   * the proposal item type
   */
  type: ProposalItemType.ITINERARY | ProposalItemType.PROPOSAL | ProposalItemType.YACHT | ProposalItemType.VESSEL
  /**
   * The full uri for this proposal item
   */
  uri: string
  /**
   * one of the indexed item
   * ItineraryIndexItem | ProposalIndexItem | YachtIndexItem | VesselIndexItem
   */
  item: ItineraryIndexItem | ProposalIndexItem | YachtIndexItem | VesselIndexItem | null
}

/**
 * Cart document object structure
 */
export type CartDocument = {
  /**
   * uri of proposal in cart
   * @deprecated not required to store the uri in the cart document
   */
  proposalUri: string
  /**
   * internal name of proposal in cart
   */
  proposalInternalName?: string
  /**
   * flag to determine
   * if proposal document needs to be created
   * or is already created,
   * enables loading of same proposal document
   * without generating a new proposal uri
   * everytime the cart is mounted
   * @deprecated not required to keep a state of the cart document
   */
  createDoc: boolean
  /**
   * uri of the proposal that has been dropped / added in the cart
   * enables us to provide invalid state of the cart
   * in order to restrict user from dropping multiple proposals
   */
  droppedProposalUri: string
  /**
   * The proposal items in the cart
   */
  proposalItems: ProposalItem[]
  /**
   * The proposal template
   */
  proposalTemplate?: Template
}

/**
 * Available Proposal Item types
 */
export enum ProposalItemType {
  YACHT = 'yacht',
  VESSEL = 'vessel',
  PROPOSAL = 'proposal',
  ITINERARY = 'itinerary',
}
