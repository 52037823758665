import { EditableProposal, JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { EditableSection, EditableSectionState, JsonSection, SectionTemplate } from '@ankor-io/common/proposal/Section'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { EditableSlideState, JsonSlide, ProposalEditableSlide } from '@ankor-io/common/proposal/Slide'

import { bindings } from '@/services/proposal/Binding'
import { EditableProposalDocument } from '@/services/proposal/Document'
import { EditableProposalImpl } from '@/services/proposal/ProposalImpl'
import { getUserTemplate } from '@/services/proposal/ProposalService'
import { adapt } from '@/services/proposal/adapter/EditableProposalAdapter'

const deserialize = (jsonProposal: JsonProposal): EditableProposal => {
  // build the slides
  const slides: ProposalEditableSlide[] = jsonProposal.document.slides.map((jsonSlide: JsonSlide) => {
    // create the slide
    const slide: ProposalEditableSlide = bindings.proposalEditableSlides.get(jsonSlide.type)!({
      id: jsonSlide.uri,
      uri: jsonSlide.uri,
      proposalUri: jsonProposal.uri,
      state: jsonSlide.state || EditableSlideState.NEEDS_HYDRATION,
    })

    const sections = jsonSlide.sections.map((jsonSection: JsonSection<any>) => {
      if (!bindings.proposalsEditableSections.has(jsonSection.type)) {
        return null
      }

      return bindings.proposalsEditableSections.get(jsonSection.type)!({
        template: jsonSection as SectionTemplate,
        slideUri: slide.getUri(),
        source: jsonSection.data,
        id: jsonSection.id,
        state: jsonSection.state || EditableSectionState.INITIALIZED,
      })
    })

    // attach the sections so we don't hydrate
    sections.forEach((section: EditableSection<any> | null) => section && slide.addSection(section))

    // return the slide
    return slide
  })

  // always build the header section
  const header: EditableSection<any> =
    Object.keys(jsonProposal.document.header).length > 0
      ? bindings.proposalsEditableSections.get(jsonProposal.document.header.type as SectionType)!({
          template: jsonProposal.document.header as SectionTemplate,
          slideUri: null,
          source: jsonProposal.document.header.data,
          state: jsonProposal.document.header.state || EditableSectionState.INITIALIZED,
        })
      : bindings.proposalsEditableSections.get(SectionType.NAV_BAR)!({
          template: {
            type: SectionType.NAV_BAR,
            layout: { type: 'default', options: 'center' },
            data: {},
          } as SectionTemplate,
          slideUri: null,
          source: {},
          state: EditableSectionState.NEEDS_INIT,
        })

  // build the footer section if defined
  const footer: EditableSection<any> | null =
    Object.keys(jsonProposal.document.footer).length > 0
      ? bindings.proposalsEditableSections.get(jsonProposal.document.footer.type as SectionType)!({
          template: jsonProposal.document.footer as SectionTemplate,
          slideUri: null,
          source: jsonProposal.document.footer.data,
          state: jsonProposal.document.footer.state || EditableSectionState.INITIALIZED,
        })
      : null

  // update the template if required
  const template = getUserTemplate(jsonProposal.template)

  // create the proposal object and return it
  return adapt(
    new EditableProposalImpl(
      template,
      new EditableProposalDocument(header, footer, slides),
      jsonProposal.uri,
      jsonProposal.proposalItems,
      jsonProposal.yachts,
      jsonProposal.itineraries,
      jsonProposal.flow,
      jsonProposal.indexable,
      jsonProposal.tags,
      jsonProposal.internalName,
      // it's fine for this to go with a bang for now
      jsonProposal.compatibilityDate!,
      jsonProposal?.externalName || '',
    ),
  )
}

export const EditableProposalDeserializer = {
  deserialize: deserialize,
}

export declare type EditableProposalDeserializer = {
  deserialize: (jsonProposal: JsonProposal) => EditableProposal
}
