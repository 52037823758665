<script lang="ts" setup>
import debounce from 'lodash.debounce'

import { VesselPricingLayoutTemplate, VesselPricingSectionData } from './types/types'

type Props = {
  id: string
  proposalUri: string
  data: VesselPricingSectionData
  layout: VesselPricingLayoutTemplate
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:layout', value: { sectionId: string; layout: VesselPricingLayoutTemplate }): void
}>()

const setColour = (optionKey: string, event: Event): void => {
  const target = event.target as HTMLInputElement
  const layout = {
    ...props.layout,
    options: {
      ...props.layout.options,
      [optionKey]: target.value,
    },
  }

  emit('update:layout', { sectionId: props.id, layout })
}
</script>
<template>
  <div class="flex flex-col gap-y-1">
    <div class="flex justify-between items-center">
      <label for="pricingHeaderBackgroundColour" class="text-gray-600 dark:text-gray-300 text-xs text-center mr-2">
        Header Background
      </label>
      <button
        type="button"
        id="pricingHeaderBackgroundColour"
        class="rounded-lg text-center flex justify-center items-center h-6 w-12 drop-shadow-md"
        :style="{ background: props.layout.options?.pricingHeaderBackground }"
      >
        <input
          type="color"
          id="html5colourpickerPricingHeader"
          class="font-medium rounded-lg text-sm text-center items-center cursor-pointer h-6 w-12 opacity-0"
          :value="props.layout.options?.pricingHeaderBackground"
          @change="setColour('pricingHeaderBackground', $event)"
          @input="
            debounce(() => {
              setColour('pricingHeaderBackground', $event)
            }, 100)
          "
        />
      </button>
    </div>

    <div class="flex justify-between items-center">
      <label for="pricingBodyBackgroundColour" class="text-gray-600 dark:text-gray-300 text-xs text-center mr-2">
        Body Background
      </label>
      <button
        type="button"
        id="pricingBodyBackgroundColour"
        class="rounded-lg text-center flex justify-center items-center h-6 w-12 drop-shadow-md"
        :style="{ background: props.layout.options?.pricingBodyBackground }"
      >
        <input
          type="color"
          id="html5colourpickerPricingBody"
          class="font-medium rounded-lg text-sm text-center items-center cursor-pointer h-6 w-12 opacity-0"
          :value="props.layout.options?.pricingBodyBackground"
          @change="setColour('pricingBodyBackground', $event)"
          @input="
            debounce(() => {
              setColour('pricingBodyBackground', $event)
            }, 100)
          "
        />
      </button>
    </div>
  </div>
</template>
