import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M15.2727 15.9814L12 19.6364M12 19.6364L8.72729 15.9814M12 19.6364L12 10.9092"
    }),
    _createElementVNode("rect", {
      width: "16",
      height: "3",
      x: "4",
      y: "5",
      "stroke-width": "2",
      rx: "1"
    })
  ]))
}