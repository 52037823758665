import { Component } from 'vue'

import { SectionType } from '@ankor-io/common/proposal/SectionType'

import { AbstractSection } from '@/services/proposal/Section'

import VesselPricingSectionVue from './VesselPricingSection.vue'
import { VesselPricingSectionData } from './types/types'

export class VesselPricingSection extends AbstractSection<VesselPricingSectionData> {
  getType(): SectionType {
    return SectionType.VESSEL_PRICING
  }

  getComponent(): Component {
    return VesselPricingSectionVue
  }
}
