<script setup lang="ts">
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { ref } from 'vue'

export interface MarkerProps {
  /**
   * The label the marker will display
   */
  label: string

  placeName: string

  isCustom: boolean
}

defineProps<MarkerProps>()

/**
 * The label is absolute positioned on top of the svg.
 */
const active = ref(false)
</script>
<template>
  <div class="relative">
    <div class="absolute text-white" @mouseenter.stop="active = true" @mouseleave.stop="active = false">
      <div
        class="border-2 border-white min-w-[2.25rem] h-9 flex justify-center items-center rounded-full text-sm text-white whitespace-nowrap transition-all duration-300 ease-in-out overflow-hidden"
        :class="[isCustom ? 'bg-purple-500' : 'bg-primary-500', active ? 'px-1.5 max-w-xs' : 'px-0 max-w-[2.25rem]']"
      >
        {{ label }}
        <span
          class="transition-all duration-300 ease-in-out"
          :class="active ? 'opacity-100 pl-1.5' : 'opacity-0 pl-0 w-0'"
        >
          {{ placeName }}
        </span>
      </div>
      <div
        class="ml-2.5 -mt-0.5 h-0 w-0 border-l-8 border-r-8 border-t-8 border-solid border-l-transparent border-r-transparent border-t-white"
      ></div>
    </div>
  </div>
</template>
