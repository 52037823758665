<script setup lang="ts">
import { Ref, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'

import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import InputComponent from '@/components/Input.vue'
import { LayoutTemplate } from '@/sections/types'

import LinkButton from './assets/LinkButton.png'
import RoundedButton from './assets/RoundedButton.png'
import TransparentButton from './assets/TransparentButton.png'
import { ButtonData, ButtonLayout, ButtonStyles } from './types'

type ButtonConfigurationProps = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: ButtonData
  /**
   * The section's layout
   */
  layout: ButtonLayout
}

const props = defineProps<ButtonConfigurationProps>()

const title: Ref<string> = ref(props.data.title)
const link: Ref<string> = ref(props.data.link)

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<ButtonData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const updateLayout = (selectedLayout: LayoutTemplate): void => {
  const layout: LayoutTemplate = ObjectUtil.deepCopy(props.layout)

  layout.type = selectedLayout.type || props.layout.type
  layout.options = selectedLayout.options || props.layout.options

  emit('update:layout', { sectionId: props.id, layout: layout })
}

// The input field value update
const updateInputValue = (event: { field: string; value: string; layout?: LayoutTemplate }): void => {
  const data: ButtonData = ObjectUtil.deepCopy(props.data)
  if (event.field === 'title') {
    data.title = event.value
    title.value = event.value
  }

  if (event.field === 'link') {
    data.link = event.value
    link.value = event.value
  }

  emit('update:value', { sectionId: props.id, data })
}

const alignmentOptions = [
  {
    label: 'Left',
    id: 'left-alignment-radio',
    option: 'left',
  },
  {
    label: 'Centre',
    id: 'center-alignment-radio',
    option: 'center',
  },
  {
    label: 'Right',
    id: 'right-alignment-radio',
    option: 'right',
  },
]

const stylesOptions: ButtonStyles[] = [
  {
    type: 'rounded',
    title: 'Filled in rounded corner style',
    description: 'Bright and bold button design with your brand colour.',
    image: RoundedButton,
  },
  {
    type: 'transparent',
    title: 'Branded outline with transparent background',
    description: 'Sleek and elegant button design with rounded corners.',
    image: TransparentButton,
  },
  {
    type: 'link',
    title: 'Minimalist style with branded title',
    description: 'Low-key button design with branded font and colours.',
    image: LinkButton,
  },
]
</script>
<template>
  <HelpTip tip="Select a button style to apply to this component." />
  <InputComponent
    name="TitleInput"
    input-size="medium"
    title="Button title"
    background-color="bg-white"
    :value="title"
    @update:value="updateInputValue({ field: 'title', value: $event })"
  />
  <InputComponent
    name="LinkInput"
    input-size="medium"
    title="Paste link here"
    background-color="bg-white"
    :value="link"
    @update:value="updateInputValue({ field: 'link', value: $event })"
  />

  <div class="pb-4 border-b border-gray-200">
    <p class="text-sm font-semibold text-gray-900 dark:text-white">Alignment</p>
    <div class="flex gap-4">
      <div v-for="option in alignmentOptions" class="flex flex-col gap-2 mt-2 items-center">
        <input
          type="radio"
          name="alignmentRadioOptions"
          class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 focus:ring-2"
          :id="option.id"
          :value="option.label"
          :checked="option.option === layout.options"
          @click="updateLayout({ type: props.layout.type, options: option.option })"
        />
        <label for="alignmentRadioOptions" class="text-xs text-gray-600 dark:text-gray-300">{{ option.label }}</label>
      </div>
    </div>
  </div>

  <div class="overflow-y-auto flex flex-col gap-4">
    <div v-for="option in stylesOptions">
      <p class="text-sm font-semibold text-gray-900 dark:text-white">{{ option.title }}</p>
      <p class="text-xs mb-4 text-gray-500 dark:text-gray-400">{{ option.description }}</p>
      <div class="group relative" :class="option.type">
        <img class="w-full border border-gray-200" :src="option.image" />
        <!-- On hover apply -->
        <div v-if="layout.type !== option.type">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout({ type: option.type, options: props.layout.options })"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </div>
        <Badge class="top-4 right-4" text="Added" :show="layout.type === option.type" />
      </div>
    </div>
  </div>
</template>
