/**
 *
 * Track events
 */
export const PREFERRED_VIEW = 'Preferred View'
export const PRESENTATIONS_FILTERED_BY = 'Presentations Filtered By'
export const ROUTES_FILTERED_BY = 'Routes Filtered By'
export const VESSELS_FILTERED_BY = 'Vessels Filtered By'
export const PRESENTATIONS_SORTED_BY = 'Presentations Sorted By'
export const ROUTES_SORTED_BY = 'Routes Sorted By'
export const VESSELS_SORTED_BY = 'Vessels Sorted By'
export const NEW_PRESENTATION = 'New Presentation'
export const PRESENTATION_EDIT = 'Presentation Edit'
export const SHARE_PRESENTATION = 'Share Presentation'
export const DELETE_PRESENTATION = 'Delete Presentation'
export const DUPLICATE_PRESENTATION = 'Duplicate Presentation'
export const VESSEL_ADDED = 'Vessel Added To Presentation'
export const SECTION_ADDED = 'Section Added To Presentation'
export const CHANGE_ROUTE_NAME = 'Change Route Name'
export const SEARCH_FOR_LOCATION = 'Search For Location'
export const LOCATIONS_FILTERED_BY = 'Locations Filtered By'
export const EXPAND_CONTENT_PANEL = 'Expand Content Panel'
export const CLICK_PRESENTATION_EDITOR_TAB = 'Click Presentation Editor Tab'
export const CLICK_SKIP_TO_PRESENTATION_EDITOR_BUTTON = 'Click Skip To Presentation Editor Button'
export const PRESENTATION_NAME_CLICKED = 'Presentation Name Clicked'
export const EDIT_ROUTE = 'Edit Route'
export const DELETE_ROUTE = 'Delete Route'
export const PREVIEW_ROUTE = 'Preview Route'
export const CREATE_ROUTE = 'Create New Route'
export const DUPLICATE_ROUTE = 'Duplicate Route'
export const DOWNLOAD_ROUTE_PDF = 'Download Route PDF'
export const SELECT_ROUTE = 'Select Route In Presentation Editor'
export const REMOVE_ROUTE = 'Remove Route From Presentation Editor'
export const NAVIGATE_TO_HOME = 'Navigate To Home'
export const OPEN_SHARE_MODAL = 'Open Share Modal'
export const OPEN_PRESENTATION_PREVIEW = 'Open Presentation Preview'
export const LOGOUT = 'Logout'
export const SELECT_ANKOR_APP = 'Select Ankor App'
export const OPEN_ACTIONS_MENU = 'Open Actions Menu'
export const SHARED_LINK_VIEW = 'Shared Link View'
export const OPEN_PROFILE_COMPANY_SETTINGS = 'Open Profile & Company Settings'
export const CONFIRM_PROFILE_COMPANY_SETTINGS = 'Confirm Profile & Company Settings'
export const OPEN_STYLE_BRANDING_SETTINGS = 'Open Style & Branding Settings'
export const CONFIRM_STYLE_BRANDING_SETTINGS = 'Confirm Style & Branding Settings'
