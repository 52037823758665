export const WHEELHOUSE_2023_01_09_TOOLS_DEBUG = 'wheelhouse_2023_01_09_tools_debug'
export const WHEELHOUSE_20230516_YACHTS = 'wheelhouse_20230516_yachts'
export const WHEELHOUSE_20230711_PRODUCT_ONBOARDING = 'wheelhouse_20230711_product_onboarding'
export const WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE = 'wheelhouse_20230801_wysiwyg_proposal_in_mobile_mode'
export const WHEELHOUSE_20230809_FIX_MAP_MULTIPLE_LOCATIONS_TO_SHOW_IN_READONLY_VIEWS =
  'wheelhouse_20230809_fix_map_multiple_locations_to_show_in_readonly_views'
export const WHEELHOUSE_20230919_YACHT_SUMMARY_CARD_SECTION = 'wheelhouse_20230919_yacht_summary_card_section'
export const WHEELHOUSE_20240228_VESSEL_SLIDE = 'wheelhouse_20240228_vessel_slide'
export const WHEELHOUSE_20240625_ONBOARDING_INVITE_COLLEAGUES = 'wheelhouse_20240625_onboarding_invite_colleagues'
export const WHEELHOUSE_20250103_TRIPS_V2_APP = 'wheelhouse_20250103_trips_v2_app'
