import MapboxDraw from '@mapbox/mapbox-gl-draw'
import * as turf from '@turf/turf'
import { Map as MapboxMap } from 'mapbox-gl'

import { Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'

/**
 * Get segment index for the currently selected marker
 * @param segments segments collection
 * @param label the selected marker label
 */
export const getSegmentIndexForSelectedMarker = (segments: Segment[], label: string): number | null => {
  let selectedSegmentIndex: number | null = null
  segments.filter((segment: Segment, segmentIndex: number) =>
    segment.stops.filter((stop: Stop, stopIndex: number) => {
      if (!stop.place) {
        return false
      }
      const labelSuffix = segment.stops.length > 1 ? String.fromCharCode((stopIndex % 26) + 'a'.charCodeAt(0)) : ''
      const markerLabel = `${segmentIndex + 1}${labelSuffix}`
      if (label === markerLabel) {
        selectedSegmentIndex = segmentIndex
        return true
      }
      return false
    }),
  )

  return selectedSegmentIndex
}

/**
 * Gets the selected stop index for the currently selected marker
 * @param segments segments collection
 * @param label the selected marker label
 */
export const getStopIndexForSelectedMarker = (segments: Segment[], label: string) => {
  return segments.reduce((segAcc, segment, segmentIndex) => {
    const selectedStopIndex = segment.stops.findIndex((stop, stopIndex) => {
      if (!stop.place) {
        return false
      }
      const labelSuffix = segment.stops.length > 1 ? String.fromCharCode((stopIndex % 26) + 'a'.charCodeAt(0)) : ''
      const markerLabel = `${segmentIndex + 1}${labelSuffix}`
      return label === markerLabel
    })
    return selectedStopIndex === -1 ? segAcc : selectedStopIndex
  }, -1)
}

/**
 * Defining styles for lines drawn on map while route planning
 * styles for active and inactive lines
 * TODO: needs to be updated once product designs are in place
 */
export const drawStyles: object[] = [
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: ['all', ['==', 'active', 'false'], ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#d41812',
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#29ee0a',
      'line-dasharray': [0.2, 2],
      'line-width': 4,
    },
  },
]

/**
 * Print the geometry for the drawing
 */
export const logDrawingDetails = (map: MapboxMap, drawBar: MapboxDraw) => {
  const result: any = drawBar.getAll()
  const routeCoordinates = result.features[0].geometry?.coordinates

  const point: any = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Point',
          coordinates: routeCoordinates[0],
        },
      },
    ],
  }

  const route: any = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: routeCoordinates,
        },
      },
    ],
  }

  map.addSource('route', {
    type: 'geojson',
    data: route,
  })

  map.addSource('point', {
    type: 'geojson',
    data: point,
  })

  map.addLayer({
    id: 'route',
    source: 'route',
    type: 'line',
    paint: {
      'line-width': 2,
      'line-color': '#007cbf',
    },
  })

  map.addLayer({
    id: 'point',
    source: 'point',
    type: 'symbol',
    layout: {
      'icon-image': 'harbor',
      'icon-size': 1.5,
      'icon-rotate': ['get', 'bearing'],
      'icon-rotation-alignment': 'map',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
    },
  })
}

/**
 * this animates the route from start to finish
 * @param route route is a collection of geoJSON features
 * @param point point is a geoJSON
 */
export const animateRoute = (route: any, point: any) => {
  let counter = 0
  const animate = () => {
    // Calculate the distance in kilometers between route start/end point.
    const lineDistance = turf.lineDistance(route.features[0], { units: 'nauticalmiles' })

    const arc = []

    // Number of steps to use in the arc and animation, more steps means
    // a smoother arc and animation, but too many steps will result in a
    // low frame rate
    const steps = 1500

    // Draw an arc between the `origin` & `destination` of the two points
    for (let i = 0; i < lineDistance; i += lineDistance / steps) {
      const segment = turf.along(route.features[0], i, { units: 'nauticalmiles' })
      arc.push(segment.geometry.coordinates)
    }

    // Update the route with calculated arc coordinates
    route.features[0].geometry.coordinates = arc

    const start = route.features[0].geometry.coordinates[counter >= steps ? counter - 1 : counter]

    const end = route.features[0].geometry.coordinates[counter >= steps ? counter : counter + 1]

    if (!start || !end) return

    point.features[0].geometry.coordinates = route.features[0].geometry.coordinates[counter]

    // Calculate the bearing to ensure the icon is rotated to match the route arc
    // The bearing is calculate between the current point and the next point, except
    // at the end of the arc use the previous point and the current point
    point.features[0].properties.bearing = turf.bearing(
      turf.point(route.features[0].geometry.coordinates[counter >= steps ? counter - 1 : counter]),
      turf.point(route.features[0].geometry.coordinates[counter >= steps ? counter : counter + 1]),
    )

    // Update the source with this new data.
    // @ts-ignore
    map.getSource('point').setData(point)

    // Request the next frame of animation so long the end has not been reached.
    if (counter < steps) {
      requestAnimationFrame(animate)
    }

    counter = counter + 1
  }
}
