import { Component } from 'vue'

import { SlideType } from '@ankor-io/common/proposal/SlideType'

import { HydrationType } from './Section'

/**
 * The supported Sections types
 */
export enum SectionType {
  // Generic Sections
  IMAGE = 'image',
  SPACER = 'spacer',
  BUTTON = 'button',
  NAV_BAR = 'nav_bar',
  HEADING = 'heading',
  DISCLAIMERS = 'disclaimers',
  PROFILE_COMPANY = 'profile_company',
  ADDITIONAL_NOTES = 'additional_notes',
  EMBED = 'embed',
  // Itinerary Sections
  ALL_DAY_CARDS = 'all_day_cards',
  ITINERARY_MAP = 'itinerary_map',
  ITINERARY_HERO = 'itinerary_hero',
  ITINERARY_SUMMARY = 'itinerary_summary',
  ITINERARY_OVERVIEW = 'itinerary_overview',
  ITINERARY_CHECKLIST = 'itinerary_checklist',
  // Yacht sections
  YACHT_SUMMARY_CARD = 'yacht_summary_card',
  VESSEL_SHOWCASE = 'vessel_showcase',
  // Vessel sections
  VESSEL_HERO = 'vessel_hero',
  VESSEL_OVERVIEW = 'vessel_overview',
  VESSEL_CREW = 'vessel_crew',
  VESSEL_SPECIFICATIONS = 'vessel_specifications',
  VESSEL_TOYS_AMENITIES = 'vessel_toys_amenities',
  VESSEL_PRICING = 'vessel_pricing',
}

// fields needed for a section.
export type SectionDefinition = {
  readonly label: string
  readonly type: SectionType
  readonly targetType: SlideType[]
  readonly icon: Component
  readonly iconVariant: 'solid' | 'outline'
  readonly optionsPanel: boolean
  readonly optionsPanelTitle?: string
  readonly enabled?: boolean // unspecified|default = true
  readonly autoRemove?: boolean // unspecified|default = false
  readonly openOptionsPanelOnAdd?: boolean // unspecified|default = true
  readonly disableSaveLayout?: boolean // unspecified|default = false
  hydrationType?: HydrationType
}
