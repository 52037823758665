<script lang="ts" setup>
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE } from '@/utils/growthbook/constants'

import { AdditionalNotesEditorData, AdditionalNotesLayout } from './types'

type AdditionalNotesProps = {
  /**
   * The section id
   */
  id: string
  /**
   * The slide uri
   */
  uri: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The section's data
   */
  data: AdditionalNotesEditorData
  /**
   * The section's layout
   */
  layout: AdditionalNotesLayout
  /**
   * The lifecycle hook - will be required when 3 image layout is done

   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<AdditionalNotesProps>()
//const stowageService: StowageService = useStowageService() - will be required when 3 image layout is done
//const copyingAssets: Ref<boolean> = ref(false)

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<AdditionalNotesEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

props.lifecycle({
  onHydrated: () => {
    if (!props.layout.type) {
      updateLayout('two-column-text-only')
    }
  },
} as unknown as EditableLifecycleHooks)
/** will be required when 3 image layout is done
props.lifecycle({
  onHydrated: () => {
    copyingAssets.value = true
    // the asset copying is slow, sometimes it takes more than a minute to complete
    // to provide a faster experience, first load the assets for the first day and set
    // copying to false so the user can start see something
    stowageService.copyFiles(props.uri, props.proposalUri, props.data.images).then(() => (copyingAssets.value = false))
  },
} as unknown as EditableLifecycleHooks)

const uploadState: Ref<string | null> = ref(null)
const draggedImage: Ref<string | null> = ref(null)
const dragoverElement: Ref<string | null> = ref(null)

const drag = (event: DragEvent): void => {
  if (draggedImage.value !== null) return
  const target = event.target as HTMLElement
  draggedImage.value = target.id
}

const dragover = (event: DragEvent): void => {
  const target = event.target as HTMLElement
  // dropzoneLabel is the incorrect target, so ignore it
  if (dragoverElement.value === target.id || target.id.includes('dropzoneLabel')) {
    return
  }

  if (target.tagName === 'svg') {
    dragoverElement.value = target.parentElement!.id
  } else {
    dragoverElement.value = target.id
  }
}

const drop = (id?: string): void => {
  removeDragCursor()
  if (id) {
    const element = document.getElementById(id) as HTMLImageElement
    const data: AdditionalNotesEditorData = ObjectUtil.deepCopy(props.data)
    data.images = [element.dataset.imagePath!, ...data.images]
    emit('update:value', { sectionId: props.id, data: data })
    draggedImage.value = null
    dragoverElement.value = null
  }
}
*/

const updateLayout = (layoutType: string) => {
  emit('update:layout', { sectionId: props.id, layout: { type: layoutType, options: { ...props.layout.options } } })
}

const update = (data: AdditionalNotesEditorData): void => {
  emit('update:value', { sectionId: props.id, data })
}
</script>
<template>
  <div>
    <!--Right aligned-->
    <div v-if="props.layout.type === 'single-column-right-aligned'" class="flex text-right">
      <MultiLineTextEditor
        class="gap-10 font-light leading-normal"
        placeholder="Enter an additional notes"
        :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:w-1/2' : 'sm:w-1/2'"
        :value="props.data.textNote"
        @update:value="update({ ...props.data, textNote: $event })"
        data-test="text-note-right"
      />
    </div>

    <!--Left aligned-->
    <MultiLineTextEditor
      v-else-if="props.layout.type === 'single-column-left-aligned'"
      class="gap-10 font-light leading-normal"
      placeholder="Enter an additional notes"
      :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:w-1/2' : 'sm:w-1/2'"
      :value="props.data.textNote"
      @update:value="update({ ...props.data, textNote: $event })"
      data-test="text-note-left"
    />

    <!--Center aligned-->
    <div v-else-if="props.layout.type === 'single-column-center-aligned'" class="flex text-center">
      <MultiLineTextEditor
        class="gap-10 font-light leading-normal w-full"
        placeholder="Enter an additional notes"
        :value="props.data.textNote"
        @update:value="update({ ...props.data, textNote: $event })"
        data-test="text-note-center"
      />
    </div>

    <!--Two columns aligned-->
    <div
      v-else
      :class="$growthbook.isOn(WHEELHOUSE_20230801_WYSIWYG_PROPOSAL_IN_MOBILE_MODE) ? '@sm:columns-2' : 'sm:columns-2'"
    >
      <MultiLineTextEditor
        class="gap-10 font-light leading-normal"
        placeholder="Enter an additional notes"
        :value="props.data.textNote"
        @update:value="update({ ...props.data, textNote: $event })"
        data-test="text-note-two-column"
      />
    </div>
  </div>
</template>
