import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12.3516 18.8484C12.1266 18.6234 12.0002 18.3182 12.0002 18C12.0002 17.6818 12.1266 17.3767 12.3516 17.1516L17.5032 12L12.3516 6.84842C12.237 6.73773 12.1455 6.60531 12.0827 6.45891C12.0198 6.3125 11.9867 6.15504 11.9853 5.99571C11.9839 5.83637 12.0143 5.67835 12.0746 5.53088C12.1349 5.3834 12.224 5.24942 12.3367 5.13675C12.4494 5.02408 12.5834 4.93497 12.7308 4.87464C12.8783 4.8143 13.0363 4.78394 13.1957 4.78532C13.355 4.78671 13.5125 4.81981 13.6589 4.8827C13.8053 4.94559 13.9377 5.03701 14.0484 5.15162L20.0484 11.1516C20.2733 11.3767 20.3997 11.6818 20.3997 12C20.3997 12.3182 20.2733 12.6234 20.0484 12.8484L14.0484 18.8484C13.8233 19.0734 13.5182 19.1998 13.2 19.1998C12.8818 19.1998 12.5766 19.0734 12.3516 18.8484Z",
      "clip-rule": "evenodd"
    }),
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M5.15158 18.8484C4.92661 18.6234 4.80023 18.3182 4.80023 18C4.80023 17.6818 4.92661 17.3766 5.15158 17.1516L10.3032 12L5.15158 6.84841C4.93299 6.62209 4.81203 6.31897 4.81477 6.00433C4.8175 5.6897 4.9437 5.38872 5.16619 5.16623C5.38868 4.94374 5.68966 4.81754 6.00429 4.8148C6.31893 4.81207 6.62205 4.93302 6.84838 5.15161L12.8484 11.1516C13.0733 11.3766 13.1997 11.6818 13.1997 12C13.1997 12.3182 13.0733 12.6234 12.8484 12.8484L6.84838 18.8484C6.62334 19.0734 6.31817 19.1998 5.99998 19.1998C5.68178 19.1998 5.37661 19.0734 5.15158 18.8484V18.8484Z",
      "clip-rule": "evenodd"
    })
  ]))
}