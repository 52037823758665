<script setup lang="ts">
import { Ref, onBeforeUpdate, onMounted, ref } from 'vue'

import { JsonProposal, Proposal } from '@ankor-io/common/proposal/Proposal'

import ProposalViewer from '@/components/ProposalViewer.vue'
import Spinner from '@/components/Spinner.vue'
import { ProposalDeserializer } from '@/services/proposal/deserializer/ProposalDeserializer'
import { useStowageService } from '@/services/stowage/StowageService'
import { WHEELHOUSE_20250103_TRIPS_V2_APP } from '@/utils/growthbook/constants'

export type ProposalPreviewProps = {
  uri: string
}

const props = defineProps<ProposalPreviewProps>()

const stowageService = useStowageService()

const uri: Ref<string> = ref('')
const isLoading: Ref<boolean> = ref(true)
const proposal: Ref<Proposal | null> = ref(null)

onMounted(() => {
  uri.value = props.uri
  isLoading.value = true
  stowageService.getURIDocument<JsonProposal>(uri.value).then((_jsonProposal: JsonProposal | null) => {
    proposal.value = _jsonProposal ? ProposalDeserializer.deserialize(_jsonProposal) : null
    isLoading.value = false
  })
})

onBeforeUpdate(() => {
  if (props.uri !== uri.value) {
    uri.value = props.uri
    isLoading.value = true
    stowageService.getURIDocument<JsonProposal>(props.uri).then((_jsonProposal: JsonProposal | null) => {
      proposal.value = _jsonProposal ? ProposalDeserializer.deserialize(_jsonProposal) : null
      isLoading.value = false
    })
  }
})
</script>
<template>
  <div
    v-if="isLoading"
    class="flex items-center justify-center h-full"
    :class="
      $growthbook.isOn(WHEELHOUSE_20250103_TRIPS_V2_APP)
        ? $route.name === 'trips-preview'
        : $route.name === 'Preview'
        ? 'pt-24 sm:pt-14'
        : ''
    "
  >
    <Spinner />
  </div>
  <div v-else>
    <ProposalViewer v-if="proposal" :proposal="proposal!"></ProposalViewer>
    <div v-else>Presentation not found</div>
  </div>
</template>
