<script setup lang="ts">
import Badge from '@/components/Badge.vue'
import HelpTip from '@/components/HelpTip.vue'
import SingleColumnLayoutImage from '@/sections/checklist/assets/SingleColumnLayout.png'
import TwoColumnLayoutImage from '@/sections/checklist/assets/TwoColumnLayout.png'
import { LayoutTemplate } from '@/sections/types'

type Props = {
  id: string
  layout: LayoutTemplate
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

const updateLayout = (layout: LayoutTemplate): void => {
  emit('update:layout', { sectionId: props.id, layout })
}
</script>

<template>
  <div class="flex flex-col gap-y-8 h-[calc(100vh-10rem)] overflow-y-auto">
    <div class="pb-3 border-b border-b-gray-200">
      <HelpTip tip="Select a style for the before you board section of this route." />
    </div>

    <div class="single-column-layout">
      <p class="text-sm font-semibold text-gray-900 dark:text-white">Single column</p>
      <p class="text-xs mb-4 text-gray-500 dark:text-gray-400">Single column “before you board” section</p>
      <div class="group relative h-45 w-full flex items-center bg-gray-200 border border-gray-200">
        <img class="w-full border border-gray-200" :src="SingleColumnLayoutImage" />
        <!-- On hover apply -->
        <template v-if="layout.type !== undefined && layout.type !== 'single_column_layout'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout({ type: 'single_column_layout' })"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </template>
        <Badge text="Added" :show="layout.type === 'single_column_layout' || !layout.type" />
      </div>
    </div>

    <!-- Ideal to convert this into a component -->
    <div class="two-column-layout">
      <p class="text-sm font-semibold text-gray-900 dark:text-white">Two column</p>
      <p class="text-xs mb-4 text-gray-500 dark:text-gray-400">Two column “before you board” section</p>
      <div class="group relative h-45 w-full flex items-center bg-gray-200 border border-gray-200">
        <img class="w-full border border-gray-200" :src="TwoColumnLayoutImage" />
        <!-- On hover apply -->
        <template v-if="layout.type !== 'two_column_layout'">
          <button
            class="z-20 absolute bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/2 group-hover:translate-y-1/2 border border-white bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="updateLayout({ type: 'two_column_layout' })"
          >
            Apply Style
          </button>
          <div
            class="z-10 absolute top-0 bg-black w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
          ></div>
        </template>
        <Badge text="Added" :show="layout.type === 'two_column_layout'" />
      </div>
    </div>
  </div>
</template>
