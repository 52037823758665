<script setup lang="ts">
import Color from '@tiptap/extension-color'
import Link from '@tiptap/extension-link'
import Placeholder from '@tiptap/extension-placeholder'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { BubbleMenu, Editor, EditorContent } from '@tiptap/vue-3'
import { useDebounceFn } from '@vueuse/core'
import { Ref, computed, onBeforeUnmount, onBeforeUpdate, onMounted, ref } from 'vue'

import { isValidURL } from '@ankor-io/common/url/isValidURL'
import {
  OutlineAlignCenter,
  OutlineAlignJustify,
  OutlineAlignLeft,
  OutlineAlignRight,
  OutlineBold,
  OutlineClearFormatting,
  OutlineItalic,
  OutlineLink,
  OutlineOrderedList,
  OutlineRedo,
  OutlineUnderline,
  OutlineUndo,
} from '@ankor-io/icons/outline'
import { SolidUnorderedList } from '@ankor-io/icons/solid'

import InputVue from '@/components/Input.vue'

export interface Props {
  value: string
  placeholder?: string
  isResizable?: boolean
  isEditable?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  value: '',
  isResizable: false,
  isEditable: true,
})

const emit = defineEmits<{
  (e: 'update:value', value: string): void
  (e: 'on:blur', value: string): void
}>()

const editor: Ref<Editor | undefined> = ref(undefined)
const href: Ref<string> = ref('')
const setLinkDropdown: Ref<HTMLElement | null> = ref(null)
const textAlignDropdown: Ref<HTMLElement | null> = ref(null)
const cachedValue: Ref<string | undefined> = ref(undefined)
const popperInstance = ref<any>(undefined)

onMounted(() => {
  editor.value = new Editor({
    content: props.value,
    extensions: [
      StarterKit,
      Color,
      TextStyle,
      Underline,
      Link.configure({
        openOnClick: false,
      }),
      TextAlign.configure({
        types: ['heading', 'paragraph'],
        alignments: ['left', 'center', 'right', 'justify'],
        defaultAlignment: 'left',
      }),
      Placeholder.configure({
        placeholder: props.placeholder, // Use a placeholder
      }),
    ],
    editorProps: {
      attributes: {
        class: props.isResizable ? 'resize-y overflow-y-auto ' : '',
      },
      transformPastedHTML(html) {
        return html.replace(/style="[^\"]*"/g, '')
      },
    },
    editable: props.isEditable,
    parseOptions: {
      preserveWhitespace: 'full',
    },
    onBlur: () => {
      if (editor.value) {
        const text = editor.value.isEmpty ? '' : editor.value.getHTML()
        emit('update:value', text)
        emit('on:blur', 'ignored')
      }
    },
  })

  cachedValue.value = editor.value?.getHTML()
})

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy()
  }
})

onBeforeUpdate(() => {
  if (editor.value && editor.value.getHTML() !== props.value) {
    // make sure the editor content is updated when the value is changing
    editor.value!.commands.setContent(props.value)
  }
})

const textStyle = computed(() => editor.value?.getAttributes('textStyle').color)

// Returns an array of strings for each RGB value
const rgbStrings = computed(
  () =>
    textStyle.value &&
    textStyle.value.indexOf('(') !== -1 &&
    textStyle.value.substring(textStyle.value.indexOf('(') + 1, textStyle.value.indexOf(')')).split(', '),
)

// Returns an array of numbers for each RGB value
const rgb = computed(() => rgbStrings.value && rgbStrings.value.map((string: string) => parseInt(string)))

// Returns the hexadecimal unit for a given value
const valueToHex = (value: number) => {
  const hex = value.toString(16) // 16 is the radix used to return a hexadecimal unit
  return hex.length === 1 ? '0' + hex : hex
}

// Converts each RGB value as a hexadecimal unit before concatenating to a string
const rgbToHex = computed(() => rgb.value && `#${rgb.value.map((value: number) => valueToHex(value)).join('')}`)

const openLinkDropdown = () => {
  const previousUrl = editor.value?.getAttributes('link').href
  href.value = previousUrl
  setLinkDropdown.value?.classList.toggle('hidden')
}

const setLink = () => {
  // cancelled
  if (href.value === null) {
    editor.value?.commands.blur()
    setLinkDropdown.value?.classList.add('hidden')
    return
  }

  // empty
  if (href.value === '' || href.value === undefined) {
    editor.value?.commands.unsetLink()
    editor.value?.commands.blur()
    setLinkDropdown.value?.classList.add('hidden')
    return
  }

  if (!isValidURL(href.value)) {
    href.value = 'http://' + href.value
  }

  // update link
  editor.value?.chain().focus().setLink({ href: href.value }).run()
  editor.value?.commands.blur()
  setLinkDropdown.value?.classList.add('hidden')
}

const textAlignIcon = computed(() => {
  if (editor.value?.isActive({ textAlign: 'justify' })) return OutlineAlignJustify
  if (editor.value?.isActive({ textAlign: 'center' })) return OutlineAlignCenter
  if (editor.value?.isActive({ textAlign: 'right' })) return OutlineAlignRight
  return OutlineAlignLeft
})

const setAlignment = (align: string): void => {
  editor.value?.chain().focus().setTextAlign(align).run()
  textAlignDropdown.value?.classList.toggle('hidden')
}

const handlePopperPosition = useDebounceFn(() => {
  const header = document.querySelector('.proposal-header')

  if (!popperInstance.value || !header) {
    return
  }

  const top = popperInstance.value.reference.getBoundingClientRect().top

  if (top < header.clientHeight + 120) {
    popperInstance.value.setProps({ placement: 'bottom' })
  } else {
    popperInstance.value.setProps({ placement: 'top' })
  }
}, 500)

const onShow = (instance: any) => {
  popperInstance.value = instance
  handlePopperPosition()

  window.addEventListener('wheel', handlePopperPosition)
}

const onHide = () => {
  window.removeEventListener('wheel', handlePopperPosition)
}
</script>
<template>
  <BubbleMenu
    v-if="editor"
    :editor="editor"
    :tippy-options="{ duration: 100, maxWidth: 'none', zIndex: 20, onShow, onHide }"
    @click="editor.commands.focus()"
  >
    <div
      class="z-40 flex items-center text-black dark:text-white gap-4 px-4 py-2.5 bg-white dark:bg-gray-700 rounded-sm shadow"
    >
      <!-- Text colour -->
      <button
        id="textColor"
        type="button"
        class="relative rounded-full h-3.5 w-3.5 bg-gray-500 dark:bg-gray-400"
        :style="{ background: rgbToHex || textStyle }"
      >
        <input
          type="color"
          title="color"
          class="absolute inset-0 h-3.5 w-3.5 font-medium rounded-lg hover:cursor-pointer opacity-0"
          :model="rgbToHex || textStyle"
          :value="rgbToHex || textStyle"
          @change="
            editor
              ?.chain()
              .focus()
              .setColor(($event.target as HTMLInputElement).value)
              .run()
          "
          @input="
            editor
              ?.chain()
              .focus()
              .setColor(($event.target as HTMLInputElement).value)
              .run()
          "
        />
      </button>
      <!-- Text bold -->
      <button
        class="option group flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
        @click="editor?.chain().focus().toggleBold().run()"
      >
        <OutlineBold
          class="w-full group-hover:fill-primary-600"
          :class="editor.isActive('bold') ? 'fill-primary-600' : 'fill-gray-900 dark:fill-white'"
        />
      </button>
      <!-- Text italic -->
      <button
        class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300 italic"
        @click="editor?.chain().focus().toggleItalic().run()"
      >
        <OutlineItalic
          class="w-full group-hover:fill-primary-600"
          :class="editor.isActive('italic') ? 'fill-primary-600' : 'fill-gray-900  dark:fill-white'"
        />
      </button>
      <!-- Text underline -->
      <button
        class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300 italic"
        @click="editor?.chain().focus().toggleUnderline().run()"
      >
        <OutlineUnderline
          class="w-full group-hover:fill-primary-600 group-hover:text-primary-600"
          :class="editor.isActive('underline') ? 'text-primary-600 fill-primary-600' : 'fill-gray-900  dark:fill-white'"
        />
      </button>
      <!-- Hyperlink -->
      <div class="relative">
        <button
          class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
          @click="openLinkDropdown"
        >
          <OutlineLink
            class="w-full group-hover:text-primary-600"
            :class="editor.isActive('link') ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
          />
        </button>
        <!-- Set link dropdown -->
        <div ref="setLinkDropdown" class="z-10 hidden absolute top-9 w-max">
          <div class="flex items-center gap-2 bg-white rounded-lg shadow p-2.5">
            <div class="relative">
              <InputVue
                title="Insert link here"
                background-color="bg-white"
                :value="editor.getAttributes('link').href"
                @update:value="href = $event"
              />
            </div>
            <button
              class="text-white bg-primary-700 hover:text-white border border-primary-600 hover:bg-blue-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              @click="setLink"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <!-- Text alignment -->
      <div class="relative">
        <button
          class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
          @click="textAlignDropdown?.classList.toggle('hidden')"
        >
          <Component class="w-full" :is="textAlignIcon" />
        </button>
        <!-- Text align dropdown -->
        <div ref="textAlignDropdown" class="z-10 hidden absolute top-8 bg-white dark:bg-gray-700 divide-y shadow">
          <!-- Align left -->
          <button
            class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
            @click="setAlignment('left')"
          >
            <OutlineAlignLeft
              class="w-full group-hover:text-primary-600"
              :class="editor.isActive({ textAlign: 'left' }) ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
            />
          </button>
          <!-- Align justify -->
          <button
            class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
            @click="setAlignment('justify')"
          >
            <OutlineAlignJustify
              class="w-full group-hover:text-primary-600"
              :class="editor.isActive({ textAlign: 'justify' }) ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
            />
          </button>
          <!-- Align right -->
          <button
            class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
            @click="setAlignment('right')"
          >
            <OutlineAlignRight
              class="w-full group-hover:text-primary-600"
              :class="editor.isActive({ textAlign: 'right' }) ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
            />
          </button>
          <!-- Align center -->
          <button
            class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
            @click="setAlignment('center')"
          >
            <OutlineAlignCenter
              class="w-full group-hover:text-primary-600"
              :class="editor.isActive({ textAlign: 'center' }) ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
            />
          </button>
        </div>
      </div>
      <!-- Clear formatting -->
      <button
        class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
        @click="editor?.chain().focus().clearNodes().unsetAllMarks().run()"
      >
        <OutlineClearFormatting class="w-full" />
      </button>
      <!-- Bullet list -->
      <button
        class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
        @click="editor?.chain().focus().toggleBulletList().run()"
      >
        <SolidUnorderedList
          class="w-full group-hover:text-primary-600"
          :class="editor.isActive('bulletList') ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
        />
      </button>
      <!-- Ordered list -->
      <button
        class="flex group items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300"
        @click="editor?.chain().focus().toggleOrderedList().run()"
      >
        <OutlineOrderedList
          class="w-full group-hover:text-primary-600"
          :class="editor.isActive('orderedList') ? 'text-primary-600' : 'text-gray-900 dark:text-white'"
        />
      </button>
      <!-- Undo -->
      <button
        class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300 disabled:bg-white disabled:dark:bg-gray-800 disabled:text-gray-300 disabled:dark:text-gray-600"
        :disabled="!editor?.can().undo()"
        @click="editor?.commands.undo()"
      >
        <OutlineUndo class="w-full" />
      </button>
      <!-- Redo -->
      <button
        class="flex items-center justify-center text-center font-bold text-xl font-['Inter'] w-6 h-6 rounded-sm p-0.5 enabled:hover:text-primary-600 enabled:hover:bg-primary-300 enabled:active:ring-2 enabled:active:ring-primary-300 disabled:bg-white disabled:dark:bg-gray-800 disabled:text-gray-300 disabled:dark:text-gray-600"
        :disabled="!editor?.can().redo()"
        @click="editor?.commands.redo()"
      >
        <OutlineRedo class="w-full" />
      </button>
      <!-- Reset -->
      <button
        class="flex items-center justify-center text-center text-sm text-primary-600 dark:text-primary-500 font-['Inter'] rounded-sm px-2.5 py-0.5 hover:bg-primary-300 active:ring-2 active:ring-primary-300 disabled:bg-white disabled:dark:bg-gray-800 disabled:text-gray-300 disabled:dark:text-gray-600"
        :disabled="cachedValue === editor?.getHTML()"
        @click="cachedValue && editor?.commands.setContent(cachedValue)"
      >
        Reset
      </button>
    </div>
  </BubbleMenu>
  <EditorContent :editor="editor" data-test="editor-content" />
</template>
<style lang="scss">
// override the default behavior of wrapping text in the tiptap editor
.whitespace-nowrap {
  .ProseMirror p {
    white-space: nowrap;
  }
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  @apply text-gray-400;
  content: attr(data-placeholder);
  float: left;
  pointer-events: none;
  height: 0;
  font-weight: 400;
}
</style>
