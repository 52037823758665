<script lang="ts" setup>
import { Ref, onBeforeUpdate, ref } from 'vue'

import { ChangeEvent, State } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { EditableSection } from '@ankor-io/common/proposal/Section'

export interface EditorProps {
  section: EditableSection<any>
}

const props = defineProps<EditorProps>()

const stateRef: Ref<State> = ref(State.READ)
const sectionRef = ref(props.section)

const updateState = (newState: State): void => {
  stateRef.value = newState
}

const emit = defineEmits<{
  (e: 'hydrated', value: ChangeEvent<any>): void
}>()

/**
 * Make sure that when a section is added and the data hydrated, then
 * we let diff sync know with an update
 */
sectionRef.value.setLifecycleHooks({
  onHydrated: () => {
    emit('hydrated', { sectionId: sectionRef.value.id, data: sectionRef.value.data })
  },
} as unknown as EditableLifecycleHooks)

onBeforeUpdate(() => {
  sectionRef.value = ref(props.section).value
})
</script>
<template>
  <div>
    <slot :section="sectionRef" :state="stateRef" :update-state="updateState"></slot>
  </div>
</template>
