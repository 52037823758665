<script lang="ts" setup>
import { ChevronRightIcon } from '@heroicons/vue/20/solid'
import { initPopovers } from 'flowbite'
import ls from 'localstorage-slim'
import { Ref, Teleport, computed, defineComponent, onMounted, ref } from 'vue'
import draggable from 'vuedraggable'

import MegaModal from '@ankor-io/blocks/components/modals/MegaModal.vue'
import SimpleAlertModal from '@ankor-io/blocks/components/modals/SimpleAlertModal.vue'
import { modalHelper } from '@ankor-io/blocks/components/modals/modalHelper'
import ListTable from '@ankor-io/blocks/layouts/ListTable.vue'
import ListTableAccordion from '@ankor-io/blocks/layouts/ListTableAccordion.vue'
import { ItineraryIndexItem } from '@ankor-io/common/index/ItineraryIndexItem'
import { EditableProposal, JsonProposal, ProposalItem, ProposalItemType } from '@ankor-io/common/proposal/Proposal'
import { SlideType } from '@ankor-io/common/proposal/SlideType'
import { SolidCircleX, SolidDrag, SolidPlus, SolidSearch, SolidX } from '@ankor-io/icons/solid'

import SearchNoResultsMoon from '@/assets/search-no-results-moon.svg'
import VisibleObserver from '@/components/VisibleObserver.vue'
import { durationToDays, epochAsLabel } from '@/utils/date'
import { PREVIEW_ROUTE, REMOVE_ROUTE, SELECT_ROUTE } from '@/utils/mixPanel/constants'

import RoutePreview from '../library/RoutePreview.vue'
import Tags from '../library/Tags.vue'

defineComponent(draggable)

const props = defineProps<{
  uri: string
  presentation: EditableProposal
  isEditSaved: boolean
}>()

const emit = defineEmits<{
  (e: 'update:presentation', value: JsonProposal): void
}>()

// Modal helpers
const previewModalHelper = modalHelper()
const deleteModalHelper = modalHelper()

// the data in the table -- the dirty one has "null" values for tombstoned items.
let dirtyRiver: Ref<any[]> = ref([])
const river = computed(() => dirtyRiver.value.filter((item) => !!item))

// We don't want to mutate the presentation prop
const presentationComputed = computed(() => {
  return props.presentation
})
const isDragging: Ref<boolean> = ref(false)
const draggableRoute: Ref<{ uri: string; index: number } | null> = ref(null)

onMounted(() => {
  initPopovers()
})

// During the "transform items" step of the Aloglia search API,
// accumulate the results in the river array.
const accumulateSearchResults = (items: any[], { results }: any): object => {
  //  ** IMPORTANT **
  //  There is no guarantee that this function will be called only once
  //    for each call to refineNext() or via an Algolia HTTP call. See:
  //   - https://github.com/algolia/vue-instantsearch/issues/707#issuecomment-1361526922
  //

  // reset river to empty array if it's the first page
  if (results.page === 0) {
    dirtyRiver.value = []
  }

  items.forEach((hit, i) => {
    // allow only itineraries into the dirty river
    if (hit.type !== ProposalItemType.ITINERARY) {
      return
    }

    let offset = results.page * results.hitsPerPage + i

    if (ls.get<string>(`tombstone::${hit.uri}`) !== null && ls.get<string>(`tombstone::${hit.uri}`) !== undefined) {
      dirtyRiver.value[offset] = null
      return
    }

    // set the hit to the expected row position aligning to the position of the hit.
    dirtyRiver.value[offset] = {
      ...hit,
      uri: hit.objectID,
      name: hit.line_1,
      numDays: durationToDays(hit.line_5),
      embarkation: hit.line_3,
      disembarkation: hit.line_4,
      tags: hit.tags,
      dateEdited: epochAsLabel(hit.lastModified),
    }
  })

  // return the clean river (used by algolia lib)
  return river
}

/**
 * Function to select an itinerary from the search results
 * @param item The ItineraryIndexItem
 */
const selectRoute = (item: ItineraryIndexItem) => {
  const proposalItem: ProposalItem = {
    type: ProposalItemType.ITINERARY,
    uri: item.uri,
    item,
  } as ProposalItem

  presentationComputed.value.addItem(proposalItem)
  emit('update:presentation', presentationComputed.value.toJson())
}

/**
 * Remove a route from the presentation
 * @param index The index of the route to remove
 */
const removeRoute = (index: number) => {
  // hide the modal
  deleteModalHelper.hide()

  const routes = presentationComputed.value.itineraries.splice(index, 1)
  const route = routes[0]
  if (route) {
    presentationComputed.value.removeItem(route)
  }

  emit('update:presentation', presentationComputed.value.toJson())
}

/**
 * Reorders the selected route slides
 */
const reorder = () => {
  const jsonPresentation = presentationComputed.value.toJson()
  // Early cancel of reordering when the order did not actually change - prevent diffsync from triggering onChanges
  if (
    draggableRoute.value !== null &&
    draggableRoute.value.uri === jsonPresentation.itineraries[draggableRoute.value.index!]?.uri
  ) {
    isDragging.value = false
    draggableRoute.value = null
    return
  }

  const routeSlides = jsonPresentation.document.slides.filter((slide) => slide.type === SlideType.ITINERARY) || []
  const reorderedRouteSlides =
    routeSlides?.sort((a, b) => {
      const aIndex = jsonPresentation.itineraries.findIndex((itinerary) => itinerary.uri === a.uri)
      const bIndex = jsonPresentation.itineraries.findIndex((itinerary) => itinerary.uri === b.uri)
      return aIndex - bIndex
    }) || []

  isDragging.value = false
  draggableRoute.value = null
  const vesselSlides = jsonPresentation.document.slides?.filter((slide) => slide.type === SlideType.VESSEL) || []
  emit('update:presentation', {
    ...jsonPresentation,
    document: {
      ...jsonPresentation.document,
      slides: [...vesselSlides, ...reorderedRouteSlides],
    },
  })
}
</script>
<template>
  <div class="flex flex-col md:flex-row h-[calc(100vh-11rem)] gap-4">
    <div class="w-full md:w-3/4 h-1/2 md:h-full">
      <ais-configure filters="type:itinerary AND NOT tags:tombstone" hitsPerPage="60" />

      <!-- Delete modal -->
      <Teleport defer to="body">
        <!-- delete modal -->
        <SimpleAlertModal
          v-if="deleteModalHelper.isVisible()"
          :model="deleteModalHelper.model"
          @confirm="(model: any) => [deleteModalHelper.hide(), removeRoute(model.index)]"
          @dismiss="deleteModalHelper.hide()"
        >
          <template #title="{ modelValue }">
            <h3>{{ modelValue.title }}</h3>
          </template>
          <template #default="{ modelValue }">
            <div class="space-y-2.5 text-center text-sm text-gray-500 dark:text-gray-400">
              <p>You are about to remove</p>
              <p class="font-bold">{{ modelValue.name }}</p>
              <p>Are you sure you want to proceed?</p>
            </div>
          </template>
        </SimpleAlertModal>
      </Teleport>

      <!-- Preview modal -->
      <Teleport defer to="body">
        <MegaModal
          v-if="previewModalHelper.isVisible()"
          :model="previewModalHelper.model"
          @dismiss="previewModalHelper.hide()"
        >
          <template #header="{ modelValue }">
            <div class="flex flex-wrap flex-col md:flex-row gap-4 justify-between relative">
              <div>
                <h4 class="mr-4">{{ modelValue.name }}</h4>
                <div
                  v-if="modelValue.region || modelValue.numDays || modelValue.tags"
                  class="flex items-center gap-x-2 mt-2 text-sm text-gray-500 dark:text-gray-400"
                >
                  <p v-if="modelValue.region">{{ modelValue.region }}</p>
                  <p v-if="modelValue.numDays">
                    {{ modelValue.numDays }} {{ modelValue.numDays === 1 ? 'Day' : 'Days' }}
                  </p>
                  <Tags v-if="modelValue.tags" :tags="modelValue.tags" />
                </div>
              </div>

              <div class="flex items-center gap-x-2">
                <!-- disable the button and show selected when the item is in the presentation already -->
                <button
                  class="justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center text-white"
                  :disabled="!isEditSaved || presentationComputed.hasItem(modelValue.uri, ProposalItemType.ITINERARY)"
                  :class="
                    !isEditSaved || presentationComputed.hasItem(modelValue.uri, ProposalItemType.ITINERARY)
                      ? 'cursor-not-allowed bg-gray-200 dark:bg-gray-400'
                      : 'cursor-pointer bg-primary-600 dark:bg-primary-500 hover:bg-primary-500 dark:hover:bg-primary-800'
                  "
                  @click="
                    ;[
                      selectRoute(modelValue.item),
                      previewModalHelper.hide(),
                      $mixPanel.track(SELECT_ROUTE, { from: 'Preview' }),
                    ]
                  "
                >
                  <SolidPlus
                    v-if="!presentationComputed.hasItem(modelValue.uri, ProposalItemType.ITINERARY)"
                    class="size-5 shrink-0 mr-2 self-center text-white"
                  />
                  {{
                    presentationComputed.hasItem(modelValue.uri, ProposalItemType.ITINERARY)
                      ? 'Selected'
                      : 'Select Route'
                  }}
                </button>

                <button
                  type="button"
                  class="absolute md:relative top-1 md:top-auto right-0 transition-colors rounded-md text-gray-500 dark:text-gray-400 hover:text-gray-600 dark:hover:text-gray-300 focus:outline-none focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-500"
                  @click="previewModalHelper.hide()"
                >
                  <span class="sr-only">Close</span>
                  <SolidX class="size-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </template>

          <template #default="{ modelValue }">
            <div class="min-h-24 overflow-y-auto border-t border-gray-200 dark:border-gray-600">
              <RoutePreview id="route-preview" :uri="modelValue.uri" />
            </div>
          </template>
        </MegaModal>
      </Teleport>

      <div class="h-full overflow-auto px-2">
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <h3 class="sm:h-10" data-testId="page-title">Select Routes</h3>
            <p data-testId="page-subtitle">Select one or more routes that you wish to present.</p>
          </div>

          <div class="w-full flex flex-col md:flex-row justify-between items-baseline gap-2 pb-4 min-h-11">
            <div class="w-full flex flex-col sm:flex-row items-start sm:items-center gap-3">
              <div class="w-full sm:w-auto flex gap-x-2 items-center">
                <!-- nunununununununun -->
                <!--   Category Box    -->
                <!-- nunununununununun -->
                <ais-menu-select
                  attribute="tags"
                  :class-names="{
                    'ais-MenuSelect': 'tags-selector',
                  }"
                >
                  <template v-slot="{ refine }">
                    <select
                      class="w-48 h-11 z-20 border-1 rounded-lg focus:ring-0 bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500"
                      @change="refine(($event.currentTarget! as HTMLInputElement).value)"
                    >
                      <option class="" value="">All Routes</option>
                      <option class="" value="ankor">Ankor Routes</option>
                      <option class="" value="custom">My Routes</option>
                    </select>
                  </template>
                </ais-menu-select>

                <!-- nunununununununun -->
                <!--    Search Box     -->
                <!-- nunununununununun -->
                <ais-search-box
                  placeholder="Search for a route..."
                  submit-title="Submit"
                  reset-title="clear"
                  :autofocus="false"
                  :show-loading-indicator="false"
                  :class-names="{
                    'ais-SearchBox': 'h-11 w-full',
                    'ais-SearchBox-form':
                      'p-3 h-full flex items-center gap-x-2 relative rounded-lg border bg-white dark:bg-gray-700 border-gray-200 dark:border-gray-600 focus:border-gray-500 focus-within:border-gray-500 dark:focus:border-gray-500 dark:focus-within:border-gray-500',
                    'ais-SearchBox-input':
                      'bg-transparent order-2 h-full w-full z-20 outline-none border-none focus:ring-0 focus:border-0 focus:outline-0 placeholder-gray-500 text-black dark:text-white p-0',
                    'ais-SearchBox-reset': 'hidden',
                    'ais-SearchBox-loadingIcon': 'hidden',
                  }"
                >
                  <template v-slot:submit-icon>
                    <SolidSearch
                      class="order-first solid-search z-20 pointer-events-none w-5 h-5 stroke-2 fill-general-text-body"
                    />
                  </template>
                </ais-search-box>
              </div>
            </div>
          </div>
        </div>

        <!-- nunununununununun -->
        <!--  Search Results   -->
        <!-- nunununununununun -->
        <ais-infinite-hits :transform-items="accumulateSearchResults">
          <template v-slot="{ refineNext, isLastPage, results }">
            <div id="searchResults-top"></div>
            <!-- Getting Started and No Results-->
            <!-- add '?showGetStarted=1' to show that screen manually -->
            <template v-if="!results.nbHits || $route.query.showGetStarted">
              <slot name="no-results">
                <!-- No results. -->
                <div class="grid grid-cols-1 gap-4 justify-items-center pt-16">
                  <div class="h-[33vh]">
                    <img :src="SearchNoResultsMoon" alt="No results found" class="h-full" />
                  </div>
                  <h4>No results.</h4>
                  <div>Sorry! We could not find anything to match your query.</div>
                </div>
              </slot>
            </template>

            <!-- Show results -->
            <template v-else>
              <!-- table -->
              <ListTable
                rowKey="uri"
                class="hidden md:table w-full table-fixed"
                :columns="[
                  { label: 'Route details', field: 'name', type: 'string', classes: { td: 'truncate' } },
                  { label: 'Days', field: 'numDays', type: 'number', classes: { th: 'w-12' } },
                  {
                    label: '',
                    field: 'actions',
                    type: 'number',
                    classes: { th: 'min-w-48 w-48', td: 'min-w-48 w-48' },
                  },
                ]"
                :rows="river"
                :classes="{
                  thead: 'top-0',
                  tbody: 'border border-gray-200 dark:border-gray-600',
                  tr: 'cursor-pointer',
                }"
                @rowClick="
                  ({ row, field }) => {
                    if ('actions' !== field) {
                      $mixPanel.track(PREVIEW_ROUTE, { from: 'Presentation Editor' })
                      previewModalHelper.show({
                        uri: row.uri,
                        name: row.name,
                        tags: row.tags,
                        region: row.line_6,
                        numDays: row.numDays,
                        item: row,
                      })
                    }
                  }
                "
              >
                <!-- column: NAME/STOPS -->
                <template #name="{ row }">
                  <div class="flex flex-col gap-y-4 text-gray-700 dark:text-gray-300">
                    <p class="font-semibold truncate">{{ row.name }}</p>
                    <ul class="flex flex-wrap items-center gap-2">
                      <template v-for="(stop, stopIdx) in row.line_7" :key="`${stop}-${stopIdx}`">
                        <li v-if="stopIdx !== 0" aria-hidden="true">
                          <ChevronRightIcon class="size-4" />
                        </li>
                        <li
                          class="inline-flex items-center rounded-full px-3 py-2 text-xs font-medium ring-1 ring-inset ring-gray-200"
                        >
                          {{ stop }}
                        </li>
                      </template>
                    </ul>
                    <!-- </div> -->
                  </div>
                </template>
                <!-- column: ACTION MENU -->
                <template #actions="{ row }">
                  <button
                    class="inline-flex w-fit sm:w-auto justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg border text-sm px-5 py-2.5 text-center items-center"
                    :disabled="presentationComputed.hasItem(row.uri, ProposalItemType.ITINERARY)"
                    :class="
                      presentationComputed.hasItem(row.uri, ProposalItemType.ITINERARY)
                        ? 'pointer-events-none border-spacing-0 text-gray-200 dark:text-gray-600 border-gray-200 dark:border-gray-600 dark:bg-gray-800'
                        : 'border-primary-600 dark:border-primary-500 hover:border-primary-800 dark:hover:border-primary-800 text-primary-600 dark:text-primary-500 hover:text-white dark:hover:text-white focus:text-white dark:focus:text-white bg-transparent hover:bg-primary-800 focus:bg-primary-500 focus:ring-primary-300 dark:focus:ring-primary-300'
                    "
                    @click.stop=";[selectRoute(row), $mixPanel.track(SELECT_ROUTE, { from: 'List' })]"
                  >
                    <SolidPlus
                      v-if="!presentationComputed.hasItem(row.uri, ProposalItemType.ITINERARY)"
                      class="size-5 shrink-0 mr-2 self-center"
                    />
                    {{
                      presentationComputed.hasItem(row.uri, ProposalItemType.ITINERARY) ? 'Selected' : 'Select Route'
                    }}
                  </button>
                </template>
                <template #after>
                  <div class="hidden md:block">
                    <template v-if="!isLastPage">
                      <VisibleObserver @visible="refineNext" />
                    </template>
                    <template v-else="isLastPage">
                      <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                    </template>
                  </div>
                </template>
              </ListTable>
              <ListTableAccordion
                rowKey="uri"
                class="block md:hidden"
                :columns="[
                  {
                    label: 'Route details',
                    field: 'name',
                    type: 'string',
                    classes: { th: 'w-full', td: 'w-full truncate' },
                  },
                  { label: 'Days', field: 'numDays', type: 'number', classes: { th: 'w-16', td: 'w-16' } },
                  { label: '', field: 'actions', type: 'number', classes: { th: 'w-14', td: 'w-14' } },
                ]"
                :rows="river"
                :classes="{ thead: 'top-0', tr: 'cursor-pointer', dt: 'top-[2.6rem]' }"
                @rowClick="
                  ({ row, field }) => {
                    if ('actions' !== field) {
                      $mixPanel.track(PREVIEW_ROUTE, { from: 'Presentation Editor' })
                      previewModalHelper.show({ uri: row.uri, name: row.name, tags: row.tags, region: row.line_6 })
                    }
                  }
                "
              >
                <!-- column: NAME -->
                <template #name="{ row }">
                  <div class="flex flex-col gap-y-4 text-gray-700 dark:text-gray-300">
                    <p class="font-semibold truncate">{{ row.name }}</p>
                  </div>
                </template>
                <!-- column: ACTION MENU -->
                <template #actions="{ row }">
                  <button
                    v-if="isEditSaved && !presentationComputed.hasItem(row.uri, ProposalItemType.ITINERARY)"
                    class="w-full justify-center transition-colors focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-2 py-2 text-center inline-flex items-center text-white bg-primary-600 dark:bg-primary-500 hover:bg-primary-800 dark:hover:bg-primary-800 focus:ring-primary-300 dark:focus:ring-primary-300"
                    @click.stop=";[selectRoute(row), $mixPanel.track(SELECT_ROUTE, { from: 'List' })]"
                  >
                    <SolidPlus class="size-5 shrink-0 text-white" />
                  </button>
                </template>
                <!-- Accordion content -->
                <template #accordion-content="{ row }">
                  <ul class="flex flex-wrap items-center gap-x-1 col-span-2 gap-y-2">
                    <template v-for="(stop, stopIdx) in row.line_7" :key="`${stop}-${stopIdx}`">
                      <li v-if="stopIdx !== 0" aria-hidden="true">
                        <ChevronRightIcon class="size-4" />
                      </li>
                      <li
                        class="inline-flex items-center rounded-full px-3 py-2 text-xs font-medium ring-1 ring-inset ring-gray-200"
                      >
                        {{ stop }}
                      </li>
                    </template>
                  </ul>
                  <template
                    v-for="col of [
                      { label: 'Embarkation', field: 'embarkation', type: 'string' },
                      { label: 'Disembarkation', field: 'disembarkation', type: 'string' },
                      { label: 'Tags', field: 'tags', type: 'string' },
                      { label: 'Last Edited', field: 'dateEdited', type: 'string' },
                    ]"
                    :key="col.label"
                  >
                    <div v-if="row[col.field]" class="flex flex-col gap-y-1">
                      <span class="text-xs font-semibold text-gray-700 dark:text-gray-300">
                        {{ col.label }}
                      </span>
                      <Tags v-if="col.field === 'tags'" :tags="row.tags" />
                      <span v-else class="text-sm text-gray-500 dark:text-gray-400">
                        {{ row[col.field] }}
                      </span>
                    </div>
                  </template>
                </template>
                <!-- Observer -->
                <template #after>
                  <div class="block md:hidden">
                    <template v-if="!isLastPage">
                      <VisibleObserver @visible="refineNext" />
                    </template>
                    <template v-else="isLastPage">
                      <div class="mt-8 text-center text-sm">You have reached the end of the results!</div>
                    </template>
                  </div>
                </template>
              </ListTableAccordion>
            </template>
          </template>
        </ais-infinite-hits>
      </div>
    </div>
    <div class="w-full md:w-1/4 h-1/2 md:h-full">
      <div class="h-full rounded-lg bg-gray-100 dark:bg-gray-800">
        <p class="pt-4 px-4">Selected Routes</p>
        <draggable
          v-if="presentationComputed.itineraries?.length"
          tag="ul"
          item-key="index"
          draggable=".selected-route"
          class="h-[calc(100%_-_4rem)] overflow-auto"
          :disabled="!isEditSaved"
          :list="presentationComputed.itineraries"
          @start="isDragging = true"
          @dragend="reorder"
        >
          <template #item="{ element, index }">
            <li
              v-if="element.item?.line_7 || element.item?.line_1"
              class="group relative selected-route transition-[margin] p-4 rounded-md bg-gray-50 dark:bg-gray-900"
              :class="[
                isEditSaved ? (isDragging ? 'cursor-grabbing' : 'cursor-grab') : 'cursor-not-allowed',
                isEditSaved && draggableRoute?.index === index && !isDragging ? 'my-4 ml-8 mr-4' : 'm-4',
              ]"
              @mouseenter="draggableRoute = { uri: element.item.uri, index }"
              @mouseleave="draggableRoute = null"
            >
              <SolidDrag
                v-if="!isDragging"
                class="transition-opacity absolute -left-6 top-1/2 -translate-y-1/2 size-6 shrink-0 text-gray-500 dark:text-gray-400"
                :class="isEditSaved && draggableRoute?.index === index ? 'opacity-100' : 'opacity-0'"
              />
              <div v-if="element.item?.line_1" class="font-bold">{{ element.item.line_1 }}</div>
              <div v-if="element.item?.line_7?.length" class="text-sm">
                {{ element.item.line_7[0] }} -> {{ element.item.line_7[element.item.line_7.length - 1] }}
              </div>
              <div class="invisible absolute -top-2 -right-2">
                <button
                  class="w-5 h-5 hover:cursor-pointer"
                  :class="{
                    'group-hover:visible': isEditSaved,
                  }"
                  data-popover-placement="bottom"
                  :data-popover-target="`remove-route-${index}`"
                  @click="
                    ;[
                      deleteModalHelper.show({
                        title: 'Remove this route?',
                        index: index,
                        name: element.item.line_1,
                      }),
                      $mixPanel.track(REMOVE_ROUTE),
                    ]
                  "
                >
                  <SolidCircleX
                    class="w-full h-full fill-gray-400 dark:fill-gray-500 hover:fill-gray-500 dark:hover:fill-gray-400"
                  />
                </button>
                <div
                  data-popover
                  role="tooltip"
                  :id="`remove-route-${index}`"
                  class="absolute z-50 max-w-[20rem] invisible inline-block text-xs normal-case font-normal whitespace-normal transition-opacity duration-300 rounded-lg shadow-sm opacity-0 border border-gray-200 dark:border-gray-700 text-gray-900 dark:text-white bg-white dark:bg-gray-800"
                >
                  <div class="p-2">Remove</div>
                  <div data-popper-arrow></div>
                </div>
              </div>
            </li>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
