<script setup lang="ts">
import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'

import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'

import { HeadingSectionEditorData } from './HeadingSectionEditor'

type Props = {
  id: string
  data: HeadingSectionEditorData
  layout: LayoutTemplate
  /**
   * The lifecycle hook
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}
const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<HeadingSectionEditorData>): void
  (e: 'update:layout', value: { sectionId: string; layout: LayoutTemplate }): void
}>()

props.lifecycle({
  onHydrated: () => {
    if (!['heading_and_subheading', 'heading_only', 'subheading_only'].includes(props.layout?.type)) {
      updateLayout('heading_and_subheading')
    }
  },
} as unknown as EditableLifecycleHooks)

const updateLayout = (layoutType: string) => {
  emit('update:layout', { sectionId: props.id, layout: { type: layoutType, options: { ...props.layout.options } } })
}

const update = (data: HeadingSectionEditorData): void => {
  emit('update:value', { sectionId: props.id, data })
}
</script>

<template>
  <template v-if="['heading_only', 'subheading_only'].includes(props.layout?.type)">
    <h1 v-if="props.layout?.type === 'heading_only'" class="text-5xl mb-2">
      <SingleLineTextEditor
        :value="props.data.heading"
        placeholder="Enter a heading"
        @update:value="update({ ...props.data, heading: $event })"
      />
    </h1>
    <h3 v-if="props.layout?.type === 'subheading_only'" class="text-xl">
      <SingleLineTextEditor
        :value="props.data.subheading"
        placeholder="Enter a subheading"
        @update:value="update({ ...props.data, subheading: $event })"
      />
    </h3>
  </template>
  <template v-else>
    <h1 class="text-5xl mb-2">
      <SingleLineTextEditor
        :value="props.data.heading"
        placeholder="Enter a heading"
        @update:value="update({ ...props.data, heading: $event })"
      />
    </h1>
    <h3 class="text-xl">
      <SingleLineTextEditor
        :value="props.data.subheading"
        placeholder="Enter a subheading"
        @update:value="update({ ...props.data, subheading: $event })"
      />
    </h3>
  </template>
</template>
