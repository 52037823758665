<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { computed } from 'vue'

import { OutlineLogout } from '@ankor-io/icons/outline'

import LightDarkMode from './LightDarkMode.vue'

const props = defineProps<{
  name: string | null
}>()

const emits = defineEmits<{
  (e: 'logout'): void
}>()

const initial = computed(() => {
  return props.name?.charAt(0)
})
</script>
<template>
  <Menu as="div" class="relative inline-block">
    <MenuButton class="-m-1.5 flex items-center p-1.5" data-testid="account-menu">
      <span class="sr-only">Open user menu</span>
      <div
        v-if="props.name"
        class="relative inline-flex items-center justify-center size-8 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-900"
      >
        <span class="font-medium text-sm uppercase text-gray-500 dark:text-gray-400" data-test="initials">
          {{ initial }}
        </span>
      </div>
      <div
        v-else
        class="relative size-8 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-900"
        data-test="placeholder-icon"
      >
        <svg
          class="absolute size-10 -left-1 text-gray-500 dark:text-gray-400"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path>
        </svg>
      </div>
      <span class="hidden lg:flex lg:items-center">
        <!-- span v-if="props.name" class="ml-4 text-sm/6 font-semibold text-gray-900" aria-hidden="true" data-test="label">
          {{ props.name }}
        </span -->
        <ChevronDownIcon class="ml-2 size-5 text-gray-500 dark:text-gray-400" aria-hidden="true" />
      </span>
    </MenuButton>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        as="ul"
        class="absolute right-0 z-10 mt-2.5 min-w-32 w-max origin-top-right rounded-md py-2 shadow-lg ring-1 focus:outline-none bg-white dark:bg-gray-700 ring-gray-900/5"
      >
        <MenuItem v-slot="{ active }" class="w-full block px-3 py-1 text-sm/6 text-gray-900">
          <LightDarkMode :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }" />
        </MenuItem>
        <!-- links -->
        <slot name="contextualLinks"></slot>
        <MenuItem
          v-slot="{ active }"
          class="text-sm text-red-500 hover:text-red-600 dark:text-red-400 dark:hover:text-red-500"
        >
          <li
            class="group cursor-pointer flex items-center gap-x-2 px-3.5 py-1"
            :class="{ 'outline-none bg-gray-50 dark:bg-gray-600': active }"
            @click.stop="$emit('logout')"
          >
            <OutlineLogout class="size-4 shrink-0 group-hover:dark:text-red-500" />
            Logout
          </li>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>
