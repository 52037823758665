<script setup lang="ts">
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { SolidVideoCamera } from '@ankor-io/icons/solid'

import { EmbedSectionData, EmbedSectionLayout } from '@/sections/embed/types'

type Props = {
  id: string
  data: EmbedSectionData
  layout: EmbedSectionLayout
  /**
   * The lifecycle hook - will be required when 3 image layout is done
   */
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:layout', value: { sectionId: string; layout: EmbedSectionLayout }): void
}>()

props.lifecycle({
  onHydrated: () => {
    if (!props.layout.type) {
      emit('update:layout', {
        sectionId: props.id,
        layout: { type: props.layout.type, options: { ...props.layout.options, size: 'default' } },
      })
    }
  },
} as unknown as EditableLifecycleHooks)
</script>
<template>
  <div
    class="min-h-48 mx-auto"
    :class="[
      { 'w-1/3': props.layout?.options?.size === 'small' },
      { 'w-2/3': props.layout?.options?.size === 'medium' },
    ]"
  >
    <div
      v-if="props.data.content"
      v-html="props.data.content"
      class="flex justify-center"
      :class="[{ 'embed-container': props.layout?.options?.size !== 'default' }]"
    ></div>
    <div v-else class="flex flex-col justify-center items-center min-h-48 text-lg select-none">
      <div class="flex justify-center items-center bg-gray-400 bg-opacity-25 rounded-lg w-full h-40 mb-4">
        <SolidVideoCamera class="w-8 h-8 mr-2 opacity-75" />
      </div>
      Edit this section to add a video
    </div>
  </div>
</template>
<style lang="scss">
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  max-width: 100%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
