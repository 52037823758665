import { DiffSyncClient, DiffsyncClientFactory } from '@ankor-io/common/diffsync'
import { Callable } from '@ankor-io/common/lang/functional.types'
import { App, InjectionKey } from 'vue'

/**
 * The diff sync plugin
 *
 * @returns the plugin object
 */
export const useDiffSyncPlugin = () => {
  return {
    install: (app: App) => {
      // provide the plugin wrapping the factory
      app.provide(DiffSyncClientKey, {
        init: (options: DiffSyncInitOptions): DiffSyncClient<Object> =>
          DiffsyncClientFactory.create({
            url: `wss://${window.location.host}/stowage/sync/${encodeURIComponent(options.uri)}`,
            token: options.token,
            getToken: options.getToken,
            documentContent: options.documentContent,
            updateRunnable: options.updateRunnable,
            destructor: options.destructor,
          }),
      })
    },
  }
}

/**
 * The type definition for diff sync plugin
 */
export type DiffSyncPlugin = {
  /**
   * Allows to initialize a diff sync client. Wrapper around the {@link DiffSyncClientFactory}
   */
  init(options: DiffSyncInitOptions): DiffSyncClient<Object>
}

/**
 * The diff sync client initialization options
 */
export type DiffSyncInitOptions = {
  /**
   * The authenticated user token
   */
  token: string
  /**
   * The initial document content
   */
  documentContent: Object
  /**
   * A function to run when there are changes from the backend
   */
  updateRunnable?: Callable
  /**
   * The uri to start the synchronization on
   */
  uri: string
  /**
   * Any behaviour to run when the diff sync instance is cleared/destroy
   */
  destructor?: Callable
  /**
   * A function to get a fresh new token
   */
  getToken?: () => Promise<string | undefined>
}

/**
 * The diff sync client key - typed to a {@link DiffSyncPlugin}
 */
export const DiffSyncClientKey = Symbol() as InjectionKey<DiffSyncPlugin>
