import LatLon from 'geodesy/latlon-spherical'

// See: https://www.movable-type.co.uk/scripts/latlong.html
// https://github.com/chrisveness/geodesy

export const metresToNauticalMiles = () => 1 / 1852
export const nauticalMilesToMeters = () => 1852

export type Distance = {
  from: LatLon
  to: LatLon
  method: string
  value: number
  unit: string
}

/**
 * Calculate the straight line distance (in nautical miles)
 * between 2 lat/long points using the Haversine formula.
 *
 * @param lat1 starting point latitude
 * @param lon1 starting point longitude
 * @param lat2 end point latitiude
 * @param lon2 end point longitude
 * @returns the distance in nautical miles
 */
export const distanceBetween = (lat1: number, lon1: number, lat2: number, lon2: number): Distance => {
  // underlying library does argument validation.
  const p1 = new LatLon(lat1, lon1)
  const p2 = new LatLon(lat2, lon2)

  // Using the Haversine formula, calculate the distance in meters
  const inMeters = p1.distanceTo(p2)

  return {
    from: p1,
    to: p2,
    method: 'haversine',
    value: inMeters * metresToNauticalMiles(),
    unit: 'nm',
  }
}
