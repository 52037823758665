import gql from 'graphql-tag'
import { Component } from 'vue'

import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'
import { LineItem, Vessel } from '@ankor-io/common/vessel/types'

import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

import ConfigurationComponent from './VesselPricingConfiguration.vue'
import VesselPricingSectionEditorVue from './VesselPricingSectionEditor.vue'
import { VesselPricingLayoutTemplate, VesselPricingSectionData } from './types/types'

const QUERY = gql`
  query Vessel($URI: String!) {
    vessel(uri: $URI) {
      variants {
        pricing {
          currency
          note
          unit
          inputAmountTaxed
          lineItems {
            item
            conditions
            quantity
            unitPrice
            taxRate {
              label
              value
            }
            discount {
              type
              value
            }
            amount
          }
          subTotal
          totalTax
          total
        }
      }
    }
  }
`

export class VesselPricingSectionEditor extends ProposalAbstractEditableSection<
  VesselPricingSectionData,
  EditableProposal
> {
  async fetchData(): Promise<VesselPricingSectionData> {
    const defaultLayoutOptions: VesselPricingLayoutTemplate = {
      type: 'default',
      options: {
        enabledPricingColumns: ['item', 'conditions', 'quantity', 'unitPrice', 'discount', 'taxRate', 'amount'],
        pricingHeaderBackground: 'rgb(243, 244, 246)',
        pricingBodyBackground: 'rgb(255,255,255)',
        pricingLabel: 'Pricing',
      },
    }

    this.setLayout(defaultLayoutOptions)

    const client = await this.client()
    const { data, errors } = await client.query({
      query: QUERY,
      variables: { URI: this.getSlideUri() },
      errorPolicy: 'all',
    })

    console.debug('VesselPricingSectionEditor errors: ', errors)

    const vessel: Vessel = data.vessel
    const variant = vessel.variants[0]
    const pricing: any = variant?.pricing ?? {}
    const pricingLineItems = (pricing.lineItems ?? []).map((lineItem: LineItem) => ({
      ...lineItem,
      displayQuantityAsPercent: Number(lineItem.quantity) < 1,
    }))
    const vesselData: VesselPricingSectionData = {
      pricing: { ...pricing, lineItems: pricingLineItems },
    }

    return Promise.resolve(vesselData)
  }

  getType(): SectionType {
    return SectionType.VESSEL_PRICING
  }

  getComponent(): Component {
    return VesselPricingSectionEditorVue
  }

  getConfigurationComponent(): Component | null {
    return ConfigurationComponent
  }
}
