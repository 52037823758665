<script setup lang="ts">
/**
 * ###############################################
 * ################### Input #####################
 * id - Proposal ID
 * uri - URI of the vessel entity
 * data - {@VesselOverviewData} - Vessel overview data
 * layout - {@LayoutTemplate} - Layout template
 * ################### Output ####################
 * Renders vessel overview section (non-editable)
 * Heading
 * Vessel description
 * ###############################################
 */
import { Ref, onMounted, onUnmounted, onUpdated, ref } from 'vue'

import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import { LayoutTemplate } from '@/sections/types'
import { VesselOverviewData } from '@/sections/vessel/overview/types'

type Props = {
  id: string
  uri: string
  data: VesselOverviewData
  layout: LayoutTemplate
}
const props = defineProps<Props>()

const NUM_LINES_TO_CLAMP = 4
const descriptionRef: Ref<any> = ref(null)
const isClamped: Ref<boolean> = ref(true)
const showClampButton: Ref<boolean> = ref(true)

onMounted(() => {
  checkDescriptionClampable()
  window.addEventListener('resize', checkDescriptionClampable)
})

onUpdated(() => {
  checkDescriptionClampable()
})

onUnmounted(() => {
  window.removeEventListener('resize', checkDescriptionClampable)
})

const checkDescriptionClampable = () => {
  setTimeout(() => {
    if (!props.data.description) {
      showClampButton.value = false
      return
    }

    const height = descriptionRef.value?.scrollHeight!
    const lineHeight = Number(descriptionRef.value?.style.lineHeight.replace('px', ''))!
    const lines = height / lineHeight
    if (lines > NUM_LINES_TO_CLAMP) {
      showClampButton.value = true
    } else {
      showClampButton.value = false
    }
  }, 0)
}
</script>
<template>
  <div v-if="props.data && (props.data.heading || props.data.description)">
    <!-- Heading -->
    <h1 v-if="props.data.heading" class="overview-heading font-bold text-center text-3xl mb-4">
      <SingleLineTextEditor :value="props.data.heading" :is-editable="false" />
    </h1>
    <!-- Narrative -->
    <p
      v-if="props.data.description"
      class="mb-4"
      ref="descriptionRef"
      style="line-height: 24px"
      :class="isClamped ? 'line-clamp-4' : 'line-clamp-none'"
    >
      <MultiLineTextEditor class="gap-10 font-light" :value="props.data.description" :is-editable="false" />
    </p>
    <span v-if="showClampButton" class="cursor-pointer text-theme-primary" @click="isClamped = !isClamped">
      {{ isClamped ? 'Show more' : 'Show less' }}
    </span>
  </div>
</template>
<style>
.overview-heading .ProseMirror p {
  line-height: 46px; /* TailwindCSS would not override the text editors line height */
}
</style>
