import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M21.2484 11.1516C21.4734 11.3766 21.5998 11.6818 21.5998 12C21.5998 12.3182 21.4734 12.6234 21.2484 12.8484L12.8484 21.2484C12.6234 21.4734 12.3182 21.5997 12 21.5997C11.6818 21.5997 11.3767 21.4734 11.1516 21.2484L2.75163 12.8484C2.64003 12.7371 2.55152 12.6049 2.49118 12.4593C2.43084 12.3137 2.39986 12.1576 2.40003 12V5.99999C2.40003 5.04521 2.77931 4.12954 3.45444 3.45441C4.12957 2.77928 5.04525 2.39999 6.00003 2.39999H12C12.3072 2.39999 12.6144 2.51759 12.8484 2.75159L21.2484 11.1516ZM6.00003 7.19999C6.31828 7.19999 6.62351 7.07357 6.84855 6.84852C7.0736 6.62348 7.20003 6.31825 7.20003 5.99999C7.20003 5.68173 7.0736 5.37651 6.84855 5.15147C6.62351 4.92642 6.31828 4.79999 6.00003 4.79999C5.68177 4.79999 5.37654 4.92642 5.1515 5.15147C4.92645 5.37651 4.80003 5.68173 4.80003 5.99999C4.80003 6.31825 4.92645 6.62348 5.1515 6.84852C5.37654 7.07357 5.68177 7.19999 6.00003 7.19999Z",
      "clip-rule": "evenodd"
    })
  ]))
}