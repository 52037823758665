<script setup lang="ts">
import { Ref, computed, inject, ref } from 'vue'

import AssetViewer from '@ankor-io/blocks/components/AssetViewer/AssetViewer.vue'
import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import PhoneInput from '@ankor-io/blocks/components/Input/Phone/PhoneInput.vue'
import { isEmailValid } from '@ankor-io/common/input-validation/validation'
import { Identity } from '@ankor-io/common/proposal/Proposal'
import { URIContext } from '@ankor-io/common/uri/Uri'
import { MediaUriBuilder } from '@ankor-io/common/uri/uri.builder'
import { OutlineDelete, OutlineXMark } from '@ankor-io/icons/outline'
import { SolidPlus, SolidUser } from '@ankor-io/icons/solid'

import InputVue from '@/components/Input.vue'
import Spinner from '@/components/Spinner.vue'
import DeleteConfirmation, { DeleteConfirmationDataProps } from '@/components/modal-content/DeleteConfirmation.vue'
import ProfileCompanyUploader from '@/components/modal-content/ProfileCompanyUploader.vue'
import { AuthenticationContext } from '@/iam/types'
import { _requestSignedUrl, uploadFile } from '@/services/assets/upload'

type DeleteConfirmationData = DeleteConfirmationDataProps & {
  field: 'image' | 'companyImage'
}

const props = defineProps<{
  userTemplateUri: string
  identity: Identity
  /**
   * @deprecated Was a requirement for trips V1
   */
  isSaving?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:identity', value: Identity): void
  (e: 'close:modal'): void
}>()

const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const profileAndCompanyForm = ref<HTMLFormElement | null>(null)

const isUploaderOpen: Ref<boolean> = ref(false)
const isDeleteConfirmationOpen: Ref<boolean> = ref(false)
const uploaderHeading: Ref<string | null> = ref(null)
const isUploading: Ref<boolean> = ref(false)
const deleteConfirmationData: Ref<DeleteConfirmationData | null> = ref(null)

const identityRef: Ref<Identity> = ref({ ...props.identity })

const emailValid: Ref<boolean> = ref(true)
const emailInput: Ref<string> = ref(identityRef.value.email)

const canSave = computed(() => JSON.stringify(props.identity) !== JSON.stringify(identityRef.value))

const updateEmail = (value: string) => {
  if (emailInput.value === value) {
    return
  }

  emailInput.value = value
  emailValid.value = true
  // Input validation
  if (value && !isEmailValid(value)) {
    emailValid.value = false
    return
  }

  updateValue('email', value)
}

const updateValue = (field: keyof Identity, value: string): void => {
  identityRef.value[field] = value
}

const updateIdentity = () => {
  if (!profileAndCompanyForm.value?.checkValidity() || !emailValid.value) {
    return
  }

  emit('update:identity', identityRef.value)
}

const openUploaderModal = (title: string) => {
  uploaderHeading.value = title
  isUploaderOpen.value = true
}

const openDeleteConfirmationModal = (field: 'image' | 'companyImage') => {
  deleteConfirmationData.value = {
    field,
    message: 'You are about to delete this image. Do you wish to proceed?',
    labelCancel: 'Cancel',
    labelConfirm: 'Confirm',
  }
  isDeleteConfirmationOpen.value = true
}

const closeUploaderModal = () => {
  isUploaderOpen.value = false
  uploaderHeading.value = null
}

const closeDeleteConfirmationModal = () => {
  isDeleteConfirmationOpen.value = false
  deleteConfirmationData.value = null
}

const closeModal = () => {
  closeUploaderModal()
  closeDeleteConfirmationModal()
}

const handleUploadFile = async (file: File | File[]) => {
  isUploading.value = true
  const token: string | undefined = await authenticationContext.getToken()
  const fileToUpload = Array.isArray(file) ? file[0] : file
  const { signedUrl, mediaUri }: { signedUrl: string; mediaUri: string } = await _requestSignedUrl(
    props.userTemplateUri,
    token!,
  )
  const uploaded: boolean = await uploadFile(signedUrl, fileToUpload)
  const fileReader = new FileReader()

  fileReader.readAsDataURL(fileToUpload)
  if (uploaded) {
    updateValue(uploaderHeading.value === 'profile' ? 'image' : 'companyImage', mediaUri)
  }

  closeUploaderModal()
  isUploading.value = false
}

const confirmDelete = (field: 'image' | 'companyImage') => {
  updateValue(field, '')
  closeDeleteConfirmationModal()
}

/**
 * Close modal
 */
const close = () => {
  emit('close:modal')
}

const profileMediaUri = computed(() => {
  if (!identityRef.value.image) {
    return ''
  }

  return identityRef.value.image.includes(`${URIContext.MEDIA}::`)
    ? identityRef.value.image
    : new MediaUriBuilder().build(props.userTemplateUri, identityRef.value.image)
})

const companyMediaUri = computed(() => {
  if (!identityRef.value.companyImage) {
    return ''
  }

  return identityRef.value.companyImage.includes(`${URIContext.MEDIA}::`)
    ? identityRef.value.companyImage
    : new MediaUriBuilder().build(props.userTemplateUri, identityRef.value.companyImage)
})
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center"
    @click.stop
  >
    <div
      class="w-full md:max-w-2xl max-h-screen overflow-auto relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md grid grid-row-3 divide-y dark:divide-gray-600 text-sm text-gray-900 dark:text-white"
    >
      <!-- close icon -->
      <div class="absolute right-8 top-8 flex justify-end">
        <OutlineXMark
          class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
          @click.stop="close"
        />
      </div>

      <!-- Modal Header -->
      <div class="p-6 text-lg font-semibold text-gray-900 dark:text-gray-200">Profile & Company</div>

      <!-- Modal Body -->
      <div class="p-6 text-gray-900 dark:text-white">
        <!-- Modal within a modal for uploading an image -->
        <div
          v-if="isUploaderOpen || isDeleteConfirmationOpen"
          id="additional-branding-modal"
          tabindex="1"
          aria-hidden="true"
          class="fixed top-0 left-0 right-0 z-50 w-full overflow-x-hidden overflow-y-auto inset-0 h-full backdrop-blur-[2px] p-2"
        >
          <div class="relative shrink-0 top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 w-fit">
            <!-- Modal content -->
            <div
              id="modal"
              class="relative bg-white border border-gray-200 dark:bg-gray-800 dark:border-gray-600 text-gray-900 dark:text-gray-50 rounded-lg shadow-md p-8"
            >
              <div class="absolute right-8 top-10 flex justify-end">
                <OutlineXMark
                  class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
                  @click="closeModal()"
                />
              </div>
              <ProfileCompanyUploader
                v-if="isUploaderOpen"
                :is-loading="isUploading"
                :heading="uploaderHeading!"
                @upload:file="handleUploadFile($event)"
              />
              <DeleteConfirmation
                v-else
                :message="deleteConfirmationData!.message"
                :label-cancel="deleteConfirmationData!.labelCancel"
                :label-confirm="deleteConfirmationData!.labelConfirm"
                @close:modal="closeDeleteConfirmationModal()"
                @confirm:modal="confirmDelete(deleteConfirmationData!.field)"
              />
            </div>
          </div>
        </div>

        <form ref="profileAndCompanyForm" class="flex flex-col gap-y-2" @submit.prevent>
          <!-- Company -->
          <p class="font-medium leading-none">Company</p>
          <div class="flex flex-col md:flex-row gap-4">
            <div class="flex flex-col sm:flex-row md:flex-col gap-2">
              <div class="flex flex-col gap-y-2">
                <div class="flex justify-center group relative w-60 h-36 rounded-lg overflow-hidden">
                  <div class="w-full">
                    <AssetViewerPlaceholder
                      v-if="companyMediaUri"
                      class="object-contain"
                      :url="`/media/${companyMediaUri}`"
                    />
                    <div v-else class="h-full w-full flex justify-center items-center rounded-lg bg-primary-50">
                      <p class="select-none text-6xl font-bold text-primary-400">Logo</p>
                    </div>
                  </div>

                  <div class="absolute top-0 w-full h-full transition-all duration-300 invisible group-hover:visible">
                    <div
                      class="z-10 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
                    ></div>
                  </div>

                  <!-- On hover add -->
                  <button
                    type="button"
                    id="company-uploader-edit-image-button"
                    class="z-20 w-32 absolute top-0 left-1/2 -translate-x-1/2 flex gap-x-1 justify-center items-center cursor-pointer border border-white focus:border-none bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100 focus:ring-2 focus:ring-primary-300"
                    :class="
                      identityRef.companyImage
                        ? 'group-hover:top-8'
                        : 'group-hover:top-1/2 group-hover:-translate-y-1/2'
                    "
                    @click="openUploaderModal('company')"
                  >
                    <SolidPlus class="w-5 h-5 fill-white" />
                    Upload &amp; edit
                  </button>

                  <!-- On hover delete -->
                  <button
                    v-if="identityRef.companyImage"
                    type="button"
                    id="company-delete-image-button"
                    class="z-20 w-32 absolute flex gap-x-2 justify-center items-center bottom-0 group-hover:bottom-8 left-1/2 -translate-x-1/2 cursor-pointer border border-red-600 bg-red-300 px-3 py-2 rounded-md transition-all duration-300 opacity-0 group-hover:opacity-100"
                    @click="openDeleteConfirmationModal('companyImage')"
                  >
                    <OutlineDelete class="w-4 h-4 stroke-red-600" />
                    <span class="text-red-600 font-medium text-xs">Delete image</span>
                  </button>
                </div>
                <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
              </div>
            </div>

            <!-- Company input fields -->
            <div class="w-full max-w-[28rem] flex flex-col gap-y-4">
              <InputVue
                title="Company name"
                input-size="small"
                background-color="bg-white"
                :value="identityRef.companyName"
                @update:value="updateValue('companyName', $event)"
              />
              <InputVue
                title="Office number"
                input-size="small"
                background-color="bg-white"
                :value="identityRef.companyPhone"
                @update:value="updateValue('companyPhone', $event)"
              />
              <!-- Street address -->
              <div class="relative h-full">
                <textarea
                  rows="3"
                  placeholder=" "
                  id="street-address-input"
                  class="peer h-full block px-2.5 py-2.5 w-full text-sm rounded-lg border-1 appearance-none focus:outline-none focus:ring-0 peer text-gray-900 dark:text-white bg-white dark:bg-gray-900 border-gray-300 dark:border-gray-600 focus:border-blue-600 dark:focus:border-blue-500"
                  :class="{
                    'bg-gray-50 dark:bg-gray-800 active:bg-transparent focus:bg-transparent':
                      !identityRef.companyAddress,
                  }"
                  :value="identityRef.companyAddress"
                  @blur="updateValue('companyAddress', ($event.target as HTMLInputElement).value)"
                ></textarea>
                <label
                  for="street-address-input"
                  class="absolute text-sm duration-300 rounded-t transform -translate-y-4 scale-75 top-2 z-10 origin-[0] ml-1 px-2 peer-focus:px-2 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-6 peer-placeholder-shown:top-8 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 text-gray-500 dark:text-gray-400 bg-white dark:bg-gray-900 peer-focus:text-blue-600 peer-focus:dark:text-blue-500"
                  :class="{
                    'bg-gray-50 peer-focus:bg-white dark:bg-gray-800 peer-focus:dark:bg-gray-700 active:bg-transparent focus:bg-transparent':
                      !identityRef.companyAddress,
                  }"
                >
                  Street address
                </label>
              </div>
            </div>
          </div>

          <hr class="my-3 border-gray-200 dark:border-gray-700" />

          <!-- Profile -->
          <p class="font-medium leading-none">Profile</p>
          <div class="flex flex-col md:flex-row gap-4">
            <div class="flex flex-col gap-y-2">
              <div class="flex justify-center group relative w-60 h-36 rounded-lg overflow-hidden">
                <AssetViewer v-if="profileMediaUri" class="object-contain" :url="`/media/${profileMediaUri}`" />
                <div v-else class="w-60 flex justify-center items-center rounded-lg bg-primary-50">
                  <SolidUser class="h-full fill-primary-400" />
                </div>

                <div class="absolute top-0 w-full h-full transition-all duration-300 invisible group-hover:visible">
                  <div
                    class="z-10 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
                  ></div>
                </div>

                <!-- On hover add -->
                <button
                  type="button"
                  id="profile-uploader-edit-image-button"
                  class="z-20 w-32 absolute top-0 left-1/2 -translate-x-1/2 flex gap-x-1 justify-center items-center cursor-pointer border border-white focus:border-none bg-white text-white font-medium text-xs px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100 focus:ring-2 focus:ring-primary-300"
                  :class="identityRef.image ? 'group-hover:top-8' : 'group-hover:top-1/2 group-hover:-translate-y-1/2'"
                  @click="openUploaderModal('profile')"
                >
                  <SolidPlus class="w-5 h-5 fill-white" />
                  Upload &amp; edit
                </button>

                <!-- On hover delete -->
                <button
                  v-if="identityRef.image"
                  type="button"
                  id="profile-delete-image-button"
                  class="z-20 w-32 absolute flex gap-x-2 justify-center items-center bottom-0 group-hover:bottom-8 left-1/2 -translate-x-1/2 cursor-pointer border border-red-600 bg-red-300 px-3 py-2 rounded-md transition-all duration-300 opacity-0 group-hover:opacity-100"
                  @click="openDeleteConfirmationModal('image')"
                >
                  <OutlineDelete class="w-4 h-4 stroke-red-600" />
                  <span class="text-red-600 font-medium text-xs">Delete image</span>
                </button>
              </div>
              <p class="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
            </div>

            <!-- Profile input fields -->
            <div class="max-w-[28rem] grid grid-cols-2 gap-4">
              <InputVue
                title="First name"
                input-size="small"
                background-color="bg-white"
                :value="identityRef.givenName"
                @update:value="updateValue('givenName', $event)"
              />
              <InputVue
                title="Last name"
                input-size="small"
                background-color="bg-white"
                :value="identityRef.surname"
                @update:value="updateValue('surname', $event)"
              />
              <InputVue
                class="col-span-2"
                title="Role"
                input-size="small"
                background-color="bg-white"
                :value="identityRef.role"
                @update:value="updateValue('role', $event)"
              />
              <PhoneInput
                class="col-span-2"
                :value="identityRef.phone"
                @update:phone:number="updateValue('phone', $event)"
              />
              <!-- Email -->
              <InputVue
                class="col-span-2"
                type="email"
                title="Email"
                input-size="small"
                background-color="bg-white"
                :value="emailInput"
                :is-valid="emailValid"
                @update:value="updateEmail($event)"
              />
              <InputVue
                class="col-span-2"
                title="Website"
                input-size="small"
                background-color="bg-white"
                :value="identityRef.website"
                @update:value="updateValue('website', $event)"
              />
            </div>
          </div>
        </form>
      </div>

      <!-- Modal Footer -->
      <div class="p-6 flex justify-end gap-x-2">
        <!-- Cancel/Confirm Buttons -->
        <button
          id="cancel-modal"
          type="button"
          class="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          @click.stop="close"
        >
          Cancel
        </button>
        <button
          id="confirm-modal"
          type="button"
          class="text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
          :class="
            canSave
              ? 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              : 'bg-gray-200 dark:bg-gray-400 cursor-not-allowed'
          "
          :disabled="!canSave"
          @click.stop="updateIdentity"
        >
          <Spinner v-if="props.isSaving" class="w-5 h-5" />
          <span v-else> Confirm </span>
        </button>
      </div>
    </div>
  </div>
</template>
