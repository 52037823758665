import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      d: "M12 21.6C14.5461 21.6 16.9879 20.5886 18.7883 18.7882C20.5886 16.9879 21.6 14.5461 21.6 12C21.6 9.45392 20.5886 7.01212 18.7883 5.21177C16.9879 3.41142 14.5461 2.39999 12 2.39999C9.45395 2.39999 7.01215 3.41142 5.2118 5.21177C3.41145 7.01212 2.40002 9.45392 2.40002 12C2.40002 14.5461 3.41145 16.9879 5.2118 18.7882C7.01215 20.5886 9.45395 21.6 12 21.6V21.6ZM13.2 8.39999C13.2 8.08173 13.0736 7.77651 12.8486 7.55147C12.6235 7.32642 12.3183 7.19999 12 7.19999C11.6818 7.19999 11.3765 7.32642 11.1515 7.55147C10.9265 7.77651 10.8 8.08173 10.8 8.39999V10.8H8.40002C8.08176 10.8 7.77654 10.9264 7.5515 11.1515C7.32645 11.3765 7.20002 11.6817 7.20002 12C7.20002 12.3183 7.32645 12.6235 7.5515 12.8485C7.77654 13.0736 8.08176 13.2 8.40002 13.2H10.8V15.6C10.8 15.9183 10.9265 16.2235 11.1515 16.4485C11.3765 16.6736 11.6818 16.8 12 16.8C12.3183 16.8 12.6235 16.6736 12.8486 16.4485C13.0736 16.2235 13.2 15.9183 13.2 15.6V13.2H15.6C15.9183 13.2 16.2235 13.0736 16.4486 12.8485C16.6736 12.6235 16.8 12.3183 16.8 12C16.8 11.6817 16.6736 11.3765 16.4486 11.1515C16.2235 10.9264 15.9183 10.8 15.6 10.8H13.2V8.39999Z",
      "clip-rule": "evenodd"
    })
  ]))
}