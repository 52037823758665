import gql from 'graphql-tag'
import { Component, ComputedOptions, MethodOptions } from 'vue'

import { Itinerary, Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'
import { replacePathToMediaUris } from '@ankor-io/common/media/uri.media.replace'
import { EditableProposal } from '@ankor-io/common/proposal/Proposal'
import { SectionType } from '@ankor-io/common/proposal/SectionType'

import HeroConfiguration from '@/sections/hero/HeroConfiguration.vue'
import HeroSectionEditorVue from '@/sections/hero/HeroSectionEditor.vue'
import { ProposalAbstractEditableSection } from '@/services/proposal/Section'

const QUERY = gql`
  query Itinerary($URI: String!) {
    itinerary(uri: $URI) {
      uri
      internalName
      images
      segments {
        stops {
          place {
            uri
            images
          }
        }
      }
      from {
        location {
          region
        }
      }
    }
  }
`

/**
 * Get all the images from the itinerary
 * @param itinerary
 */
const getAllImages = (itinerary: Itinerary): string[] => {
  const itineraryImages = (itinerary.images && replacePathToMediaUris(itinerary.uri, ...itinerary.images)) || []
  return itineraryImages.concat(
    itinerary.segments.flatMap(
      (segment: Segment) =>
        segment.stops.flatMap(
          (stop: Stop) => stop.place?.images && replacePathToMediaUris(stop.place.uri, ...stop.place.images),
        ) || [],
    ),
  )
}

export interface HeroSectionEditorData {
  heading: string
  subheading: string
  images: string[]
  heroImages: string[]
}

export class HeroSectionEditor extends ProposalAbstractEditableSection<HeroSectionEditorData, EditableProposal> {
  async fetchData(): Promise<HeroSectionEditorData> {
    const itineraryUri = this.getSlideUri()
    const client = await this.client()
    return client
      .query({
        query: QUERY,
        variables: { URI: itineraryUri },
      })
      .then(({ data }) => {
        const images = getAllImages(data?.itinerary).filter((image) => image)
        return Promise.resolve({
          heading: data?.itinerary.internalName,
          subheading: data?.itinerary.from.location.region || null,
          images,
          heroImages: images,
        })
      })
  }

  getType(): SectionType {
    return SectionType.ITINERARY_HERO
  }

  getComponent(): Component<any, any, any, ComputedOptions, MethodOptions> {
    return HeroSectionEditorVue
  }

  getConfigurationComponent(): Component<any, any, any, ComputedOptions, MethodOptions> | null {
    return HeroConfiguration
  }
}
