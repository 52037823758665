<script setup lang="ts">
import { Ref } from 'vue'

import { Runnable } from '@ankor-io/common/lang/functional.types'

import Spinner from '@/components/Spinner.vue'

type PlacePointPopup = {
  uri: string
  latitude: number
  longitude: number
  isCustom: boolean
  line_1: string
  line_2: string
  line_3: string
  line_4: string
  image: string
  handleAdd: Runnable<string>
  handleDelete: Runnable<string>
  isDeletingCustomPoint: Ref<boolean> // We can pass the Ref itself so this is reactive
}

const props = defineProps<PlacePointPopup>()

const addPlace = () => {
  props.handleAdd(props.uri)
}

const deletePlace = () => {
  props.handleDelete(props.uri)
}
</script>
<template>
  <figure class="hide-on-transit relative w-full group">
    <div class="relative max-w-md max-h-72 text-center">
      <h3 class="text-lg text-gray-700 tracking-tight font-bold line-clamp-1" v-html="line_1"></h3>
      <span class="text-gray-600">{{ line_4 ? line_4 + ',' : '' }} {{ line_3 }}</span>
      <div class="flex text-gray-500 text-xs">
        <p class="mx-1 w-full leading-tight line-clamp-3" v-html="line_2"></p>
      </div>
      <div class="mt-2 flex space-x-1 items-center justify-center">
        <button
          type="button"
          class="add-place h-8 w-16 transition-all text-white items-center outline-none rounded-lg text-xs text-center focus:ring-2 whitespace-nowrap"
          :class="[
            !isCustom
              ? 'bg-primary-500 hover:bg-primary-600 active:bg-primary-500 focus:bg-primary-500 focus:ring-primary-300'
              : 'bg-purple-500 hover:bg-purple-600 active:bg-purple-500 focus:bg-purple-500 group focus:ring-purple-300',
            props.isDeletingCustomPoint.value ? 'cursor-not-allowed' : 'cursor-pointer',
          ]"
          :disabled="props.isDeletingCustomPoint.value"
          @click="addPlace"
        >
          <span class="p-1">Add</span>
        </button>
        <button
          type="button"
          class="adding-place h-8 w-16 items-center outline-none rounded-lg bg-gray-200 cursor-not-allowed dark:bg-gray-400"
        >
          <Spinner class="w-4 h-4 text-gray-500 dark:text-gray-400" />
        </button>
        <button
          v-if="isCustom"
          type="button"
          class="h-8 w-16 transition-all bg-red-500 hover:bg-red-600 active:bg-red-500 focus:bg-red-500 focus:ring-red-300 text-white items-center outline-none rounded-lg text-xs text-center focus:ring-2 whitespace-nowrap"
          :class="props.isDeletingCustomPoint.value ? 'cursor-not-allowed' : 'cursor-pointer'"
          :disabled="props.isDeletingCustomPoint.value"
          @click="deletePlace"
        >
          <Spinner v-if="props.isDeletingCustomPoint.value" class="w-4 h-4" />
          <span v-else class="p-1">Delete</span>
        </button>
      </div>
    </div>
  </figure>
</template>

<style lang="scss">
.adding-place {
  display: none;
}
.adding-marker {
  .adding-place {
    display: block;
  }
  .add-place {
    display: none;
  }
}
</style>
