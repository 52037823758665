<script setup lang="ts">
import { initDropdowns } from 'flowbite'
import * as LibPhoneNumberJS from 'libphonenumber-js'
import { Ref, onMounted, ref } from 'vue'

type Props = {
  value?: string
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:phone:number', value: string): void
}>()

const countryCodeToFlag = (countryCode: string): string => {
  return countryCode
    .toUpperCase()
    .split('')
    .map((char) => String.fromCodePoint(char.charCodeAt(0) + 0x1f1a5))
    .join('')
}

// Phone input vars
const regionNames = new Intl.DisplayNames(['en'], { type: 'region' })
const countryCode: Ref<LibPhoneNumberJS.CountryCode> = ref('US')
const countryFlag: Ref<string> = ref(countryCodeToFlag(countryCode.value))

const countryCallingCode: Ref<LibPhoneNumberJS.CountryCallingCode> = ref(
  LibPhoneNumberJS.getPhoneCode(countryCode.value), // 61
)
const phoneNumber: Ref<string | null> = ref(null) // The number that appears after the calling code only
const isPhoneNumberValid: Ref<boolean> = ref(true)

onMounted(() => {
  initDropdowns()
  getPhoneNumber()
})

/**
 * Get the phone number from the props.value and use the libphonenumber-js library to disect the phone number to local vars
 */
const getPhoneNumber = () => {
  if (!props.value) {
    return
  }

  const parsedPhoneNumber = LibPhoneNumberJS.parse(props.value)
  // It wont validate the parse if it is invalid
  if (!parsedPhoneNumber || !Object.keys(parsedPhoneNumber).length) {
    throw new Error('Oops! Failed to parse phone number')
  }

  phoneNumber.value = parsedPhoneNumber.phone
  countryCode.value = parsedPhoneNumber.country
  countryFlag.value = countryCodeToFlag(countryCode.value)
  countryCallingCode.value = LibPhoneNumberJS.getPhoneCode(countryCode.value)
}

/**
 * Updates the country code, calling code and the flag through the libphonenumber-js library
 * @param country The country code, eg: AU, US
 */
const updatePhoneCode = (country: LibPhoneNumberJS.CountryCode) => {
  countryCode.value = country
  countryFlag.value = countryCodeToFlag(countryCode.value)
  countryCallingCode.value = LibPhoneNumberJS.getPhoneCode(countryCode.value)

  // We should check the validity of the phone number otherwise changing the code with a previously valid number will remain truthy, just don't emit
  if (phoneNumber.value) {
    const value = `+${countryCallingCode.value} ${phoneNumber.value}`
    isPhoneNumberValid.value = LibPhoneNumberJS.isValidNumber(value)
  }
}

/**
 * Updates the entire phone number value, bringing together the calling code + the number and determines
 * the validity of the whole number through libphonenumber-js library. Emit the value if it is valid
 * @param event HTML Input Event from the phone input field
 */
const updatePhoneNumber = (event: Event) => {
  const target = event.target as HTMLInputElement
  phoneNumber.value = target.value

  // Importance in separating code and value by a whitespace so that when we retrieve the saved value later on, we can get this code and value separately
  const value = `+${countryCallingCode.value} ${phoneNumber.value}`
  isPhoneNumberValid.value = LibPhoneNumberJS.isValidNumber(value)
  if (isPhoneNumberValid.value) {
    emit('update:phone:number', value)
  }
}
</script>
<template>
  <div class="flex items-center">
    <button
      id="dropdown-phone-button"
      data-dropdown-toggle="dropdown-phone"
      class="flex-shrink-0 z-10 inline-flex items-center gap-x-2 py-2 px-3 text-sm font-medium text-center rounded-l-lg focus:ring-4 focus:outline-none text-gray-900 bg-gray-100 border border-gray-300 focus:ring-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
      type="button"
    >
      {{ countryFlag }} +{{ countryCallingCode }}
      <svg
        class="w-2.5 h-2.5 ms-2.5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
      </svg>
    </button>
    <div
      id="dropdown-phone"
      class="z-10 h-64 overflow-auto hidden divide-y rounded-lg shadow bg-white divide-gray-100 dark:bg-gray-700"
    >
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdown-phone-button">
        <li v-for="(country, index) of LibPhoneNumberJS.getCountries()" :key="`${index}:${country}`">
          <template v-if="countryCodeToFlag(country) && regionNames.of(country)">
            <button
              type="button"
              role="menuitem"
              class="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-600 dark:hover:text-white"
              @click="updatePhoneCode(country)"
            >
              <span class="inline-flex items-center">
                {{ countryCodeToFlag(country) }}
                {{ regionNames.of(country) }}
                (+{{ LibPhoneNumberJS.getPhoneCode(country) }})
              </span>
            </button>
          </template>
        </li>
      </ul>
    </div>
    <label for="phone-input" class="mb-2 text-sm font-medium sr-only text-gray-900 dark:text-white">
      Phone number:
    </label>
    <input
      type="text"
      id="phone-input"
      placeholder="1234567890"
      class="block py-2 px-3 w-full z-20 text-sm rounded-r-lg border-s-0 border bg-white dark:bg-gray-900 focus:ring-blue-500 focus:border-blue-500 dark:placeholder-gray-400 text-gray-900 dark:text-white dark:focus:border-blue-500"
      :class="[
        !isPhoneNumberValid && phoneNumber
          ? 'border-red-500 dark:border-red-400 !bg-red-600 !bg-opacity-10'
          : 'border-gray-300 dark:border-s-gray-700 dark:border-gray-600',
        { '!bg-gray-50 dark:!bg-gray-800 active:bg-transparent focus:bg-transparent': !phoneNumber },
      ]"
      :value="phoneNumber"
      @blur="updatePhoneNumber($event)"
    />
  </div>
</template>
