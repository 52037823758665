<script setup lang="ts">
import { Ref, inject, onBeforeMount, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import AssetViewerPlaceholder from '@ankor-io/blocks/components/AssetViewer/AssetViewerPlaceholder.vue'
import ImageCarouselModalEditor from '@ankor-io/blocks/components/ImageCarouselModal/ImageCarouselModalEditor.vue'
import { OutlineDelete } from '@ankor-io/icons/outline'
import { _requestSignedUrl, uploadFile } from '@ankor-io/wheelhouse/src/services/assets/upload'

import AssetUploader from '@/components/asset-uploader/AssetUploader.vue'
import DeleteConfirmation, { DeleteConfirmationDataProps } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { AuthenticationContext } from '@/iam/types'
import { useModal } from '@/modal/useModal'

type DeleteConfirmationData = DeleteConfirmationDataProps & {
  index: number
}

type Asset = {
  id: number
  path: string
}

type Props = {
  uri: string
  images: string[]
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:assets', value: { assets: string[] }): void
}>()

const uploadProgress: Ref<number> = ref(0)
const uploadState: Ref<string | null> = ref(null)
const assetsRef: Ref<Asset[]> = ref([])
const authenticationContext: AuthenticationContext = inject('authenticationContext')!

const { isOpen, updateModalState } = useModal()
const modalValue: Ref<DeleteConfirmationData | null> = ref(null)

const showCarouselModal: Ref<boolean> = ref(false)
const isDragging: Ref<boolean> = ref(false)

// transforming the shape of images to have id and
// track via reference so as to not resolve images
// on any of the drag/drop/reorder actions
onBeforeMount(() => {
  assetsRef.value = toAssetFromPath(props.images)
})

const toPathFromAsset = (assets: Asset[]): string[] => assets.map((asset) => asset.path)

const toAssetFromPath = (paths: string[]): Asset[] =>
  paths.map((path, index) => ({
    id: index,
    path,
  }))

const handleUploadFile = async (file: File | File[]): Promise<boolean> => {
  const setProgress = setInterval(() => {
    if (uploadProgress.value >= 99) {
      clearInterval(setProgress)
      uploadProgress.value = 0
    } else {
      uploadProgress.value += 1
    }
  }, 100)

  const filesToUpload = Array.isArray(file) ? file : [file]

  const results = filesToUpload.map(async (fileToUpload: File) => {
    const token: string | undefined = await authenticationContext.getToken()
    const { mediaUri, signedUrl } = await _requestSignedUrl(props.uri, token!)
    const uploaded: boolean = await uploadFile(signedUrl, fileToUpload)

    const fileReader = new FileReader()
    fileReader.readAsDataURL(fileToUpload)
    clearInterval(setProgress)
    uploadProgress.value = 0

    if (!uploaded) {
      uploadState.value = 'Failed'
      return false
    }

    uploadState.value = 'Success'

    assetsRef.value.push({ id: assetsRef.value.length, path: mediaUri })
    emit('update:assets', { assets: toPathFromAsset(assetsRef.value) })

    setTimeout(() => {
      uploadState.value = null
    }, 3000)
    return true
  })

  return results.reduce(
    async (a: Promise<boolean>, b: Promise<boolean>) => (await a) && (await b),
    Promise.resolve(true),
  )
}

/**
 * move position of an asset on change
 * @param e change event from draggable
 */
const updateOrder = (e: any): void => {
  if (e.moved) {
    emit('update:assets', { assets: toPathFromAsset(assetsRef.value) })
  }
}

const openDeleteConfirmation = (index: number) => {
  modalValue.value = {
    message: 'You are about to delete an image from this route, are you sure you want to proceed?',
    labelCancel: 'No, keep the image',
    labelConfirm: 'Yes, delete it',
    index,
  }

  updateModalState(true)
}

const confirmDelete = () => {
  assetsRef.value.splice(modalValue.value!.index, 1)
  updateModalState(false)
  modalValue.value = null
  emit('update:assets', { assets: toPathFromAsset(assetsRef.value) })
}

const closeModal = () => {
  updateModalState(false)
}

watch(
  () => props.images,
  (value: string[]) => {
    if (JSON.stringify(toPathFromAsset(assetsRef.value)) !== JSON.stringify(value)) {
      assetsRef.value = toAssetFromPath(props.images)
    }
  },
)

watch(isOpen, (value) => {
  if (!value) {
    modalValue.value = null
  }
})
</script>

<template>
  <div id="assets-wrapper" class="flex flex-col gap-y-4">
    <p v-if="assetsRef.length === 0" class="flex justify-center items-center h-40 text-gray-500 dark:text-gray-400">
      No photos yet
    </p>

    <draggable
      item-key="id"
      class="flex-wrap gap-4"
      :class="[assetsRef.length > 0 ? 'flex' : 'hidden']"
      ghostClass="asset-ghost"
      dragClass="asset-drag"
      :list="assetsRef"
      @start="isDragging = true"
      @end="isDragging = false"
      @change="updateOrder"
    >
      <template #item="{ element: asset, index: assetIndex }: { element: Asset, index: number }">
        <div
          class="group relative h-40 w-52 rounded overflow-hidden transition-[transform background-color] ease-in-out duration-500"
        >
          <!-- on hover background -->
          <div
            v-if="!isDragging"
            class="hide-on-drag absolute top-0 w-full h-full transition-all duration-300 invisible group-hover:visible"
          >
            <div
              class="z-10 bg-black rounded-md w-full h-full transition-opacity duration-300 opacity-0 group-hover:opacity-25 hover:opacity-25"
            ></div>
          </div>

          <!-- on hover actions -->
          <button
            v-if="!isDragging"
            class="hide-on-drag z-20 absolute flex justify-center items-center top-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:top-1/4 group-hover:-translate-y-1/4 border border-white bg-white w-20 px-3 py-2 rounded-md transition-all duration-300 bg-opacity-25 opacity-0 group-hover:opacity-100"
            type="button"
            @click="showCarouselModal = true"
          >
            <span class="mr-1 text-white font-medium text-xs">Gallery</span>
          </button>
          <button
            v-if="!isDragging"
            class="hide-on-drag gap-x-1 z-20 absolute flex justify-around items-center bottom-0 left-1/2 -translate-x-1/2 cursor-pointer group-hover:bottom-1/4 group-hover:translate-y-1/4 border border-red-600 bg-red-300 w-20 px-3 py-2 rounded-md transition-all duration-300 opacity-0 group-hover:opacity-100"
            type="button"
            @click="openDeleteConfirmation(assetIndex)"
          >
            <OutlineDelete class="w-4 h-4 shrink-0 stroke-red-600" />
            <span class="text-red-600 font-medium text-xs">Delete</span>
          </button>

          <!-- asset -->
          <AssetViewerPlaceholder class="w-full h-full object-cover" :url="`/media/${asset.path}`" />
        </div>
      </template>
    </draggable>

    <AssetUploader
      id="itinerary-assets-uploader"
      class="h-36"
      :uploadProgress="uploadProgress"
      :uploadState="uploadState"
      @file:loaded="handleUploadFile"
    />

    <Teleport to="body">
      <ImageCarouselModalEditor v-show="showCarouselModal" :slides="props.images" @close="showCarouselModal = false" />
    </Teleport>

    <ModalContentWrapper v-if="modalValue">
      <DeleteConfirmation
        :message="modalValue.message"
        :label-cancel="modalValue.labelCancel"
        :label-confirm="modalValue.labelConfirm"
        @close:modal="closeModal()"
        @confirm:modal="confirmDelete()"
      />
    </ModalContentWrapper>
  </div>
</template>
<style lang="scss" scoped>
.asset-ghost {
  background: #e5e7eb; // gray/200
  border: 1px dashed #e5e7eb; // gray/200
  > * {
    display: none;
  }
}
.asset-drag {
  .hide-on-drag {
    display: none;
  }
}
</style>
