<script setup lang="ts">
import { defineAsyncComponent, onBeforeMount } from 'vue'

import '@ankor-io/common/tailwindcss/base.scss'

import Loading from '@/components/Loader.vue'
import Modal from '@/components/Modal.vue'
import { useModal } from '@/modal/useModal'

const AppContext = defineAsyncComponent(() => import('./AppContext.vue'))
const { isOpen } = useModal()

onBeforeMount(() => {
  // Read from browser setting of dark theme and move to session storage.
  const storageValue = sessionStorage.getItem('prefers-color-scheme')
  if (storageValue && storageValue === 'dark') {
    document.documentElement.classList.add('dark')
  } else if (!storageValue && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    document.documentElement.classList.add('dark')
  }
})
</script>
<template>
  <Suspense>
    <template #fallback>
      <Loading class="h-screen" />
    </template>
    <AppContext>
      <!-- TODO: fix navbar section to use new modal instead -->
      <Modal v-show="isOpen">
        <div id="modal-container"></div>
      </Modal>

      <main class="trips bg-white dark:bg-gray-900">
        <RouterView name="main" />
      </main>
    </AppContext>
  </Suspense>
</template>
<style></style>
