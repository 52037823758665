<script lang="ts" setup>
import { initPopovers } from 'flowbite'
import { Ref, nextTick, onMounted, ref, watch } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { EditableLifecycleHooks } from '@ankor-io/common/lang/Lifecycle'
import { Runnable } from '@ankor-io/common/lang/functional.types'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { JsonProposal } from '@ankor-io/common/proposal/Proposal'
import { Discount, DiscountTypeValues, InputAmountsTaxed, TaxRate, Unit } from '@ankor-io/common/vessel/types'
import { OutlineCog, OutlineInformationCircle, OutlineTrash } from '@ankor-io/icons/outline'
import { SolidCircleX } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'
import MultiLineTextEditor from '@/components/editor/text/MultiLineTextEditor.vue'
import SingleLineTextEditor from '@/components/editor/text/SingleLineTextEditor.vue'
import DiscountVue, { DiscountProps } from '@/components/modal-content/PricingDiscount.vue'
import QuantityVue, { QuantityProps } from '@/components/modal-content/PricingQuantity.vue'
import TaxRateVue, { TaxRateProps } from '@/components/modal-content/PricingTaxRate.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/modal/useModal'
import { restrictToNumbers } from '@/utils/restrictKeyPress'

import Dropdown from './Dropdown.vue'
import {
  InputAmountsTaxedValues,
  LineItemPricing,
  VesselPricing,
  VesselPricingLayoutTemplate,
  VesselPricingSectionData,
} from './types/types'

type Props = {
  /**
   * The section id
   */
  id: string
  /**
   * The proposal uri
   */
  proposalUri: string
  /**
   * The vessel pricing data
   */
  data: VesselPricingSectionData
  /**
   * The vessel layout
   */
  layout: VesselPricingLayoutTemplate
  /**
   * The proposal document
   */
  document: JsonProposal
  isHydrating: boolean
  lifecycle: Runnable<EditableLifecycleHooks>
}

const props = defineProps<Props>()
const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<VesselPricingSectionData>): void
  (e: 'update:layout', value: { sectionId: string; layout: VesselPricingLayoutTemplate }): void
}>()

const { isOpen, updateModalState } = useModal()
const selectedModalType: Ref<'TaxRate' | 'Discount' | 'Quantity' | null> = ref(null)
const taxRateModalData: Ref<TaxRateProps | null> = ref(null)
const discountModalData: Ref<DiscountProps | null> = ref(null)
const quantityModalData: Ref<QuantityProps | null> = ref(null)

const selectedLineItemIndex: Ref<number> = ref(-1)
const showConfigureColumns: Ref<boolean> = ref(false)
const inputRefs: Ref<any> = ref([])

const inputAmountTaxedOptions = [
  { label: 'Tax Inclusive', value: InputAmountsTaxedValues.INCLUSIVE },
  { label: 'Tax Exclusive', value: InputAmountsTaxedValues.EXCLUSIVE },
  { label: 'No Tax', value: InputAmountsTaxedValues.NONE },
]

const inputCurrencyOptions = [
  { label: ' ', value: null },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'GBP', value: 'GBP' },
  { label: 'AUD', value: 'AUD' },
  { label: 'AED', value: 'AED' },
  { label: 'SGD', value: 'SGD' },
  { label: 'HKD', value: 'HKD' },
  { label: 'BTC', value: 'BTC' },
  { label: 'ETH', value: 'ETH' },
]

const unitOptions = [
  { label: 'Weekly', value: 'WEEK' },
  { label: 'Daily', value: 'DAY' },
  { label: 'Hourly', value: 'HOUR' },
]

const pricingLineColumns = [
  {
    key: 'item',
    label: 'Item',
    helpText:
      'The name and/or description of the item, for example "Charter Fee" or "Advanced Provisioning Allowance: the APA is an advance payment that covers variable expenses that can arise during your charter"',
  },
  {
    key: 'conditions',
    label: 'Conditions',
    helpText:
      'A description of any conditions related to the item, for example "Half Board includes seven breakfasts, four lunches, and three dinners in your charter fee"',
  },
  {
    key: 'quantity',
    label: 'Quantity',
    helpText:
      'The number or percentage of the item, for example APA may be "35%" or a Beverage Package for 20 guests would be "20". The default Quantity is "1"',
  },
  {
    key: 'unitPrice',
    label: 'Price',
    helpText:
      'The price per item, for example a $50k Charter Fee would be "50000", or a day beverage package per guest may be "99.95"',
  },
  {
    key: 'discount',
    label: 'Discount',
    helpText:
      'Any discount applied to the item, which can be a percentage or numeric amount, for example "5%" or "5000"',
  },
  {
    key: 'taxRate',
    label: 'Tax Rate',
    helpText: 'The tax type and percentage that applies, for example "VAT 20%"',
  },
  {
    key: 'amount',
    label: 'Amount',
    helpText: '',
  },
]

onMounted(() => initPopovers())

const updateValue = (value: VesselPricing) => {
  emit('update:value', {
    sectionId: props.id,
    data: {
      pricing: value,
    },
  })
}

const updateLayout = (optionKey: string, value: string | boolean) => {
  emit('update:layout', {
    sectionId: props.id,
    layout: {
      ...props.layout,
      options: {
        ...props.layout.options,
        [optionKey]: value,
      },
    },
  })
}

const updatePricingAttributes = (fieldKey: 'note' | 'currency', value: string) => {
  const updatedPricing: VesselPricingSectionData = ObjectUtil.deepCopy(props.data)
  updatedPricing.pricing[fieldKey] = value
  updateValue(updatedPricing.pricing)
}

const updatePricingUnit = (value: Unit) => {
  const updatedPricing: VesselPricingSectionData = ObjectUtil.deepCopy(props.data)
  updatedPricing.pricing.unit = value
  updateValue(updatedPricing.pricing)
}

const updateInputAmountTaxed = (value: InputAmountsTaxed) => {
  const dataCopy: VesselPricingSectionData = ObjectUtil.deepCopy(props.data)
  dataCopy.pricing.inputAmountTaxed = value

  const updatedPricing = reComputeTotals(dataCopy.pricing)
  updateValue(updatedPricing)
}

/**
 * Compute totals for pricing based on Amounts being INCLUSIVE, EXCLUSIVE, NONE
 *
 * @param pricing
 */
const reComputeTotals = (pricing: VesselPricing): VesselPricing => {
  const updatedPricing: VesselPricing = ObjectUtil.deepCopy(pricing)
  if (!pricing?.lineItems?.length) {
    updatedPricing.subTotal = 0
    updatedPricing.totalTax = 0
    updatedPricing.total = 0
    return updatedPricing
  }

  updatedPricing.subTotal = pricing.lineItems.reduce((acc, lineItem) => {
    return acc + lineItem.amount
  }, 0)

  if (updatedPricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE || !props.data.pricing.inputAmountTaxed) {
    updatedPricing.totalTax = pricing.lineItems.reduce((acc, lineItem) => {
      return acc + lineItem.amount * (lineItem.taxRate?.value ?? 0)
    }, 0)

    updatedPricing.total = updatedPricing.subTotal
    return updatedPricing
  }

  if (updatedPricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE) {
    updatedPricing.totalTax = pricing.lineItems.reduce((acc, lineItem) => {
      return acc + lineItem.amount * (lineItem.taxRate?.value ?? 0)
    }, 0)

    updatedPricing.total = updatedPricing.subTotal + updatedPricing.totalTax
    return updatedPricing
  }

  updatedPricing.totalTax = 0
  updatedPricing.total = updatedPricing.subTotal
  return updatedPricing
}

const getAmount = (lineItemPricing: LineItemPricing): number => {
  const amount = lineItemPricing.quantity * lineItemPricing.unitPrice
  if (!lineItemPricing.discount) {
    return amount
  }

  if (lineItemPricing.discount.type === DiscountTypeValues.PERCENTAGE) {
    return amount - amount * lineItemPricing.discount.value
  }

  return amount - lineItemPricing.discount.value
}

const getLineItemAttribute = (lineItemPricing: LineItemPricing, attribute: string): string => {
  if (attribute === 'quantity') {
    const suffix = lineItemPricing.displayQuantityAsPercent ? '%' : ''
    const quantityToDisplay = lineItemPricing.displayQuantityAsPercent
      ? (lineItemPricing[attribute] || 0) * 100
      : lineItemPricing[attribute]
    return lineItemPricing[attribute] ? `${Math.round(quantityToDisplay * 100) / 100}${suffix}` : '-'
  }

  if (attribute === 'taxRate') {
    return lineItemPricing[attribute]
      ? `${lineItemPricing[attribute]?.label} ${
          Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
        }%`
      : '-'
  }

  if (attribute === 'discount') {
    const value =
      lineItemPricing[attribute]?.type === DiscountTypeValues.AMOUNT
        ? lineItemPricing[attribute]?.value
        : Math.round((lineItemPricing[attribute]?.value || 0) * 100 * 100) / 100
    return lineItemPricing[attribute]
      ? `${value}${lineItemPricing[attribute]?.type === DiscountTypeValues.PERCENTAGE ? '%' : ''}`
      : '-'
  }

  if (attribute === 'amount' || attribute === 'unitPrice') {
    return (Number(lineItemPricing[attribute]) / 100).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  }

  if (attribute === 'item' || attribute === 'conditions') {
    return lineItemPricing[attribute]?.toString() ?? ''
  }

  return ''
}

const updateLineItemQuantity = (index: number, displayQuantityAsPercent: boolean, quantity: number) => {
  const lineItems: LineItemPricing[] = ObjectUtil.deepCopy(props.data.pricing.lineItems)
  lineItems[index].displayQuantityAsPercent = displayQuantityAsPercent
  lineItems[index].quantity = quantity
  lineItems[index]['amount'] = getAmount(lineItems[index])
  const updatedPricing = reComputeTotals({ ...props.data.pricing, lineItems })
  updateValue(updatedPricing)
}

const updateUnitPrice = (index: number, $event: Event) => {
  const lineItems: LineItemPricing[] = ObjectUtil.deepCopy(props.data.pricing.lineItems)

  const value = ($event.target as HTMLInputElement).value
  selectedLineItemIndex.value = -1
  // have to multiply by 100 as we always store cent values, return if nothing changed
  if (lineItems[index]['unitPrice'] === Number(value) * 100) {
    return
  }

  lineItems[index]['unitPrice'] = Number(value) * 100
  lineItems[index]['amount'] = getAmount(lineItems[index])
  const updatedPricing = reComputeTotals({ ...props.data.pricing, lineItems })
  updateValue(updatedPricing)
}

const updateLineItem = (index: number, attribute: string, value: string) => {
  const lineItems: LineItemPricing[] = ObjectUtil.deepCopy(props.data.pricing.lineItems)
  if (attribute === 'item' || attribute === 'conditions') {
    lineItems[index][attribute] = value
    updateValue({ ...props.data.pricing, lineItems })

    return
  }

  if (attribute === 'taxRate') {
    lineItems[index][attribute] = JSON.parse(value)
    lineItems[index]['amount'] = getAmount(lineItems[index])
  }

  if (attribute === 'discount') {
    lineItems[index][attribute] = JSON.parse(value)
    lineItems[index]['amount'] = getAmount(lineItems[index])
  }

  const updatedPricing = reComputeTotals({ ...props.data.pricing, lineItems })
  updateValue(updatedPricing)
}

const addPricingLineItem = () => {
  const updatedPricing: VesselPricing = ObjectUtil.deepCopy(props.data.pricing)
  if (!updatedPricing.lineItems) {
    updatedPricing.lineItems = []
  }

  updatedPricing.lineItems.push({
    item: '',
    conditions: '',
    quantity: 1,
    unitPrice: 0,
    discount: undefined,
    taxRate: undefined,
    amount: 0,
  })

  updateValue(updatedPricing)
}

const deletePricingLineItem = (lineItemIndex: number) => {
  const pricingCopy: VesselPricing = ObjectUtil.deepCopy(props.data.pricing)
  pricingCopy.lineItems.splice(lineItemIndex, 1)
  const updatedPricing = reComputeTotals(pricingCopy)
  updateValue(updatedPricing)
}

const togglePricingLineColumn = (pricingLineColumn: string) => {
  const updatedPricingLineColumns = ObjectUtil.deepCopy(props.layout.options.enabledPricingColumns)
  updatedPricingLineColumns.includes(pricingLineColumn)
    ? updatedPricingLineColumns.splice(updatedPricingLineColumns.indexOf(pricingLineColumn), 1)
    : updatedPricingLineColumns.push(pricingLineColumn)

  updateLayout('enabledPricingColumns', updatedPricingLineColumns)
}

// TAX RATE MODAL
const openTaxRatePopup = (lineItemIndex: number, taxRate: TaxRate) => {
  selectedModalType.value = 'TaxRate'
  selectedLineItemIndex.value = lineItemIndex
  taxRateModalData.value = { taxRateName: taxRate.label || '', taxRateValue: taxRate.value?.toString() || '' }
  updateModalState(true)
}

const submitTaxRate = ($event: TaxRateProps) => {
  const { taxRateName, taxRateValue } = $event
  updateLineItem(
    selectedLineItemIndex.value,
    'taxRate',
    JSON.stringify({ label: taxRateName, value: Number(taxRateValue) / 100 }),
  )
  updateModalState(false)
  taxRateModalData.value = null
}

// DISCOUNT MODAL
const openDiscountPopup = (lineItemIndex: number, discount: Discount) => {
  selectedModalType.value = 'Discount'
  selectedLineItemIndex.value = lineItemIndex
  discountModalData.value = {
    discountType: discount.type || 'PERCENTAGE',
    discountValue: discount.value?.toString() || '',
  }
  updateModalState(true)
}

const submitDiscount = ($event: DiscountProps) => {
  const { discountType, discountValue } = $event
  updateLineItem(
    selectedLineItemIndex.value,
    'discount',
    JSON.stringify({
      type: discountType,
      value: discountType === DiscountTypeValues.AMOUNT ? discountValue : Number(discountValue) / 100,
    }),
  )
  updateModalState(false)
  discountModalData.value = null
}

// QUANTITY MODAL
const openQuantityPopup = (lineItemIndex: number, displayQuantityAsPercent: boolean, quantity: number) => {
  selectedModalType.value = 'Quantity'
  selectedLineItemIndex.value = lineItemIndex
  quantityModalData.value = {
    displayQuantityAsPercent: displayQuantityAsPercent,
    quantity: quantity?.toString() || '',
  }
  updateModalState(true)
}

const submitQuantity = ($event: QuantityProps) => {
  const { displayQuantityAsPercent, quantity } = $event
  const quantityPercent = displayQuantityAsPercent ? Number(quantity) / 100 : Number(quantity)
  updateLineItemQuantity(selectedLineItemIndex.value, displayQuantityAsPercent, quantityPercent)
  updateModalState(false)
  quantityModalData.value = null
}

const closeModal = () => {
  updateModalState(false)
  selectedModalType.value = null
}

/**
 * show editable unit price input and focus
 * @param index index of lineItem
 */
const showEditablePrice = (index: number) => {
  selectedLineItemIndex.value = index
  nextTick(() => inputRefs.value[index].focus())
}

watch(isOpen, (value) => {
  if (!value) {
    selectedModalType.value = null
  }
})
</script>
<template>
  <div
    v-if="props.isHydrating || !props.data.pricing"
    class="flex items-center justify-center h-[70vh] @sm:h-[calc(100vh-7rem)]"
  >
    <Spinner />
  </div>

  <div v-else id="vessel-pricing-section">
    <h3 class="font-bold text-3xl">
      <SingleLineTextEditor
        :value="props.layout.options?.pricingLabel"
        @update:value="updateLayout('pricingLabel', $event)"
      />
    </h3>

    <div class="flex justify-between text-sm items-end">
      <div class="basis-2/4">
        Note:
        <MultiLineTextEditor
          placeholder="Enter note here"
          :value="props.data.pricing?.note || ''"
          @update:value="updatePricingAttributes('note', $event)"
        />
      </div>

      <div class="flex flex-col @sm:flex-row justify-end items-center gap-x-2">
        Prices are:
        <div class="relative w-24 h-8 flex-none pr-1 text-left">
          <Dropdown
            id="unit"
            :value="props.data.pricing.unit || 'WEEK'"
            :options="unitOptions"
            @select:value="updatePricingUnit($event as Unit)"
          />
        </div>

        Currency:
        <div class="relative w-20 h-8 flex-none pr-1 text-left">
          <Dropdown
            id="currency"
            :value="props.data.pricing.currency"
            :options="inputCurrencyOptions"
            @select:value="updatePricingAttributes('currency', $event)"
          />
        </div>

        <span class="whitespace-nowrap">Amounts are:</span>
        <div class="relative w-32 h-8 flex-none pr-1 text-left">
          <Dropdown
            id="inputAmountTaxed"
            :value="props.data.pricing.inputAmountTaxed"
            :options="inputAmountTaxedOptions"
            @select:value="updateInputAmountTaxed($event as InputAmountsTaxed)"
          />
        </div>
      </div>
    </div>

    <div class="relative">
      <div
        v-if="!showConfigureColumns"
        class="absolute z-10 -top-3 -right-3 p-1 rounded-lg bg-gray-100 bg-opacity-90 shadow-md"
      >
        <OutlineCog
          class="cursor-pointer size-6 stroke-gray-600 hover:stroke-primary-600"
          @click="showConfigureColumns = true"
        />
      </div>
      <div
        v-if="showConfigureColumns"
        class="mt-4 -mb-4 text-right text-xs cursor-pointer hover:text-primary-600 group"
        @click.stop="showConfigureColumns = false"
      >
        <SolidCircleX class="mb-0.5 size-4 inline fill-gray-600 group-hover:fill-primary-600" />
        Close Column Selector
      </div>

      <div class="overflow-x-auto overflow-y-hidden drop-shadow-sm sm:rounded-lg mx-auto my-4 border border-gray-200">
        <table class="w-full text-sm text-left whitespace-nowrap text-gray-700" aria-describedby="Pricing">
          <thead class="text-xs uppercase" :style="{ background: props.layout.options.pricingHeaderBackground }">
            <tr>
              <template
                v-for="(pricingLineColumn, index) of pricingLineColumns"
                :key="`${pricingLineColumn.key}-${index}`"
              >
                <th
                  v-if="
                    props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key) ||
                    (showConfigureColumns &&
                      !props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key))
                  "
                  scope="col"
                  class="px-2 py-4"
                  :class="[
                    { 'border-r border-gray-200': index !== pricingLineColumns.length - 1 },
                    {
                      'opacity-50':
                        showConfigureColumns &&
                        !props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key),
                    },
                  ]"
                >
                  <div v-if="showConfigureColumns" class="flex items-center gap-x-1">
                    <input
                      type="checkbox"
                      class="size-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                      :id="pricingLineColumn.key"
                      :value="pricingLineColumn"
                      :checked="props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key)"
                      @click="togglePricingLineColumn(pricingLineColumn.key)"
                    />
                    <label :for="pricingLineColumn.key">
                      {{ pricingLineColumn.label }}
                    </label>
                  </div>
                  <div
                    v-else
                    class="flex items-center gap-x-1"
                    :class="{
                      'opacity-20':
                        pricingLineColumn.key === 'taxRate' &&
                        props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE,
                    }"
                  >
                    {{ pricingLineColumn.label }}
                    <button
                      v-if="pricingLineColumn.helpText"
                      type="button"
                      data-popover-placement="bottom"
                      :data-popover-target="`popover-${pricingLineColumn.key}`"
                    >
                      <OutlineInformationCircle class="size-4" />
                    </button>
                  </div>
                  <div
                    data-popover
                    role="tooltip"
                    class="absolute z-50 invisible inline-block text-xs normal-case font-normal whitespace-normal text-gray-500 transition-opacity duration-300 bg-white border border-gray-200 rounded-lg shadow-sm opacity-0"
                    :id="`popover-${pricingLineColumn.key}`"
                  >
                    <div class="max-w-[25rem] p-2">{{ pricingLineColumn.helpText }}</div>
                    <div data-popper-arrow></div>
                  </div>
                </th>
              </template>
              <th>
                <!-- actions -->
              </th>
            </tr>
          </thead>
          <tbody class="tracking-wider text-right" :style="{ background: props.layout.options.pricingBodyBackground }">
            <template
              v-for="(lineItem, lineItemIndex) of props.data.pricing.lineItems"
              :key="`${lineItem.item}-${lineItemIndex}`"
            >
              <tr class="whitespace-nowrap border-t h-12 border-gray-200">
                <template v-for="(pricingLineColumn, index) of pricingLineColumns">
                  <td
                    v-if="
                      props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key) ||
                      (showConfigureColumns &&
                        !props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key))
                    "
                    class="ml-3 p-2"
                    :key="`${pricingLineColumn.key}-${lineItemIndex}-${index}`"
                    :class="[
                      { 'border-r border-gray-200': index !== pricingLineColumns.length - 1 },
                      { 'text-left': ['item', 'conditions', 'taxRate'].includes(pricingLineColumn.key) },
                      { 'w-48': pricingLineColumn.key === 'unitPrice' },
                      {
                        'opacity-50':
                          showConfigureColumns &&
                          !props.layout.options.enabledPricingColumns?.includes(pricingLineColumn.key),
                      },
                      {
                        'opacity-20':
                          pricingLineColumn.key === 'taxRate' &&
                          props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE,
                      },
                    ]"
                  >
                    <div v-if="pricingLineColumn.key === 'unitPrice'">
                      <input
                        v-if="selectedLineItemIndex === lineItemIndex"
                        type="number"
                        class="px-2 h-6 text-sm text-right border border-gray-300 rounded"
                        pattern="^\d+(?:\.\d{1,2})?$"
                        :ref="(el) => (inputRefs[lineItemIndex] = el)"
                        :value="Number(lineItem['unitPrice']) / 100"
                        :placeholder="`Enter ${pricingLineColumn.label}`"
                        @blur="updateUnitPrice(lineItemIndex, $event)"
                        @keypress="restrictToNumbers($event)"
                        @keydown.up.prevent
                        @keydown.down.prevent
                      />
                      <span v-else @click.stop="showEditablePrice(lineItemIndex)">
                        {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                      </span>
                    </div>
                    <SingleLineTextEditor
                      v-else-if="['item', 'conditions'].includes(pricingLineColumn.key)"
                      :value="getLineItemAttribute(lineItem, pricingLineColumn.key)"
                      :placeholder="`Enter ${pricingLineColumn.label}`"
                      @update:value="updateLineItem(lineItemIndex, pricingLineColumn.key, $event)"
                    />
                    <div
                      v-else-if="
                        pricingLineColumn.key === 'taxRate' &&
                        props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.NONE
                      "
                    >
                      -
                    </div>
                    <div
                      v-else-if="pricingLineColumn.key === 'quantity'"
                      class="cursor-default"
                      @click.stop="
                        openQuantityPopup(lineItemIndex, lineItem.displayQuantityAsPercent, lineItem.quantity)
                      "
                    >
                      {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                    </div>
                    <div
                      v-else-if="pricingLineColumn.key === 'taxRate'"
                      class="cursor-default"
                      @click.stop="openTaxRatePopup(lineItemIndex, lineItem.taxRate || ({} as TaxRate))"
                    >
                      {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                    </div>
                    <div
                      v-else-if="pricingLineColumn.key === 'discount'"
                      class="cursor-default"
                      @click.stop="openDiscountPopup(lineItemIndex, lineItem.discount || ({} as Discount))"
                    >
                      {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                    </div>
                    <div v-else-if="pricingLineColumn.key === 'amount'" class="text-right cursor-default pr-2">
                      {{ getLineItemAttribute(lineItem, pricingLineColumn.key) }}
                    </div>
                  </td>
                </template>
                <td class="cursor-pointer" @click="deletePricingLineItem(lineItemIndex)">
                  <OutlineTrash class="size-4 stroke-red-600 hover:stroke-red-500" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>

    <div class="flex justify-between">
      <button
        class="h-10 text-primary-700 border border-primary-600 bg-white hover:bg-primary-200 hover:primary-300 font-medium rounded-lg text-sm px-5 py-2.5"
        @click.stop="addPricingLineItem"
      >
        + Add a line
      </button>

      <div class="grid gap-x-4 gap-y-1 grid-cols-2 text-right">
        <template v-if="props.data.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
          <div class="opacity-60">Subtotal:</div>
          <div>
            <span class="font-bold">
              {{
                props.data.pricing.subTotal
                  ? (props.data.pricing.subTotal / 100).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : 0
              }}
            </span>
            {{ props.data.pricing.currency }}
          </div>
        </template>

        <div v-if="props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.INCLUSIVE" class="opacity-60">
          Includes Tax:
        </div>
        <div v-if="props.data.pricing.inputAmountTaxed === InputAmountsTaxedValues.EXCLUSIVE" class="opacity-60">
          Total Tax:
        </div>
        <div v-if="props.data.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE">
          <span class="font-bold">
            {{
              props.data.pricing.totalTax
                ? (props.data.pricing.totalTax / 100).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0
            }}
          </span>
          {{ props.data.pricing.currency }}
        </div>

        <div class="opacity-60">Total:</div>
        <div :class="{ 'border-t': props.data.pricing.inputAmountTaxed !== InputAmountsTaxedValues.NONE }">
          <span class="font-bold">
            {{
              props.data.pricing.total
                ? (props.data.pricing.total / 100).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                : 0
            }}
          </span>
          {{ props.data.pricing.currency }}
        </div>
      </div>
    </div>

    <ModalContentWrapper v-if="selectedModalType">
      <TaxRateVue
        v-if="selectedModalType === 'TaxRate'"
        :tax-rate-name="taxRateModalData!.taxRateName"
        :tax-rate-value="taxRateModalData!.taxRateValue"
        @close:modal="closeModal()"
        @confirm:modal="submitTaxRate($event)"
      />
      <DiscountVue
        v-if="selectedModalType === 'Discount'"
        :discount-type="discountModalData!.discountType"
        :discount-value="discountModalData!.discountValue"
        @close:modal="closeModal()"
        @confirm:modal="submitDiscount($event)"
      />
      <QuantityVue
        v-if="selectedModalType === 'Quantity'"
        :display-quantity-as-percent="quantityModalData!.displayQuantityAsPercent"
        :quantity="quantityModalData!.quantity"
        @close:modal="closeModal()"
        @confirm:modal="submitQuantity($event)"
      />
    </ModalContentWrapper>
  </div>
</template>
