<script setup lang="ts">
import { Ref, nextTick, onBeforeMount, ref, watch } from 'vue'
import draggable from 'vuedraggable'

import { Segment, Stop } from '@ankor-io/common/itinerary/Itinerary'
import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { SolidChevronDoubleRight, SolidCircleX, SolidDrag, SolidPencil, SolidPlusCircle } from '@ankor-io/icons/solid'

import DeleteConfirmation, { DeleteConfirmationDataProps } from '@/components/modal-content/DeleteConfirmation.vue'
import ModalContentWrapper from '@/components/modal-content/Wrapper.vue'
import { useModal } from '@/modal/useModal'

type DeleteConfirmationData = DeleteConfirmationDataProps & {
  segmentIndex: number
  stopIndex?: number
}

type ItinerarySegmentsProps = {
  overviewHeading: string
  selectedPlaceUri: string | null
  selectedSegmentIndex: number | null
  selectedStopIndex: number | null
  segments: Segment[]
}

const props = defineProps<ItinerarySegmentsProps>()

const emit = defineEmits<{
  (e: 'update:segments', value: { segments: Segment[] }): void
  (
    e: 'update:selection',
    value: { placeUri: string | null; segmentIndex: number | null; stopIndex: number | null },
  ): void
}>()

const { isOpen, updateModalState } = useModal()

// ref to keep track of segments
const segmentsRef = ref([] as Segment[])
// ref to determine which segment label is edited
const editingSegmentLabelRef: Ref<number | null> = ref(null)
// refs for segment label inputs
const clonedInputLabelRefs: Ref<any> = ref([])
// ref to show actions on segment
const hoveredSegmentIndex: Ref<number | null> = ref(null)
// ref to show/hide elements while drag
const isDragging: Ref<boolean> = ref(false)

const isDeleteConfirmationOpen: Ref<boolean> = ref(false)
const deleteConfirmationData: Ref<DeleteConfirmationData | null> = ref(null)

const selectOverview = () => {
  emit('update:selection', { placeUri: null, segmentIndex: null, stopIndex: null })
}

const selectPlace = (uri: string, segmentIndex: number, stopIndex: number) => {
  emit('update:selection', { placeUri: uri, segmentIndex, stopIndex })
}

/**
 * show editable segment label input and focus
 * @param index index of segment
 */
const showEditableSegmentLabel = (index: number) => {
  editingSegmentLabelRef.value = index
  nextTick(() => clonedInputLabelRefs.value[index].focus())
}

/**
 * update segment label when changed
 * @param event change event
 * @param index index of segment to be updated
 */
const updateSegmentLabel = (event: Event, index: number): void => {
  editingSegmentLabelRef.value = null
  const target = event.target as HTMLInputElement

  if (target.value === props.segments[index].label) {
    return
  }

  const updatedSegments = ObjectUtil.deepCopy(props.segments) as Segment[]
  updatedSegments[index].label = target.value
  emit('update:segments', { segments: updatedSegments })
}

/**
 * show segment hover actions when segment label is not being edited
 * @param index index of the segment
 */
const showSegmentHoverActions = (index: number) => {
  if (editingSegmentLabelRef.value === null) {
    hoveredSegmentIndex.value = index
  }
}

/**
 * hide segment hover actions
 */
const hideSegmentHoverActions = () => {
  if (editingSegmentLabelRef.value === null) {
    hoveredSegmentIndex.value = null
  }
}

/**
 * add new empty segment
 * @param index index of segment after which new segment is to be added
 */
const addSegment = (index: number) => {
  const newSegment = { label: `Day ${index + 1}`, stops: [] as Stop[] } as Segment
  const updatedSegments = ObjectUtil.deepCopy(props.segments) as Segment[]
  updatedSegments.splice(index, 0, newSegment)
  emit('update:segments', { segments: updatedSegments })
}

const openDeleteConfirmation = (segmentIndex: number, stopIndex?: number) => {
  deleteConfirmationData.value = {
    segmentIndex,
    stopIndex,
    message: 'Are you sure you want to delete this object?',
    labelCancel: 'No, cancel',
    labelConfirm: 'Yes, delete it',
  }

  isDeleteConfirmationOpen.value = true
  updateModalState(true)
}

/**
 * delete segment
 * @param index index of segment that needs to be deleted
 */
const deleteSegment = (index: number) => {
  // select overview if the segment to be deleted contains a selected place
  if (props.segments[index].stops.some((stop) => stop.place.uri === props.selectedPlaceUri)) {
    emit('update:selection', { placeUri: null, segmentIndex: null, stopIndex: null })
  }

  const updatedSegments: Segment[] = ObjectUtil.deepCopy(props.segments)
  updatedSegments.splice(index, 1)
  emit('update:segments', { segments: updatedSegments })
  updateModalState(false)
}

const canSegmentMove = (e: any) => {
  return e.from.classList.contains('drag-segment') && e.to.classList.contains('drag-segment')
}

/**
 * move position of a segment on change
 * @param e change event from draggable
 */
const changeSegment = (e: any): void => {
  if (e.moved) {
    emit('update:segments', { segments: segmentsRef.value })
    emit('update:selection', { placeUri: null, segmentIndex: null, stopIndex: null })
  }
}

const canSegmentStopMove = (e: any) => {
  return e.from.classList.contains('drag-stop') && e.to.classList.contains('drag-stop')
}

const changeSegmentStop = (e: any) => {
  if (e.added || e.removed || e.moved) {
    emit('update:segments', { segments: segmentsRef.value })
    emit('update:selection', { placeUri: null, segmentIndex: null, stopIndex: null })
  }
}

const deleteStop = (segmentIndex: number, stopIndex: number) => {
  // select overview if the segment to be deleted contains a selected place
  if (props.segments[segmentIndex].stops[stopIndex].place.uri === props.selectedPlaceUri) {
    emit('update:selection', { placeUri: null, segmentIndex: null, stopIndex: null })
  }

  const updatedSegments: Segment[] = ObjectUtil.deepCopy(props.segments)
  updatedSegments[segmentIndex].stops.splice(stopIndex, 1)
  emit('update:segments', { segments: updatedSegments })
  updateModalState(false)
}

/**
 * When more than 1 stop exists in a segment, the segment index is followed by an alphabetical index of the stop
 * @param index
 */
const toAlphabeticalIndex = (stopsLength: number, index: number) => {
  return stopsLength > 1 ? String.fromCharCode((index % 26) + 64).toLocaleLowerCase() : ''
}

onBeforeMount(() => {
  segmentsRef.value = props.segments
})

watch(
  () => props.segments,
  (newValue) => {
    if (JSON.stringify(segmentsRef.value) !== JSON.stringify(newValue)) {
      segmentsRef.value = newValue
    }
  },
  { deep: true },
)

watch(isOpen, (value) => {
  if (!value) {
    isDeleteConfirmationOpen.value = false
    deleteConfirmationData.value = null
  }
})
</script>
<template>
  <!-- Segments wrapper -->
  <div class="w-full h-[calc(100vh-8.1rem)]">
    <!-- Overview -->
    <div class="flex justify-center">
      <div
        id="overview"
        class="flex items-center w-56 h-9 pr-2.5 transition-all ease-in-out duration-300"
        :class="[
          props.selectedPlaceUri === null
            ? 'select-none border-2 border-r-0 rounded-l-full border-primary-300 bg-primary-600 ml-16'
            : 'cursor-pointer border rounded-full border-white bg-primary-500 -ml-8',
        ]"
        @click.stop="selectOverview()"
      >
        <div class="grow text-center text-white text-sm line-clamp-1">{{ overviewHeading }}</div>
        <SolidChevronDoubleRight
          class="shrink-0 text-primary-300 transition-all ease-in-out duration-300"
          :class="[props.selectedPlaceUri === null ? 'opacity-100 w-5 h-5' : 'opacity-0 w-0 h-0']"
        />
      </div>
    </div>

    <!-- Segments -->
    <draggable
      class="drag-segment flex flex-col items-center gap-y-8 mt-4 mr-8"
      tag="ul"
      item-key="label"
      dragClass="segment-dragged"
      :group="{ name: 'groupSegmentStop' }"
      :options="{ delay: 400 }"
      :list="segmentsRef"
      :move="canSegmentMove"
      @start="isDragging = true"
      @end="isDragging = false"
      @change="changeSegment"
      @mouseleave="hideSegmentHoverActions"
    >
      <template #item="{ element: segment, index: segmentIndex }: { element: Segment, index: number }">
        <li
          class="cursor-pointer relative flex flex-col items-center gap-y-2 w-full pt-3 pb-6 border-2 border-dashed rounded-lg border-gray-200 bg-gray-50 dark:border-gray-600 dark:bg-gray-700"
          :id="`segment-li-${segmentIndex}`"
          @mouseover="showSegmentHoverActions(segmentIndex)"
        >
          <!-- Segment label -->
          <div
            class="flex items-center gap-x-1 h-6"
            :id="`segment-label-${segmentIndex}`"
            :class="{ hidden: editingSegmentLabelRef === segmentIndex }"
            @click.stop="showEditableSegmentLabel(segmentIndex)"
          >
            <span class="text-sm dark:text-white">{{ segment.label }} </span>
            <SolidPencil class="w-3 h-3 text-gray-500 dark:text-white" />
          </div>

          <!-- editable segment label -->
          <div class="relative" :class="{ hidden: editingSegmentLabelRef !== segmentIndex }">
            <input
              type="text"
              class="px-2.5 w-full h-6 leading-none text-center text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder=" "
              :ref="(el) => (clonedInputLabelRefs[segmentIndex] = el)"
              :value="segment.label"
              :id="`floating_label_segment_${segmentIndex + 1}`"
              @blur="updateSegmentLabel($event, segmentIndex)"
            />
            <label
              class="pointer-events-none absolute text-sm text-gray-500 transition-transform duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1 dark:text-gray-400 dark:bg-gray-700 peer-focus:dark:text-blue-500"
              :for="`floating_label_segment_${segmentIndex + 1}`"
            >
              Day {{ segmentIndex + 1 }}
            </label>
          </div>

          <!-- Stops -->
          <draggable
            tag="ul"
            item-key="label"
            class="drag-stop flex flex-col gap-y-2 dark:!border-gray-400"
            ghostClass="stop-ghost"
            :class="{ 'drag-stop-empty': segment.stops.length === 0 }"
            :group="{ name: 'groupSegmentStop' }"
            :list="segment.stops"
            :move="canSegmentStopMove"
            @change="changeSegmentStop"
          >
            <template #item="{ element: stop, index: stopIndex }: { element: Stop, index: number }">
              <li
                class="cursor-pointer flex items-center gap-x-2 pl-4 pr-2.5 w-56 h-9 rounded-lg text-white text-sm transition-all ease-in-out duration-300"
                :id="`place-${segmentIndex}-${stopIndex}`"
                :class="[
                  segmentIndex === props.selectedSegmentIndex && stopIndex === props.selectedStopIndex
                    ? 'border-2 border-r-0 rounded-l-full ml-12'
                    : 'border rounded-full',
                  {
                    'border-primary-300 bg-primary-600':
                      segmentIndex === props.selectedSegmentIndex &&
                      stopIndex === props.selectedStopIndex &&
                      !stop.place.tags?.includes('custom'),
                  },
                  {
                    'border-white bg-primary-500':
                      segmentIndex === props.selectedSegmentIndex &&
                      stopIndex !== props.selectedStopIndex &&
                      !stop.place.tags?.includes('custom'),
                  },
                  {
                    'border-white bg-primary-500':
                      segmentIndex !== props.selectedSegmentIndex && !stop.place.tags?.includes('custom'),
                  },
                  {
                    'border-purple-300 bg-purple-600':
                      stop.place.uri === props.selectedPlaceUri && stop.place.tags?.includes('custom'),
                  },
                  {
                    'border-white bg-purple-500':
                      stop.place.uri !== props.selectedPlaceUri && stop.place.tags?.includes('custom'),
                  },
                ]"
                @click="selectPlace(stop.place.uri, segmentIndex, stopIndex)"
              >
                <span class="grow-0">{{
                  `${segmentIndex + 1}${toAlphabeticalIndex(segment.stops.length, stopIndex + 1)} `
                }}</span>

                <span class="grow text-center line-clamp-1">{{ stop.place.name }}</span>

                <span class="grow-0">
                  <SolidCircleX
                    class="cursor-pointer -mr-1.5 w-5 h-5 fill-white"
                    :class="`delete-stop-${segmentIndex}-${stopIndex}`"
                    @click.stop="openDeleteConfirmation(segmentIndex, stopIndex)"
                  />
                </span>
                <span class="grow-0">
                  <SolidChevronDoubleRight
                    class="text-white opacity-50 transition-[width] ease-in-out duration-300"
                    :class="[
                      stop.place.uri === props.selectedPlaceUri &&
                      segmentIndex === props.selectedSegmentIndex &&
                      stopIndex === props.selectedStopIndex
                        ? 'w-5 h-5'
                        : 'w-0 h-0',
                    ]"
                  />
                </span>
              </li>
            </template>
          </draggable>

          <!-- drag icon -->
          <SolidDrag
            v-if="hoveredSegmentIndex === segmentIndex && !isDragging"
            class="absolute h-8 -left-8 top-[calc(50%-1rem)] fill-gray-500"
            :id="`segment-drag-${segmentIndex}`"
          />

          <!-- add segment -->
          <div
            v-if="(hoveredSegmentIndex === segmentIndex || segmentIndex === props.segments.length - 1) && !isDragging"
            class="hide-on-drag absolute w-full h-8 -bottom-8 flex items-center"
            :id="`segment-add-${segmentIndex}`"
          >
            <div class="h-px grow bg-primary-600 dark:bg-primary-500"></div>
            <SolidPlusCircle
              class="cursor-pointer w-6 h-6 fill-primary-600 dark:fill-primary-500"
              @click.stop="addSegment(segmentIndex + 1)"
            />
            <div class="h-px grow bg-primary-600 dark:bg-primary-500"></div>
          </div>

          <!-- delete segment -->
          <div
            v-if="hoveredSegmentIndex === segmentIndex && !isDragging && segmentsRef.length > 1"
            class="hide-on-drag absolute top-1 right-1"
            :id="`segment-delete-${segmentIndex}`"
          >
            <SolidCircleX
              class="cursor-pointer w-6 h-6 fill-gray-500 dark:fill-white"
              :class="`delete-segment-${segmentIndex}`"
              @click.stop="openDeleteConfirmation(segmentIndex)"
            />
          </div>
        </li>
      </template>
    </draggable>

    <ModalContentWrapper v-if="deleteConfirmationData">
      <DeleteConfirmation
        :message="deleteConfirmationData.message"
        :label-cancel="deleteConfirmationData.labelCancel"
        :label-confirm="deleteConfirmationData.labelConfirm"
        @close:modal="updateModalState(false)"
        @confirm:modal="
          deleteConfirmationData.stopIndex !== undefined
            ? deleteStop(deleteConfirmationData.segmentIndex, deleteConfirmationData.stopIndex)
            : deleteSegment(deleteConfirmationData.segmentIndex)
        "
      />
    </ModalContentWrapper>
  </div>
</template>
<style lang="scss" scoped>
.drag-stop {
  width: 14rem;
}
.drag-stop-empty {
  width: 14rem;
  min-height: 2.25rem;
  @apply border border-dashed border-gray-200 dark:border-gray-500;
  border-radius: 1rem;
}
.segment-chosen {
  background: greenyellow;
}
.segment-ghost {
  background: turquoise;
}
.segment-dragged {
  // background: peru;
  .hide-on-drag {
    visibility: hidden;
  }
}
.stop-ghost {
  @apply bg-gray-200 dark:bg-gray-800;
  @apply border border-dashed border-gray-200 dark:border-gray-500;
  > * {
    display: none;
  }
}
</style>
