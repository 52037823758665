<script setup lang="ts">
import { Ref, computed, ref } from 'vue'

import { ObjectUtil } from '@ankor-io/common/lang/objectUtil'
import { FontItem, ThemeTemplate } from '@ankor-io/common/proposal/Proposal'
import { OutlineXMark } from '@ankor-io/icons/outline'

import Spinner from '@/components/Spinner.vue'
import FontPicker from '@/components/fonts/FontPicker.vue'

const props = defineProps<{
  theme: ThemeTemplate
  /**
   * @deprecated Was a requirement for trips V1
   */
  isSaving?: boolean
}>()

const emit = defineEmits<{
  (e: 'update:theme', value: ThemeTemplate): void
  (e: 'close:modal'): void
}>()

const themeForm = ref<HTMLFormElement | null>(null)

const themeRef: Ref<ThemeTemplate> = ref(ObjectUtil.deepCopy(props.theme))

const canSave = computed(() => JSON.stringify(props.theme) !== JSON.stringify(themeRef.value))

const updateColor = (field: 'primary' | 'background' | 'shading' | 'accent', event: Event): void => {
  themeRef.value.color[field] = (event.target as HTMLInputElement).value
}

const updateFontColor = (field: 'heading' | 'subheading' | 'primary', event: Event): void => {
  themeRef.value.font[field].color = (event.target as HTMLInputElement).value
}

const updateFontType = (field: 'heading' | 'subheading' | 'primary', value: FontItem): void => {
  themeRef.value.font[field].type = value
}

/**
 * Close modal
 */
const close = () => {
  emit('close:modal')
}
</script>
<template>
  <div
    tabindex="1"
    aria-hidden="true"
    class="fixed z-50 inset-0 backdrop-blur-[0.125rem] flex justify-center items-center"
    id="modal-global-style"
    @click.stop
  >
    <div
      class="w-full md:max-w-2xl max-h-screen overflow-auto relative bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-600 rounded-lg shadow-md grid grid-row-3 divide-y dark:divide-gray-600 text-sm text-gray-900 dark:text-white"
    >
      <!-- close icon -->
      <div class="absolute right-8 top-8 flex justify-end">
        <OutlineXMark
          class="cursor-pointer w-3 h-3 self-center stroke-gray-900 hover:stroke-primary-600 dark:stroke-gray-400"
          @click.stop="close"
        />
      </div>

      <!-- Modal Header -->
      <div class="p-6 text-lg font-semibold text-gray-900 dark:text-gray-200">Style & Branding</div>

      <!-- Modal Body -->
      <div class="p-6 text-gray-900 dark:text-white">
        <form ref="themeForm" class="flex gap-x-8" @submit.prevent>
          <!-- Colours -->
          <div class="flex flex-col gap-y-2">
            <p class="font-medium leading-none">Colours</p>
            <div class="flex gap-x-4">
              <div class="flex flex-col items-center">
                <button
                  id="primaryColor"
                  type="button"
                  class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                  :style="{ background: themeRef.color.primary }"
                >
                  <input
                    class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                    type="color"
                    id="html5colorpickerPrimary"
                    :model="themeRef.color.primary"
                    @input="updateColor('primary', $event)"
                  />
                </button>
                <label for="primaryColor" class="text-xxs text-center text-slate-400 mt-1">Primary</label>
              </div>
              <div class="flex flex-col items-center">
                <button
                  id="backgroundColor"
                  type="button"
                  class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                  :style="{ background: themeRef.color.background }"
                >
                  <input
                    class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                    type="color"
                    id="html5colorpickerBackground"
                    :model="themeRef.color.background"
                    @input="updateColor('background', $event)"
                  />
                </button>
                <label for="backgroundColor" class="text-xxs text-center text-slate-400 mt-1">Background</label>
              </div>
              <div class="flex flex-col items-center">
                <button
                  id="shadingColor"
                  type="button"
                  class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                  :style="{ background: themeRef.color.shading }"
                >
                  <input
                    class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                    type="color"
                    id="html5colorpickerShading"
                    :model="themeRef.color.shading"
                    @input="updateColor('shading', $event)"
                  />
                </button>
                <label for="shadingColor" class="text-xxs text-center text-slate-400 mt-1">Shading</label>
              </div>
              <div class="flex flex-col items-center">
                <button
                  id="accentColor"
                  type="button"
                  class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                  :style="{ background: themeRef.color.accent }"
                >
                  <input
                    class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                    type="color"
                    id="html5colorpickerAccent"
                    :model="themeRef.color.accent"
                    @input="updateColor('accent', $event)"
                  />
                </button>
                <label for="accentColor" class="text-xxs text-center text-slate-400 mt-1">Accent</label>
              </div>
            </div>
          </div>

          <!-- Font -->
          <div class="flex flex-col gap-y-2 grow">
            <p class="font-medium leading-none">Font</p>
            <div class="flex flex-col">
              <label for="headingFont">Heading</label>
              <div class="flex justify-between items-center gap-x-4">
                <FontPicker
                  id="headingFont"
                  class="border rounded-lg"
                  :selected-font="themeRef.font.heading.type"
                  @update:font="updateFontType('heading', $event)"
                ></FontPicker>
                <div class="flex flex-col items-center">
                  <button
                    id="headingColor"
                    type="button"
                    class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                    :style="{ background: themeRef.font.heading.color }"
                  >
                    <input
                      class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                      type="color"
                      id="html5colorpickerHeading"
                      :model="themeRef.font.heading.color"
                      @input="updateFontColor('heading', $event)"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <label for="contentFont">Subheading</label>
              <div class="flex justify-between items-center gap-x-4">
                <FontPicker
                  id="contentFont"
                  class="border rounded-lg"
                  :selected-font="themeRef.font.subheading.type"
                  @update:font="updateFontType('subheading', $event)"
                ></FontPicker>
                <div class="flex flex-col items-center">
                  <button
                    id="contentColor"
                    type="button"
                    class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                    :style="{ background: themeRef.font.subheading.color }"
                  >
                    <input
                      class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                      type="color"
                      id="html5colorpickerSubheading"
                      :model="themeRef.font.subheading.color"
                      @input="updateFontColor('subheading', $event)"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div class="flex flex-col">
              <label for="paragraphFont">Paragraph</label>
              <div class="flex justify-between items-center gap-x-4">
                <FontPicker
                  id="paragraphFont"
                  class="border rounded-lg"
                  :selected-font="themeRef.font.primary.type"
                  @update:font="updateFontType('primary', $event)"
                ></FontPicker>
                <div class="flex flex-col items-center">
                  <button
                    id="paragraphColor"
                    type="button"
                    class="rounded-lg text-center flex justify-center items-center h-12 w-12"
                    :style="{ background: themeRef.font.primary.color }"
                  >
                    <input
                      class="font-medium rounded-lg text-sm text-center items-center hover:cursor-pointer h-12 w-12 opacity-0"
                      type="color"
                      id="html5colorpickerPrimaryParagraph"
                      :model="themeRef.font.primary.color"
                      @input="updateFontColor('primary', $event)"
                    />
                  </button>
                </div>
              </div>
            </div>
            <span class="text-gray-500 dark:text-gray-400">
              We recommend no more than two types of fonts for your content.
            </span>
          </div>
        </form>
      </div>

      <!-- Modal Footer -->
      <div class="p-6 flex justify-end gap-x-2">
        <!-- Cancel/Confirm Buttons -->
        <button
          id="cancel-modal"
          type="button"
          class="py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
          @click.stop="close"
        >
          Cancel
        </button>
        <button
          id="confirm-modal"
          type="button"
          class="text-white focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 focus:outline-none"
          :class="
            canSave
              ? 'bg-blue-700 hover:bg-blue-800 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
              : 'bg-gray-200 dark:bg-gray-400 cursor-not-allowed'
          "
          :disabled="!canSave"
          @click.stop="$emit('update:theme', themeRef)"
        >
          <Spinner v-if="props.isSaving" class="w-5 h-5" />
          <span v-else> Confirm </span>
        </button>
      </div>
    </div>
  </div>
</template>
