import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export default function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    fill: "none",
    stroke: "currentColor",
    "aria-hidden": "true",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("path", {
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      d: "M8.72725 8.01864L12 4.36363M12 4.36363L15.2727 8.01864M12 4.36363L12 13.0908"
    }),
    _createElementVNode("rect", {
      width: "16",
      height: "3",
      x: "4",
      y: "16",
      "stroke-width": "2",
      rx: "1"
    })
  ]))
}