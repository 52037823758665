import { URIContext } from '../uri/Uri'
import { MediaUriBuilder } from '../uri/uri.builder'

/**
 * replaces relative paths to media uris if required
 * @param entityUri
 * @param images the images to check and replace
 * @returns string[]
 */
export const replacePathToMediaUris = (entityUri: string, ...images: string[]) => {
  if (!entityUri) {
    console.debug('entityUri not defined while replacing path to mediaUris')
  }

  if (
    entityUri &&
    images.length &&
    images.filter((image) => image).some((image) => !image.startsWith(`${URIContext.MEDIA}::`))
  ) {
    return images
      .filter((image) => image)
      .map((image) =>
        image.startsWith(`${URIContext.MEDIA}::`) ? image : new MediaUriBuilder().build(entityUri, image),
      )
  }

  return images.filter((image) => image)
}
