<script setup lang="ts">
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import { ref } from 'vue'

import { Runnable } from '@ankor-io/common/lang/functional.types'
import { SolidDrag, SolidPlusCircle, SolidTrash } from '@ankor-io/icons/solid'

import Spinner from '@/components/Spinner.vue'

export interface DraggableMarkerProps {
  label: string
  handleAdd: Runnable<string>
  handleDelete: Runnable<string>
  placeName: string
}

const props = defineProps<DraggableMarkerProps>()
const addingPlace = ref(false)

const addPlace = async () => {
  addingPlace.value = true
  await props.handleAdd(props.label)
  addingPlace.value = false
}

const removePlace = () => {
  props.handleDelete(props.label)
}
</script>
<template>
  <div class="relative">
    <div class="absolute text-white">
      <div class="px-4 bg-purple-500 border-2 border-white h-9 flex items-center rounded-full text-lg">
        <SolidDrag class="-ml-2 h-4 self-center fill-white" />
        <SolidPlusCircle v-if="!addingPlace" @click="addPlace" class="w-4 h-4 self-center fill-white" />
        <Spinner v-else class="h-4"></Spinner>
        <span class="text-white text-center px-5 whitespace-nowrap">{{ label }} {{ placeName }}</span>
        <SolidTrash @click.stop="removePlace" class="-mr-2 h-4 self-center fill-white" />
      </div>
      <div
        class="-mt-0.5 ml-3 h-0 w-0 border-l-8 border-r-8 border-t-8 border-solid border-l-transparent border-r-transparent border-t-white"
      ></div>
    </div>
  </div>
</template>
