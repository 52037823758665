<script setup lang="ts">
import sanitizeHtml from 'sanitize-html'
import { Ref, onMounted, ref } from 'vue'

import { ChangeEvent } from '@ankor-io/common/events/Editor'
import { SolidCircleQuestion } from '@ankor-io/icons/solid'

import { EmbedSectionData, EmbedSectionLayout, Size } from '@/sections/embed/types'

type Props = {
  id: string
  data: EmbedSectionData
  layout: EmbedSectionLayout
}

const props = defineProps<Props>()

const emit = defineEmits<{
  (e: 'update:value', value: ChangeEvent<EmbedSectionData>): void
  (e: 'update:layout', value: { sectionId: string; layout: EmbedSectionLayout }): void
}>()

const displayError: Ref<boolean> = ref(false)
const embedContentRef: Ref<string> = ref('')

const optionsForSizeSelector: { id: string; size: Size }[] = [
  { id: 'default-size-radio', size: 'default' },
  { id: 'small-size-radio', size: 'small' },
  { id: 'medium-size-radio', size: 'medium' },
  { id: 'large-size-radio', size: 'large' },
]

onMounted(() => {
  embedContentRef.value = props.data?.content ?? ''
})

const update = (content: string): void => {
  displayError.value = false

  // Allow only a super restricted iframe tag
  const sanitizedContent = sanitizeHtml(content, {
    allowedTags: ['iframe'],
    allowedAttributes: false,
  })

  if (
    !sanitizedContent.toLocaleLowerCase().startsWith('<iframe') ||
    !sanitizedContent.toLocaleLowerCase().endsWith('</iframe>')
  ) {
    displayError.value = true
    return
  }

  emit('update:value', { sectionId: props.id, data: { ...props.data, content } })
}
</script>
<template>
  <div class="h-[calc(100vh-11rem)] overflow-y-auto relative w-full">
    <div class="flex flex-col gap-y-2">
      <!-- Size options -->
      <p class="text-sm font-semibold border-b border-gray-200 text-gray-900 dark:text-white">Size</p>
      <ul class="flex items-center text-xs font-medium rounded-lg">
        <li v-for="option in optionsForSizeSelector" :key="option.id" class="flex w-full items-center pl-2">
          <input
            type="radio"
            name="sizeRadioOptions"
            class="size-4 focus:ring-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
            :id="option.id"
            :value="option.size"
            :checked="option.size === props.layout.options?.size"
            @click="
              $emit('update:layout', {
                sectionId: props.id,
                layout: {
                  type: props.layout.type,
                  options: { ...props.layout.options, size: option.size },
                },
              })
            "
          />
          <label :for="option.id" class="py-3 ml-2 capitalize text-gray-600 dark:text-gray-300">
            {{ option.size }}
          </label>
        </li>
      </ul>
    </div>

    <p class="text-sm font-semibold my-4 border-b border-gray-200 text-gray-900 dark:text-white">Embed Code</p>
    <textarea
      rows="4"
      id="embed-content"
      name="embed-content"
      placeholder="Paste the embed code from YouTube or Vimeo here."
      class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-primary-600 dark:bg-gray-900 dark:border-gray-600 dark:placeholder-gray-500 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
      v-model="embedContentRef"
    ></textarea>
    <div v-if="displayError" class="error pt-2.5 text-sm text-red-600">Please add valid embed code</div>
    <div class="pt-2.5 flex justify-end">
      <button
        class="transition-all focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center focus:ring-blue-300 text-white bg-primary-700 dark:bg-primary-600 hover:text-white hover:bg-blue-500 dark:hover:bg-primary-800"
        @click="update(embedContentRef)"
      >
        Submit
      </button>
    </div>

    <div class="p-2.5 text-gray-500">
      <div class="text-sm pt-2 font-semibold text-gray-500 dark:text-gray-400">Example:</div>
      <div class="text-xs text-gray-400 dark:text-gray-500">
        &lt;iframe width="560" height="315" src="https://www.youtube.com/embed/_5LLKBFBMEw?controls=0" frameborder="0"
        allowfullscreen&gt;&lt;/iframe&gt;
        <div class="help-tip flex items-start pt-4">
          <SolidCircleQuestion class="w-5 h-5 flex-shrink-0 mr-2 fill-gray-500" />
          <span>
            <a
              target="_blank"
              class="text-primary-500 underline"
              href="https://help.ankor.io/knowledge/how-to-use-the-embed-video-section"
            >
              Click here
            </a>
            to learn more about how the Embed Video section works.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
