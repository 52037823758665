<script setup lang="ts">
import { initDropdowns } from 'flowbite'
import { Ref, onMounted, ref } from 'vue'

import { FontItem } from '@ankor-io/common/proposal/Proposal'

import { useFontService } from '@/services/fonts/FontsService'

import FontPickerItem from './FontPickerItem.vue'

export interface Props {
  id: string
  selectedFont: FontItem
}

const props = defineProps<Props>()

const fontService = useFontService()

const fonts: Ref<FontItem[]> = ref([])
const filteredFonts: Ref<FontItem[]> = ref([])
const loadingFonts: Ref<boolean> = ref(true)
const fontFilter: Ref<string> = ref('')

onMounted(() => {
  initDropdowns()
})

const emit = defineEmits<{
  (e: 'update:font', font: FontItem): void
}>()

const filterFonts = (): void => {
  filteredFonts.value = fonts.value
  if (!fontFilter.value || fontFilter.value.length === 1) {
    return
  }
  // start filtering at the second char
  filteredFonts.value = filteredFonts.value.filter((f: FontItem) => {
    return f.family.toLowerCase().indexOf(fontFilter.value.toLocaleLowerCase()) !== -1
  })
}

const select = (font: FontItem): void => {
  emit('update:font', font)
  // reset the filter
  fontFilter.value = ''
  filteredFonts.value = fonts.value
}

fontService
  .listFonts()
  .then((_fonts: FontItem[]) => {
    fonts.value = _fonts.slice(0, 150)
    fonts.value.forEach((f: FontItem) => {
      /**
       * To be able to preview the fonts I need to create a font face
       * we already have the files so let's just loop through and add those styles
       */
      const fontFaceEl = document.getElementById(f.family)
      // do not create the style if we have it already
      if (fontFaceEl) {
        return
      }

      var newStyle = document.createElement('style')
      newStyle.setAttribute('id', f.family)
      newStyle.appendChild(
        document.createTextNode(`
      @font-face {
        font-family: '${f.family}';
        src: url('${f.files.regular.replace('http:', 'https:')}');
      }
      `),
      )
      document.head.appendChild(newStyle)
    })
  })
  .finally(() => {
    loadingFonts.value = false
    filteredFonts.value = fonts.value
  })
</script>
<template>
  <div class="relative flex flex-col w-full" :id="props.id">
    <button
      type="button"
      data-dropdown-placement="bottom"
      class="peer px-2.5 pb-2 pt-2.5 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-900 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      :disabled="loadingFonts"
      :id="`${props.id}Button`"
      :data-dropdown-toggle="`${props.id}Toggle`"
    >
      <div class="flex justify-between w-full">
        <span :style="[{ 'font-family': `'${props.selectedFont.family}'` }]">
          {{ props.selectedFont.family }}
        </span>
        <svg
          class="w-4 h-4 ml-2"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
        </svg>
      </div>
    </button>
    <!-- Dropdown menu -->
    <div
      class="z-50 hidden w-full rounded-lg shadow absolute mt-11 dark:border border-gray-600 bg-white dark:bg-gray-800"
      :id="`${props.id}Toggle`"
    >
      <div class="p-3">
        <label for="input-group-search" class="sr-only">Search</label>
        <div class="relative">
          <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              class="w-5 h-5 text-gray-500"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <input
            v-model="fontFilter"
            type="text"
            id="input-group-search"
            class="block w-full p-2 pl-10 text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Filter fonts"
            @keydown="filterFonts()"
          />
        </div>
      </div>
      <ul
        class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="dropdownSearchButton"
      >
        <li v-for="(font, index) in filteredFonts" :key="index" @click="select(font)">
          <FontPickerItem :font="font"></FontPickerItem>
        </li>
      </ul>
    </div>
  </div>
</template>
