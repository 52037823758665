import * as Sentry from '@sentry/vue'
import * as SentryCF from '@sentry/cloudflare'
import { SentryConfig } from './types'

// Initialize Sentry for Vue app. App has any type to avoid needing to get vue dependencies in this package
export const sentryVueInit = (vueApp: any, config: SentryConfig) => {
  // clone app object to avoid mutating the original
  const app = { ...vueApp }

  if (config?.enabled?.toLowerCase() === 'true') {
    console.log('Sentry enabled!')
    Sentry.init({
      app,
      dsn: config.dsn,
      environment: config.environment,
      integrations: [
        Sentry.browserTracingIntegration({
          enableInp: true,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        'localhost',
        /^\/[^.]+/, // everithing that starts with /, eg: /api, /search
        // vvv all subdomains of ankor.io or ankor.dev, plus issue-xxxx.xxx.ankor.dev variants vvv
        /https:\/\/[^.]+\.((radar|calendars|wheelhouse|app)\.ankor\.(dev|io))\/.*'/,
        /'https:\/\/[^.]+\.((radar|calendars|wheelhouse|app)\.ankor\.(dev|io))'/,
        /'https:\/\/\(radar|calendars|wheelhouse|app\.ankor\.(dev|io)\/.*'/,
        /https:\/\/\(radar|calendars|wheelhouse|app\.ankor\.(dev|io)'/
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
  return app
}

// Wire Sentry tracing for functions
export const sentryMiddlewareInit: PagesFunction<{ SENTRY_DSN: string, SENTRY_ENV: string, SENTRY_ENABLED: boolean }> = (context) => {
  const host = new URL(context.request.url).host

  if (!context.env.SENTRY_ENABLED || !context.env.SENTRY_DSN || host.includes('localhost') || host.includes('.local')) {
    return context.next()
  }

  return SentryCF.sentryPagesPlugin({
    dsn: context.env.SENTRY_DSN,
    environment: context.env.SENTRY_ENV || 'default'
  })(context as any)
}
